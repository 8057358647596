import React, { useContext, useEffect } from "react"
import axios from "axios"
import * as R from "ramda"

import { GunRunnerContext } from "./context"
import SCREENS from "./config/SCREENS"

import GunRunnerCard from "../../components/Metaracts/GunRunner/GunRunnerCard";
import {UsersContext} from "../../context/users";
import {CommunityContext} from "../../context/community";
import { SocketContext } from "../../context/socket"
import GunRunnerDrawer from "../../components/Metaracts/GunRunner/Screens/GunRunnerDrawer";
import { MetaractShelfContext } from "../MetaractShelf/context";
import card_graphic from "../../assets/Images/GunRunner/gunrunnercardgraphic.svg";

const GunRunner = ({ shelf }) => {
  const userContext = useContext(UsersContext);
  const communityContext = useContext(CommunityContext)
  const gunRunnerContext = useContext(GunRunnerContext);
  const metaractShelfContext = useContext(MetaractShelfContext)
  const socket = useContext(SocketContext)
  const userType = R.pathOr("FAN", ["userDBObject", "type"], userContext)
  const userID = R.pathOr("", ["userDBObject", "id"], userContext)
  const metaracts = R.pathOr(0, ["metaracts"], communityContext)
  const metaract = metaracts.find(
    (metaract) => metaract.type === "GUNRUNNER" && metaract.status === "ACTIVE"
  )
  const queue = R.pathOr([], ["gunRunnerQueue"], gunRunnerContext)

  console.log(queue, "queue")
  const handleClick = () => {
    if (userType === "CREATOR" && communityContext.creatorid === userID) {
      gunRunnerContext.setCurrentDrawerScreen(SCREENS.DRAWERS.CREATOR_COMMUNITY)
    } else {
      gunRunnerContext.setCurrentDrawerScreen(SCREENS.DRAWERS.FAN_COMMUNITY)
    }
    gunRunnerContext.setCurrentDrawerOpen(true)
    communityContext.setDrawerOpen(true)
  }

  const getGunRunner = async () => {
    gunRunnerContext.setIsLoading(true)
    try {
      const metaracts = R.pathOr(0, ["metaracts"], communityContext)
      const metaract = metaracts.find(
        (metaract) =>
          metaract.type === "GUNRUNNER" && metaract.status === "ACTIVE"
      )
      gunRunnerContext.setMetaractID(metaract.id)
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${metaract.id}`
      )
      gunRunnerContext.setGunRunner(res.data.data.description)
      gunRunnerContext.setGunRunnerQueue(res?.data?.data?.gunrunneritems)
      console.log(
        "Queried => getGunRunner fired on GunRunner Page",
        res.data.data
      )
    } catch (error) {
      console.log(error)
    } finally {
      gunRunnerContext.setIsLoading(false)
    }
  }

  const getGunRunnerItems = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}gunrunner/getgunrunneritems/?metaractid=${metaract.id}&take=100&skip=0&orderBy=desc`
      )
      console.log(
        res.data,
        "Queried => getGunRunnerItems fired on GunRunner Page"
      )
      gunRunnerContext.setGunRunnerQueue(res?.data?.data?.gunrunneritems)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getGunRunnerItems()
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on("RECEIVE_GUNRUNNER_HAS_CHANGED", (data) => {
        getGunRunnerItems()
        console.log("Event => RECEIVE_GUNRUNNER_HAS_CHANGED")
      })

      return () => {
        socket.off("RECEIVE_GUNRUNNER_HAS_CHANGED")
      }
    }
  }, [socket])

  useEffect(() => {
    if (!communityContext.isLoading) {
      const metaract = communityContext.metaracts.find(
        (metaract) =>
          metaract.type === "GUNRUNNER" && metaract.status === "ACTIVE"
      )
      if (metaract !== undefined) {
        gunRunnerContext.setMetaractID(metaract.id)
      }

      getGunRunner()

      if (socket) {
        if (metaract !== undefined) {
          if (metaract.id !== undefined) {
            if (gunRunnerContext.hasntJoinedRoom) {
              socket.emit("JOIN_ROOM", `gunrunner_${metaract?.id}`)
              gunRunnerContext.setHasntJoinedRoom(false)
            }
            gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.INIT)
          }
        }
      }
    }
  }, [communityContext.isLoading])

  console.log(userType , "userType")
  if (shelf && metaractShelfContext.drawerIsOpen) {
    if (userType === "CREATOR" && communityContext.creatorid === userID) {
      gunRunnerContext.setCurrentDrawerScreen(SCREENS.DRAWERS.CREATOR_COMMUNITY)
    } else {
      gunRunnerContext.setCurrentDrawerScreen(SCREENS.DRAWERS.FAN_COMMUNITY)
    }

    gunRunnerContext.setCurrentDrawerOpen(true)
    communityContext.setDrawerOpen(true)
  }

  const handleJoinLobby = () => {
    gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.FAN_BUY_SESSION)
  }

  return (
    <div>
      <GunRunnerDrawer />
      <div
        onClick={handleClick}
        className="h-fit rounded-lg hover:cursor-pointer transform transition duration-500 hover:scale-105 ml-10 flex flex-col w-auto px-5 py-5 bg-communitydarebg shadow-2xl"
      >
        <div className="flex pt-2 ">
          <h2 className="font-ocr text-lg text-dashboardhighlight">
            GUNRUNNER
          </h2>
        </div>
        <div className="flex justify-between items-center mx-2 mt-4">
          <p className="font-ocr text-dashboardhighlight text-xs">{`${queue.length} PLAYERS IN QUEUE`}</p>
          <p className="font-ocr text-dashboardhighlight text-xs">0 COMPLETED</p>
        </div>
        <div className="flex items-center mx-2 mt-4">
          <p className="font-ocr text-gunrunnercardborder text-xs">WAITING LOBBY</p>
          <img src={card_graphic}  className="mx-10 h-3"/>
        </div>

        <div className="flex flex-wrap">
        {queue.map((gunrunneritem) => (
              <div className="flex my-1 w-1/3 lg:my-4 lg:w-1/4">
                <GunRunnerCard gunrunneritem={gunrunneritem} key={gunrunneritem.id} />
              </div>
        ))}
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleJoinLobby}
            className="group flex mt-10 py-3 px-4 font-ocr text-xl border border-dashboardhighlight bg-black items-center justify-center text-dashboardtext rounded-full hover:bg-dashboardhighlight hover:text-black transition ease-in-out"
          >
            <h1 className="font-ocr text-sm">JOIN</h1>
            <svg
              viewBox="0 0 24 24"
              className="w-6 h-6 stroke stroke-dashboardtext group-hover:stroke-black ml-1"
            >
              <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
            </svg>
          </button>
        </div>
        </div>
    </div>
  )
}

export default GunRunner
