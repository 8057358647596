import { useMemo } from "react"
import MetaractCard from "../../Common/MetaractCard"
import { ReactComponent as PlusIcon } from "../../../assets/svg/plus-icon.svg"
import { ReactComponent as CommonIcon } from "../../../assets/svg/common-icon.svg"
import { ReactComponent as RareIcon } from "../../../assets/svg/rare-icon.svg"
import { ReactComponent as EpicIcon } from "../../../assets/svg/epic-icon.svg"
import {
  textRarityColors,
  bgRarityColors,
  buttonRaritycolors,
  borderRarityColors,
} from "../../../utils/colors"

const leftIcons = {
  common: <CommonIcon className="absolute top-1/3 -left-2" />,
  rare: <RareIcon className="absolute top-1/3 -left-2" />,
  epic: <EpicIcon className="absolute top-1/4 -left-2" />,
}

export const PowerUpCard = ({
  powerUp,
  isSelectedCreator,
  isUserCreator,
  handleUsePowerUp,
  setSelectedPowerUp,
  setBattleDrawerOpen,
}) => {
  const min = 0
  const max =
    Number(powerUp.price) -
    Number(
      isSelectedCreator
        ? powerUp?.contributionsselected
        : powerUp?.contributionscreator
    )
  //progress bar percentage start

  console.log("powerup", powerUp)
  const contributions = isSelectedCreator
    ? powerUp?.contributionsselected
    : powerUp?.contributionscreator
  const price = powerUp?.price
  const percentage = (contributions / price) * 100

  const useStyle =
    "rounded-full border px-3 h-6 font-proxima text-xs text-white"
  const addStyle = "rounded-sm font-bold font-ocr text-xl p-2"

  const activeStyle = "bg-black"
  const disabledStyle = "hover:cursor-default bg-black text-black opacity-30"

  const unlockedselected =
    isSelectedCreator && !powerUp.usedselected && powerUp.unlockedselected
  const unlockedcreator = !powerUp.usedcreator && powerUp.unlockedcreator

  const lockedSelected = isSelectedCreator && !powerUp.unlockedselected
  const lockedCreator = !isSelectedCreator && !powerUp.unlockedcreator

  const powerUpUnlocked = powerUp?.price - contributions === 0
  const powerUpUsed = !(unlockedselected || unlockedcreator) && powerUpUnlocked

  const buttonStyle = useMemo(() => {
    if (isUserCreator) {
      if (unlockedselected || unlockedcreator) {
        return `${activeStyle} ${useStyle} ${
          buttonRaritycolors[powerUp.rarity]
        } ${textRarityColors[powerUp.rarity]} ${
          borderRarityColors[powerUp.rarity]
        }`
      } else {
        return `${disabledStyle} ${useStyle} ${
          textRarityColors[powerUp.rarity]
        } ${borderRarityColors[powerUp.rarity]}`
      }
    } else {
      if (lockedSelected || lockedCreator) {
        return `${activeStyle} ${addStyle} ${
          buttonRaritycolors[powerUp.rarity]
        } ${textRarityColors[powerUp.rarity]}`
      } else {
        return `${disabledStyle} ${addStyle} ${
          textRarityColors[powerUp.rarity]
        }`
      }
    }
  }, [
    isUserCreator,
    lockedCreator,
    lockedSelected,
    powerUp.rarity,
    unlockedcreator,
    unlockedselected,
  ])

  const buttonContent = useMemo(() => {
    if (isUserCreator) return "USE"
    return <PlusIcon />
  }, [isUserCreator, powerUp.rarity])

  let handleButton = null

  if (isUserCreator && (unlockedselected || unlockedcreator)) {
    handleButton = () => handleUsePowerUp(powerUp)
  }
  if (lockedSelected || lockedCreator) {
    handleButton = () => {
      setSelectedPowerUp({ ...powerUp, min, max })
      setBattleDrawerOpen(true)
    }
  }

  const Bar = () => (
    <div className="w-full p-0.5 rounded-sm h-4.2 bg-dashboardbackground dark:bg-dashboardbackground border-dashboardalttext border">
      <div
        className={`${bgRarityColors[powerUp.rarity]} h-2.5 rounded-sm`}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  )

  return (
    <MetaractCard key={powerUp.id} styles={{ width: 260 }}>
      {leftIcons[powerUp.rarity]}
      <div
        className={`absolute h-7 -right-10 left-auto bottom-0 -rotate-90 font-medium font-ocr bg-cardbackground px-2 pb-2 pt-1 rounded-xl top-16 ${
          textRarityColors[powerUp.rarity]
        } ${powerUp.rarity === "common" ? "mt-2" : "mr-2"}`}
      >
        {powerUp.rarity.toUpperCase()}
      </div>
      <div className="flex flex-col justify-between gap-4" key={powerUp.id}>
        <div className="flex flex-row justify-between">
          <h1 className="font-proxima text-2xl text-white font-bold mr-2">
            {`$${powerUp?.price}`}
          </h1>
          <button onClick={handleButton} className={`z-[100] ${buttonStyle}`}>
            {buttonContent}
          </button>
        </div>

        <div>
          <Bar />
          <h1 className="font-ocr text-xs text-dashboardalttext mr-2 text-right mt-2">
            {powerUpUsed ? (
              "USED"
            ) : powerUpUnlocked ? (
              "UNLOCKED"
            ) : (
              <span>
                <span className={textRarityColors[powerUp.rarity]}>{`$${
                  powerUp?.price - contributions
                } `}</span>
                TO UNLOCK
              </span>
            )}
          </h1>
        </div>

        <p className="text-dashboardtext mt-2 font-proxima text-sm">
          {powerUp?.description}
        </p>
      </div>
    </MetaractCard>
  )
}
