import styled from "styled-components";

const CardContentText = styled.p`
  margin: 0;
  padding: 0;
  color: #101010;
  font-size: 16px;
  font-weight: 500;
`;

export default CardContentText;
