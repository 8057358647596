import React, { useState, useContext, useEffect } from "react"
// import io from "socket.io-client"
import * as R from "ramda"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { DareContext } from "../../../../../../../pages/Dare/context"
import { UsersContext } from "../../../../../../../context/users"
import DareVotingTimeLeft from "../../../../DareVotingTimeLeft"
import DareFanLiveDare from "../FanLiveDare"
import { CommunityContext } from "../../../../../../../context/community"
import { MetaractShelfContext } from "../../../../../../../pages/MetaractShelf/context"
import {
  DrawerButton,
  DareItemsList,
  DrawerContainer,
} from "../../../../../Common"
import { useScreen } from "../../../../../../../hooks/useScreen"

const DareFanCommunity = () => {
  const dareContext = useContext(DareContext)
  const communityContext = useContext(CommunityContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const [isLive, setIsLive] = useState(false)
  const [dareItems, setDareItems] = useState([])
  const [Timeend, setTimeEnd] = useState(0)
  const userContext = useContext(UsersContext)
  const { isMobile } = useScreen()
  const userID = R.pathOr("", ["userDBObject", "id"], userContext)

  useEffect(() => {
    if (!dareContext.isLoading) {
      setIsLive(R.pathOr("", ["dare", "description", "islive"], dareContext))
      const items = R.pathOr([], ["dare", "dareitems"], dareContext)
      const sorted = items
        .filter((item) => item.status === "ACTIVE")
        .sort((a, b) => b.price - a.price)
      setDareItems(sorted)
      setTimeEnd(R.pathOr(0, ["dare", "description", "timeend"], dareContext))
      console.log(dareContext.dare, "dare")
      console.log(Timeend, "timeend")
    }
  }, [dareContext.isLoading])

  const handleSubmit = () =>
    dareContext.setCurrentScreen(SCREENS.SCREENS.FAN_ADD_DARE)

  // const handleLiveSubmit = () =>
  //   dareContext.setCurrentScreen(SCREENS.SCREENS.FAN_LIVE_DARE)

  if (isLive) return <DareFanLiveDare />
  return (
    <DrawerContainer
      type="dare"
      onClose={
        isMobile
          ? null
          : () => {
              dareContext.setCurrentDrawerOpen(false)
              dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.INIT)
              dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
              communityContext.setDrawerOpen(false)
              metaractShelfContext.setDrawerIsOpen(false)
            }
      }
      header={
        <DareVotingTimeLeft timeend={dareContext.dare?.description?.timeend} />
      }
      footer={<DrawerButton text="ADD DARE" onClick={handleSubmit} />}
    >
      <DareItemsList
        title={"READY FOR VOTING"}
        dareItems={dareItems}
        userID={userID}
      />
    </DrawerContainer>
  )
}

export default DareFanCommunity
