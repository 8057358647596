import React from "react";

import * as Styled from "./styles";

const UserInformation = () => {
  return (
    <Styled.Container>
      <Styled.NameText>Matt Murdock</Styled.NameText>
      <Styled.UsernameText>@mattmurdock</Styled.UsernameText>
    </Styled.Container>
  );
};

export default UserInformation;
