import React from "react";  

import * as Styled from "./styles";

const CardFrame = ({children}) => {
    return (
        <Styled.Card>
            {children}
        </Styled.Card>
    )
}

export default CardFrame;