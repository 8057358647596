import React from "react"

const SearchBar = () => {
  return (
    <div className="flex items-center bg-dashboardboxbackground py-1.5 px-4 rounded-2xl">
      <svg
        viewBox="0 0 24 24"
        className="w-4 h-4 text-dashboardtext stroke-1 stroke-dashboardtext fill-none"
      >
        <path d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
      </svg>
      <input className="ml-2 bg-transparent font-proxima text-dashboardtext md:w-96 outline-none"></input>
    </div>
  )
}

export default SearchBar
