import React, { useState, useContext } from "react"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"

import AlternativeText from "../../../AlternativeText"
import ROUTES from "../../../../../config/routes"
import SCREENS from "../../config/screens"
import ProfilePicture from "../../../ProfilePicture"
import { UsersContext } from "../../../../../context/users"
import { toast } from "react-toastify"
import { DrawerInput } from "../../../../Metaracts/Common"
import { Button } from "../../../../Common/atoms/Button"
import { useScreen } from "../../../../../hooks/useScreen"
import { Divider } from "../../../../Common/atoms/Divider"

const FinishProfile = ({ isCreator, setCurrentScreenState }) => {
  const usersContext = useContext(UsersContext)
  const navigate = useNavigate()
  const { isMobile } = useScreen()
  const { id } = useParams()
  const [isLoadingState, setIsLoadingState] = useState(false)
  const [formState, setFormState] = useState({
    username: "",
    profilePictureUrl: "",
  })

  const updateUserInformation = async () => {
    if (!isLoadingState) {
      try {
        setIsLoadingState(true)
        const res = axios.post(
          `${process.env.REACT_APP_SERVER_API}user/updateuser/${usersContext.userDBObject.id}`,
          { name: formState.username }
        )
        const userId = usersContext.userDBObject?.id

        if (isCreator) {
          setCurrentScreenState(SCREENS.CREATE_COMMUNITY)
        } else {
          if (id) {
            navigate(`/community/${id}/true`)
          } else {
            navigate(`/`)
          }
        }
      } catch (error) {
        toast.error("Oops! Something went wrong")
      }
    } else {
      toast.error("Oops! Please wait for file to finish uploading!")
    }
  }

  return (
    <>
      {DrawerInput({
        type: "text",
        placeholder: "Enter your username...",
        onChange: (event) =>
          setFormState({ ...formState, username: event.target.value }),
        title: "Username",
        titleStyle: "sm:text-xl",
      })}
      <div>
        <h3 className="font-proxima text-base font-semibold mb-2 sm:text-xl text-dashboardtext ">
          Profile image
        </h3>
        <ProfilePicture setIsLoadingState={setIsLoadingState} />
      </div>
      <Button
        onClick={updateUserInformation}
        text={isCreator ? "NEXT" : "CREATE ACCOUNT"} //Next: CREATE COMMUNITY
        size={isMobile ? "medium" : "big"}
        styles="mx-8 mt-10 mb-4 sm:mt-16 sm:mb-10 font-proxima"
      />
      <Divider />
      <AlternativeText
        text="Already have an account?"
        buttonText="Sign In"
        onClick={() => {
          if (id) {
            navigate(`${ROUTES.SIGN_IN.path}/${id}`)
          } else {
            navigate(`${ROUTES.SIGN_IN.path}/`)
          }
        }}
        isMobile={isMobile}
        styles={"font-proxima"}
      />
    </>
  )
}

export default FinishProfile
