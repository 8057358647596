import React from "react";
import { Flex } from "rebass";

import Menu from "../../../components/AccountSettings/Menu";
import MainContent from "../../../components/AccountSettings/MainContent";

import * as Styled from "./styles";

const CreatorAccountSettings = () => {
  return (
    <Styled.Container>
      <Flex mr="115px">
        <Menu />
      </Flex>
      <Flex mt="62px">
        <MainContent />
      </Flex>
    </Styled.Container>
  );
};

export default CreatorAccountSettings;
