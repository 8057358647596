const SCREENS = {
  SCREENS: {
    INIT: "INIT",
    FAN_BUY_SESSION: "FAN_BUY_SESSION",
    FAN_IN_QUEUE: "FAN_IN_QUEUE",
    FAN_UPDATE_DETAILS: "FAN_UPDATE_DETAILS",
    CREATOR_LOBBY: "CREATOR_LOBBY",
    CREATOR_FAN_DETAILS: "CREATOR_FAN_DETAILS",
  },
  DRAWERS: {
    INIT: "INIT",
    CREATOR_COMMUNITY: "CREATOR_COMMUNITY",
    FAN_COMMUNITY: "FAN_COMMUNITY",
  },
};

export default SCREENS;
