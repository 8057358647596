import { createContext, useState } from "react";
import SCREENS from "../config/SCREENS";

export const GunRunnerContext = createContext();

const GunRunnerProvider = ({ children }) => {
  const [currentDrawerScreen, setCurrentDrawerScreen] = useState(
    SCREENS.DRAWERS.INIT
  )
  const [currentDrawerOpen, setCurrentDrawerOpen] = useState(false)
  const [creatorLive, setCreatorLive] = useState(true)
  const [hasGameStarted, setGameStarted] = useState(false)
  const [gameInProgress, setGameInProgress] = useState(false)
  const [metaractid, setMetaractID] = useState("")
  const [livesessionid, setLiveSessionID] = useState("")
  const [timeEnd, setTimeEnd] = useState(0)
  const [gunRunnerTutorialModal, setGunRunnerTutorialModal] = useState(false)
  const [hasntJoinedRoom, setHasntJoinedRoom] = useState(true)
  const [socket, setSocket] = useState()
  const [gunRunner, setGunRunner] = useState()
  const [gunRunnerItem, setGunRunnerItem] = useState()
  const [gunRunnerQueue, setGunRunnerQueue] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(
    SCREENS.INIT
  );
  const [currPlayerID, setCurrPlayerID] = useState(null);

  return (
    <GunRunnerContext.Provider
      value={{
        currentScreen,
        setCurrentScreen,
        currentDrawerScreen,
        setCurrentDrawerScreen,
        currentDrawerOpen,
        setCurrentDrawerOpen,
        creatorLive,
        setCreatorLive,
        hasGameStarted,
        setGameStarted,
        gameInProgress,
        setGameInProgress,
        timeEnd,
        setTimeEnd,
        metaractid,
        setMetaractID,
        livesessionid,
        setLiveSessionID,
        gunRunnerTutorialModal,
        setGunRunnerTutorialModal,
        hasntJoinedRoom,
        setHasntJoinedRoom,
        socket,
        setSocket,
        gunRunner,
        setGunRunner,
        isLoading,
        setIsLoading,
        currPlayerID,
        setCurrPlayerID,
        gunRunnerItem,
        setGunRunnerItem,
        gunRunnerQueue,
        setGunRunnerQueue,
      }}
    >
      {children}
    </GunRunnerContext.Provider>
  );
};

export default GunRunnerProvider;
