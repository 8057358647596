import React, { useState, useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import * as R from "ramda"

import { UsersContext } from "../../context/users"
import { CommunityContext } from "../../context/community"
import { MetaractShelfContext } from "./context"

import MetaractShelfDrawer from "../../components/MetaractShelfDrawer"
import SideBar from "../../components/Navigation/SideBar"
import MetaractShelfGrid from "../../components/MetaractShelfGrid"

const MetaractShelf = () => {
  const usersContext = useContext(UsersContext)
  const communityContext = useContext(CommunityContext)

  const { id } = useParams()

  const [isValidCommunity, setIsValidCommunity] = useState(false)
  const [communityMetaracts, setCommunityMetaracts] = useState(false)
  const [earnings, setEarnings] = useState(0)

  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext)
  const getCommunity = async () => {
    try {
      communityContext.setIsLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${id}`
      )
      console.log(
        "Queried => getCommunity fired on Community Page",
        "=>",
        response.data.data
      )
      const community = response.data.data

      if (community) {
        communityContext.setName(community.name)
        communityContext.setHandle(community.handle)
        communityContext.setIsLive(community.islive)
        communityContext.setSupporters(community.supporters)
        communityContext.setCreatorID(community.creatorid)
        communityContext.setMetaracts(
          community.metaracts.map((metaract) => metaract.metaract)
        )
        communityContext.setDescription(community.description)
        communityContext.setSrc(community.streamsrc)
        communityContext.setCreator(community.creator)
        communityContext.setID(community.id)
        setEarnings(
          community.transactions
            .filter((transaction) => transaction.status === "COMPLETED")
            .reduce((s, transaction) => transaction.amount + s, 0)
        )
        setIsValidCommunity(true)
      } else {
        setIsValidCommunity(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      communityContext.setIsLoading(false)
    }
  }

  const getMetaracts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaracts?communityid=${id}&orderBy="asc"`
      )
      console.log(
        "Queried => getMetaracts fired on Metaract Shelf Page",
        "=>",
        response.data.data
      )
      setCommunityMetaracts(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCommunity()
  }, [])

  useEffect(() => {
    if (!communityContext.isLoading) {
      getMetaracts()
    }
  }, [communityContext.isLoading])

  if (communityContext.isLoading) return <h1>loading</h1>

  if (userType === "FAN" || !isValidCommunity) {
    return (
      <div>
        <h1>Error, you are a fan.</h1>
      </div>
    )
  }

  return (
    <div className="flex w-screen bg-dashboardbackground">
      <div className="flex w-1/12">
        <SideBar />
      </div>
      <div className="flex w-11/12 bg-dashboardbackground">
        <MetaractShelfGrid
          metaracts={communityMetaracts}
          id={id}
          earnings={earnings}
        />
      </div>
      <MetaractShelfDrawer />
    </div>
  )
}

export default MetaractShelf
