import React, { useState, useContext } from "react"
import axios from "axios"
import { toast } from "react-toastify"

import { CommunityContext } from "../../../../context/community"
import { MetaractShelfContext } from "../../../../pages/MetaractShelf/context"
import {
  DrawerButton,
  DrawerInput,
  DrawerContainer,
} from "../../../Metaracts/Common"

const MetaractShelfLaunchDare = () => {
  const communityContext = useContext(CommunityContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const [minPrice, SetMinPrice] = useState(5)
  const [duration, setDuration] = useState(1)

  const handleChangeMinPrice = (eventvalue) => {
    SetMinPrice(Number(eventvalue))
  }

  const handleSubmit = (event) => {
    const description = {
      minprice: Number(minPrice),
      timeend: Date.now() + 60 * 1000 * duration,
      islive: false,
      pickeddareid: "",
    }
    //might need to fix later

    const addNewDare = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/createmetaract`,
          {
            type: "DARE",
            description,
            status: "CREATED",
            communities: [{ communityid: communityContext.id }],
          }
        )
        if (res.data?.message === "Success") {
          console.log("Added new Dare from Creator: ", res.data)
          toast("Dare Clash Created! Go have some FUN!")
        }
      } catch (error) {
        console.log(error)
      }
    }

    addNewDare()

    metaractShelfContext.setDrawerIsOpen(false)
  }

  return (
    <DrawerContainer
      type="dare"
      title="COMPLETE DARES COLLECT THE POT"
      onClose={() => {
        metaractShelfContext.setDrawerIsOpen(false)
      }}
      footer={
        <DrawerButton
          text="LAUNCH DARE CLASH"
          onClick={handleSubmit}
          styles="mx-2"
        />
      }
    >
      <p className="text-base text-dashboardtext font-hk">
        Get fans to post dares and vote on them. Once voting ends, accept the
        top voted dare on stream and do it to collect the pot.
      </p>
      <p className="text-base text-dashboardtext font-hk mt-6">
        METARACT SETTINGS
      </p>
      {DrawerInput({
        type: "number",
        placeholder: "set duration",
        onChange: (event) => setDuration(event.target.value),
      })}
      {DrawerInput({
        type: "number",
        placeholder: "set min price",
        onChange: handleChangeMinPrice,
      })}
    </DrawerContainer>
  )
}

export default MetaractShelfLaunchDare
