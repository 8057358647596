import React, { useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Modal } from "@mui/material"
import axios from "axios"
import * as R from "ramda"
import { ReactComponent as DropDownIcon } from "../../../assets/svg/dropdown.svg"
import { ReactComponent as NotificationIcon } from "../../../assets/svg/notification.svg"

import { XMarkIcon } from "@heroicons/react/20/solid"
import LogoMoblabs from "../../../assets/Images/Logo/PatchLogo 3.svg"
import { UsersContext } from "../../../context/users"
import SearchBar from "../SearchBar"
import NotificationDropdown from "../../Notifications/NotificationDropdown"
import SignOutButton from "../../Authentication/SignOutButton"
import SignInButton from "../../Authentication/SignInButton"
// import StripeContainer from "../../StripeContainer"
import Wallet from "../../Wallet"
import TobBarDropdown from "../TobBarDropdown"

const MyCommunity = () => {
  const navigate = useNavigate()
  const handleMyCommunity = () => {
    navigate(`/community/${usercommunity}`)
  }
  const usersContext = useContext(UsersContext)
  const usercommunity = R.pathOr(
    "",
    ["userDBObject", "createdcommunity", "id"],
    usersContext
  )

  return (
    <div className="flex justify-center py-1">
      {usercommunity && (
        <button
          onClick={handleMyCommunity}
          className="bg-transparent hover:bg-dashboardhighlight text-dashboardtext hover:text-black font-ocr pt-2 pb-1 px-4 text-sm border-dashboardhighlight border-2 rounded"
        >
          Community
        </button>
      )}
    </div>
  )
}

const TopBar = () => {
  const [showNotificationDropdownState, setShowNotificationDropdownState] =
    useState(false)
  const usersContext = useContext(UsersContext)
  const navigate = useNavigate()
  const [balance, setBalance] = useState(0)
  const [showWalletModal, setShowWalletModal] = useState(false)

  const getBalance = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuser/${usersContext?.userDBObject?.id}`
      )
      const total = R.pathOr(0, ["data", "data", "wallet", "total"], res)

      const committed = R.pathOr(
        0,
        ["data", "data", "wallet", "committed"],
        res
      )
      setBalance(Number(total) - Number(committed))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (usersContext?.userDBObject?.id) {
      getBalance()
      const interval = setInterval(() => {
        getBalance()
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [usersContext?.userDBObject])

  return (
    <div className="w-screen sticky top-0 z-50 flex items-center justify-between drop-shadow-lg lg:min-w-[1024px] bg-dashboardbackground pr-4 overflow-hidden sm:overflow-visible">
      {showWalletModal && (
        <Modal
          open={showWalletModal}
          onClose={() => {
            setShowWalletModal(false)
          }}
        >
          <div className="flex h-screen w-screen items-center justify-center">
            <div className="flex flex-col sm:w-[700px] sm:h-[600px] w-screen h-screen bg-daresidepanelbg rounded-xl ">
              <div className="flex w-full justify-between pt-4 items-center px-4 ">
                <h1 className="font-ocr text-dashboardtext text-xl">Wallet</h1>
                <div
                  onClick={() => {
                    setShowWalletModal(false)
                  }}
                  className="w-10 h-10 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
                >
                  <XMarkIcon />
                </div>
              </div>
              <div className="flex w-full flex-grow">
                <Wallet />
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div
        className="ml-3.5 hover:cursor-pointer"
        onClick={() => {
          navigate("/")
        }}
      >
        <img src={LogoMoblabs} alt="Logo" width="126px" className="transform" />
      </div>
      <SearchBar />
      <div className="flex items-center py-1.5 justify-between">
        {/* //CHANGE BACK */}
        {!usersContext.userIsSignedInState ? (
          <button className="flex items-center mr-10">
            <p className="font-proxima text-dashboardtext font-bold text-md">
              Explore Moblabs
            </p>
          </button>
        ) : (
          <div className="flex items-center">
            <div className=" flex  pr-10 ">
              <button
                onClick={() => {
                  setShowWalletModal(true)
                }}
                className="items-center"
              >
                <h1 className="font-bold text-dashboardhighlight">
                  {`Wallet: $${balance}
                  `}
                </h1>
              </button>
            </div>
            <div
              onClick={() =>
                setShowNotificationDropdownState(!showNotificationDropdownState)
              }
            >
              <NotificationIcon className="w-6 h-6 stroke-2 stroke-dashboardtext fill-none" />
            </div>
            {showNotificationDropdownState && <NotificationDropdown />}
            <div className="ml-12">
              {usersContext.userIsSignedInState && (
                <div
                  style={{
                    backgroundImage: `url(${usersContext.userDBObject?.profilepictureurl})`,
                  }}
                  className="rounded-full w-8 h-8 bg-cover bg-no-repeat bg-center"
                ></div>
              )}
            </div>
          </div>
        )}
        <div className="ml-2">
          {usersContext.userIsSignedInState && (
            <TobBarDropdown
              dropElement={<DropDownIcon className="px-0.5 pt-2 h-5 w-5" />}
            >
              <MyCommunity />
              <SignOutButton />
            </TobBarDropdown>
          )}
          {!usersContext.userIsSignedInState && <SignInButton />}
        </div>
      </div>
    </div>
  )
}

export default TopBar
