import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import * as R from "ramda"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "../CheckoutForm"
import { UsersContext } from "../../context/users"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

export const StripeContainer = ({ itemid }) => {
  const [clientSecret, setClientSecret] = useState("")
  const usersContext = useContext(UsersContext)
  const userID = R.pathOr("", ["userDBObject", "id"], usersContext)

  const paymentIntent = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}payment/create-payment-intent`,
        {
          itemid: `${itemid}`,
          userid: `${userID}`,
        }
      )
      const cs = R.pathOr("", ["data", "clientSecret"], res)
      setClientSecret(cs)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    paymentIntent()
  }, [])

  const appearance = {
    theme: "stripe",
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div className="flex">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}

export default StripeContainer
