export const rarityColors = {
  common: "commonBlue",
  rare: "rareOrange",
  epic: "epicPurple",
}

export const textRarityColors = {
  common: "text-commonBlue",
  rare: "text-rareOrange",
  epic: "text-epicPurple",
}

export const bgRarityColors = {
  common: "bg-commonBlue",
  rare: "bg-rareOrange",
  epic: "bg-epicPurple",
}

export const borderRarityColors = {
  common: "border-commonBlue",
  rare: "border-rareOrange",
  epic: "border-epicPurple",
}

export const buttonRaritycolors = {
  common: "bg-black text-commonBlue hover:bg-commonBlue hover:text-black",
  rare: "bg-black text-rareOrange hover:bg-rareOrange hover:text-black",
  epic: "bg-black text-epicPurple hover:bg-epicPurple hover:text-black",
}
