import { useState, useMemo } from "react"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"

import { WithDiagonalsTitle } from "../../Common/WithDiagonalsTitle"
import DareCard from "../Dare/DareCard"

export const DareItemsList = ({ title, dareItems, userID, type }) => {
  const [expanded, setExpanded] = useState(false)

  const dareItemsWithIndex = useMemo(() => {
    return dareItems
      .sort((a, b) => (a.price < b.price ? 1 : -1))
      .map((item, i) => ({ ...item, index: i + 1 }))
  }, [dareItems])

  const contributedDareItems = useMemo(
    () =>
      dareItemsWithIndex.filter((dareitem) =>
        dareitem?.userids.includes(userID)
      ) || [],
    [dareItemsWithIndex, userID]
  )

  const contributedToShow = useMemo(() => {
    if (expanded) {
      return contributedDareItems
    } else {
      return [contributedDareItems[0]]
    }
  }, [contributedDareItems, expanded])

  return (
    <div className="overflow-scroll no-scrollbar">
      <div className="flex flex-col mt-10 mb-8 sm:mt-16 sm:mb-12">
        <WithDiagonalsTitle title={title} />
        {!!contributedDareItems.length && (
          <div className="mb-6 sm:mb-10">
            <div className="flex flex-col border-4 rounded-lg border-dashboardaccent gap-y-3">
              {contributedToShow.map((dareitem, i) => {
                return (
                  <DareCard
                    description={dareitem?.description}
                    title={dareitem?.title}
                    price={dareitem?.price}
                    id={dareitem?.id}
                    users={dareitem?.userids}
                    key={i}
                    index={dareitem?.index}
                    type={type}
                    noBorder
                  />
                )
              })}
            </div>
            {contributedDareItems?.length > 1 ? (
              expanded ? (
                <FiChevronUp
                  onClick={() => setExpanded(!expanded)}
                  className="bg-dashboardaccent rounded-b text-2xl -mt-1 mx-auto h-4"
                />
              ) : (
                <FiChevronDown
                  onClick={() => setExpanded(!expanded)}
                  className="bg-dashboardaccent rounded-b text-2xl -mt-1 mx-auto h-4"
                />
              )
            ) : null}
          </div>
        )}
        <div className="flex flex-col gap-y-3 mt-2">
          {dareItemsWithIndex.map((dareitem, i) => {
            return (
              <DareCard
                description={dareitem?.description}
                title={dareitem?.title}
                price={dareitem?.price}
                id={dareitem?.id}
                users={dareitem?.userids}
                key={i}
                index={dareitem?.index}
                type={type}
                withButton={true}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
