import React, { useState, useContext } from "react"
import axios from "axios"

import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { DareContext } from "../../../../../../../pages/Dare/context"
import { SocketContext } from "../../../../../../../context/socket"
import {
  DrawerButton,
  DrawerInput,
  DrawerContainer,
} from "../../../../../Common"

const CreatorLaunchDare = () => {
  const dareContext = useContext(DareContext)

  const [minPrice, SetMinPrice] = useState(5)
  const [duration, setDuration] = useState(1)

  const socket = useContext(SocketContext)

  const handleChangeMinPrice = (eventvalue) => {
    SetMinPrice(Number(eventvalue))
  }

  const handleSubmit = (event) => {
    const description = {
      minprice: Number(minPrice),
      timeend: Date.now() + 60 * 1000 * duration,
      islive: false,
    }

    const addNewDare = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${dareContext.metaractid}`,
          {
            description,
          }
        )
        console.log("Updated Dare Settings from Creator: ", res.data)
        if (socket) {
          socket.emit("SEND_DARE_HAS_CHANGED", `dare_${dareContext.metaractid}`)
        }
      } catch (error) {
        console.log(error)
      }
    }

    addNewDare()
    dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
  }

  return (
    <DrawerContainer
      type="dare"
      back
      onClose={() => {
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
      }}
      title="COMPLETE DARES COLLECT THE POT"
      footer={
        <DrawerButton
          text="UPDATE DARE CLASH"
          onClick={handleSubmit}
          styles="mx-2"
        />
      }
      childrenContainerStyle="gap-y-4"
    >
      <p className="text-base text-dashboardtext font-hk">
        Get fans to post dares and vote on them. Once voting ends, accept the
        top voted dare on stream and do it to collect the pot.
      </p>
      <p className="text-base text-dashboardtext font-hk">METARACT SETTINGS</p>
      {DrawerInput({
        type: "number",
        placeholder: "set duration",
        onChange: (event) => setDuration(event.target.value),
      })}
      {DrawerInput({
        type: "number",
        placeholder: "set min price",
        onChange: handleChangeMinPrice,
      })}
    </DrawerContainer>
  )
}

export default CreatorLaunchDare
