import React, { useState, useContext, useEffect } from "react"

import MetaractShelfDareDetails from "../../components/MetaractShelfGrid/MetaractShelfDareDetails"
import MetaractShelfDrawer from "../../components/MetaractShelfDrawer"
import { MetaractShelfContext } from "../MetaractShelf/context"

const MetaractShelfDetails = () => {

  const metaractShelfContext = useContext(MetaractShelfContext)
  console.log(metaractShelfContext, "metaractShelfContext")
  return (
    <div className="flex flex-col w-screen h-screen bg-dashboardbackground">
        <MetaractShelfDareDetails />
        <MetaractShelfDrawer />

    </div>
  )
}

export default MetaractShelfDetails
