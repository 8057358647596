import React, { useContext } from "react";

import { AccountSettingsContext } from "../../../context/accountSettings";
import ACCOUNT_SETTINGS_SCREENS from "../../../config/screens/AccountSettings";
import PasswordChange from "../PasswordChange";
import PersonalInformation from "../PersonalInformation";

const MainContent = () => {
  const accountSettingsContext = useContext(AccountSettingsContext);

  const renderMainContent = () => {
    if (
      accountSettingsContext.currentScreenState ===
      ACCOUNT_SETTINGS_SCREENS.PERSONAL_INFO
    ) {
      return <PersonalInformation />;
    }
    if (
      accountSettingsContext.currentScreenState ===
      ACCOUNT_SETTINGS_SCREENS.PASSWORD
    ) {
      return <PasswordChange />;
    }
  };

  return renderMainContent();
};

export default MainContent;
