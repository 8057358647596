import { BsDot } from "react-icons/bs"
import { ReactComponent as TwitchIcon } from "../../../assets/svg/twitch.svg"
import { ImgAvatar } from "../atoms/ImgAvatar"

export const CommunityHeader = ({
  communityContext,
  communityMetaracts,
  userHasJoined,
  handleJoin,
  handleUnJoin,
}) => (
  <div className=" bg-daresidepanelbg justify-between w-full flex opacity-80 rounded-lg py-1.5 my-3 px-6">
    <div className="flex items-center align-middle w-full">
      <ImgAvatar
        alt="creator profile"
        src={communityContext.creator.profilepictureurl}
        square
      />
      <div className="flex-col">
        <p className="font-ocr ml-4  text-white text-2xl leading-7">
          {communityContext.name}
        </p>
        <h5 className="font-proxima ml-4 text-dashboardalttext text-base leading-4">
          @{communityContext.handle}
        </h5>
      </div>
      <div className="flex flex-row h-full p-2 gap-2">
        <TwitchIcon className="text-twitch mt-0.5" />
        <span className="h-5 border-red-500 border rounded-full text-white font-hk text-xs leading-4 font-bold pr-2 pt-0.5 ">
          <BsDot className="text-dashboardalttext inline text-3xl -mx-1 -mb-3 -mt-3.5" />
          {communityContext.islive ? "ONLINE" : "OFFLINE"}
        </span>
      </div>
    </div>
    {/* TODO check if this has to be added */}

    <div className="flex">
      <div className="flex flex-col px-2 justify-center items-end">
        <p className="font-bold px-2 text-dashboardhighlight whitespace-nowrap text-sm">
          Supporters: {communityContext.supporters}
        </p>
        <p className="font-bold px-2 text-dashboardhighlight text-sm">
          Metaracts: {communityMetaracts.length}
        </p>
      </div>
      <div className="px-4 flex items-center">
        {!userHasJoined && (
          <button
            onClick={handleJoin}
            className="group flex  py-2 px-2 font-ocr text-md border border-dashboardhighlight bg-black items-center justify-center text-dashboardtext rounded-full hover:bg-dashboardhighlight hover:text-black transition ease-in-out"
          >
            <h1>JOIN</h1>
            <svg
              viewBox="0 0 24 24"
              className="w-4 h-4 stroke stroke-dashboardtext group-hover:stroke-black ml-1"
            >
              <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
            </svg>
          </button>
        )}
        {userHasJoined && (
          <button
            onClick={handleUnJoin}
            className="group flex  py-2 px-2 font-ocr text-md border border-dashboardhighlight bg-gray-700 items-center justify-center text-dashboardtext rounded-full hover:bg-dashboardhighlight hover:text-black transition ease-in-out"
          >
            <h1>JOINED</h1>
          </button>
        )}
      </div>
    </div>
  </div>
)
