import React, { useEffect, useState } from "react";
import { Flex } from "rebass";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import * as R from "ramda";
import BalanceSide from "../../components/Payment/BalanceSide";
import Checkbox from "../../components/Common/Checkbox";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://google.com",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form>
      <PaymentElement />
      <button onClick={handleSubmit}>Submit</button>
    </form>
  );
};

const Payment = () => {
  const stripePromise = loadStripe(
    "pk_test_51MEitCFvVaq6qSDnjJnGyEoLlqEFOMpvyv6N59oXDTMPlqkcs4m76Oyqbn9g8telJYgQA1ofa2cyCMnT1i5z86TQ002gRY3XyY"
  );
  const [clientSecretState, setClientSecretState] = useState("");

  const getPaymentIntent = async () => {
    try {
      const paymentIntentResponse = await axios.post(
        "http://localhost:8080/stripe/get-payment-intent",
        {
          amount: 1000,
        }
      );
      const clientSecret = R.pathOr(
        "",
        ["data", "client_secret"],
        paymentIntentResponse
      );
      setClientSecretState(clientSecret);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentIntent();
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecretState,
  };

  return (
    clientSecretState && (
      <Flex style={{ width: "100vw" }} flex={2} pt="100px">
        <Flex flex={1}>
          <BalanceSide />
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
          <Checkbox label="Save my info for secure 1-click checkout" />
        </Flex>
      </Flex>
    )
  );
};

export default Payment;
