import React, { useContext, useState, useEffect } from "react"
import axios from "axios"
import DareCard from "../DareCard"
import { DrawerContainer } from "../../Common"
import { ReactComponent as GlowingClashImg } from "../../../../assets/svg/glowing-clash-img.svg"

import { DareContext } from "../../../../pages/Dare/context"
// import SCREENS from "../../../../pages/Dare/config/SCREENS"

const FanLiveDarePickedDare = ({ dareitemid }) => {
  const [dareitem, setDareItem] = useState("")
  const dareContext = useContext(DareContext)
  const getDareItem = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}dareitem/getdareitem/${id}`
      )
      setDareItem(res.data.data)
      console.log(res.data.data, "result in FanDareLivePickedDare")
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getDareItem(dareitemid)
  }, [])

  return (
    <DrawerContainer onClose={() => dareContext.setCurrentDrawerOpen(false)}>
      <div className="flex flex-col mt-12">
        <p className="text-base text-dashboardtext font-hk">
          Creator has picked the following dare, watch to see your creator
          complete the dare!
        </p>
        <div className="flex flex-col mt-16">
          <DareCard
            description={dareitem.description}
            title={dareitem.title}
            price={dareitem.price}
            toPick={() => null}
            picked
          />
        </div>
      </div>

      <div className=" flex justify-center h-[8vh] w-[8vh] mx-auto mt-6 sm:mt-16 mb-8 ">
        <div className="absolute w-full mt-28 mb-12 mx-auto h-[22vh]">
          <GlowingClashImg />
        </div>
      </div>
    </DrawerContainer>
  )
}

export default FanLiveDarePickedDare
