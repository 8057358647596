import React, { createContext, useState, useEffect } from "react"
import { io } from "socket.io-client"
const socket = io(`${process.env.REACT_APP_SERVER_API}`)
socket.on("connect", () =>
  console.log("connected to socket from context => ", socket.id)
)
export const SocketContext = createContext(socket)

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}
export default SocketProvider
