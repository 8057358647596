import React, { useState, useContext } from "react"
import axios from "axios"
import * as R from "ramda"
import { CommunityContext } from "../../../../../../../context/community"
import { DareContext } from "../../../../../../../pages/Dare/context"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { SocketContext } from "../../../../../../../context/socket"
import {
  DrawerButton,
  DrawerDareBox,
  DrawerDareMainInfo,
  DrawerDareDetailsInfo,
  DrawerContainer,
} from "../../../../../Common"

const DareCreatorDareDetails = () => {
  const communityContext = useContext(CommunityContext)
  const dareContext = useContext(DareContext)

  const socket = useContext(SocketContext)

  const { title, description, price, id, users } = dareContext.dareDetailsDare

  const [dareBy, setDareBy] = useState("")

  const getDareBy = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuser/${users[0]}`
      )
      const dareBy = R.pathOr("", ["data", "data", "name"], response)

      setDareBy(dareBy)
    } catch (error) {
      console.log(error)
    }
  }

  getDareBy()

  const handleSubmit = async () => {
    try {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_API}dareitem/updatedareitem/${id}`,
        { status: "REMOVED" }
      )
      console.log("dareitem removed", response.data)
      if (socket) {
        socket.emit("SEND_DARE_HAS_CHANGED", `dare_${metaract.id}`)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
    }
  }

  return (
    <DrawerContainer
      type="dare"
      back
      onClose={() => {
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
      }}
      title="DARE DETAILS"
      footer={<DrawerButton text="REMOVE DARE" onClick={handleSubmit} />}
    >
      <DrawerDareBox boxTitle="Dare Details">
        <DrawerDareMainInfo title={title} description={description} />
        <DrawerDareDetailsInfo
          dareBy={dareBy}
          price={price}
          usersLength={users?.length}
        />
      </DrawerDareBox>
    </DrawerContainer>
  )
}

export default DareCreatorDareDetails
