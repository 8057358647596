import React, { useContext, useEffect } from "react"
import axios from "axios"
import * as R from "ramda"
import { CommunityContext } from "../../context/community"
import { UsersContext } from "../../context/users"
import { DareContext } from "./context"

import { useScreen } from "../../hooks/useScreen"
import DareDrawer from "../../components/Metaracts/Dare/Screens/DareDrawers"
import DareTutorialModal from "../../components/Metaracts/Dare/DareTutorialModal"
import SCREENS from "./config/SCREENS"
import { SocketContext } from "../../context/socket"
import { MetaractShelfContext } from "../MetaractShelf/context"
import { CardTitle } from "../../components/Common/atoms/CardTitle"
import MetaractCard from "../../components/Common/MetaractCard"
import DareVotingTimeLeft from "../../components/Metaracts/Dare/DareVotingTimeLeft"
import { ReactComponent as CountdownBox } from "../../assets/svg/countdown-box.svg"
import { ReactComponent as DashCardBar } from "../../assets/svg/dash-card-bar.svg"

const Dare = ({ shelf }) => {
  const communityContext = useContext(CommunityContext)
  const usersContext = useContext(UsersContext)
  const dareContext = useContext(DareContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const socket = useContext(SocketContext)
  const { isMobile } = useScreen()

  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext)
  const userID = R.pathOr("", ["userDBObject", "id"], usersContext)

  const metaracts = R.pathOr([], ["metaracts"], communityContext)
  const metaract = metaracts.find(
    (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
  )
  // const activeDare = R.pathOr([], ["dareitems"], metaract).filter(
  //   (item) => item.status === "ACTIVE"
  // ).length

  const getDare = async () => {
    dareContext.setIsLoading(true)
    try {
      const metaracts = R.pathOr(0, ["metaracts"], communityContext)
      const metaract = metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      dareContext.setMetaractID(metaract.id)
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${metaract.id}`
      )
      dareContext.setDare(res.data.data)
      console.log("Queried => getDare fired on Dare Page", res.data.data)
    } catch (error) {
      console.log(error)
    } finally {
      dareContext.setIsLoading(false)
    }
  }

  const handleClick = () => {
    if (userType === "CREATOR" && communityContext.creatorid === userID) {
      dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.CREATOR_COMMUNITY)
    } else {
      dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.FAN_COMMUNITY)
    }
    dareContext.setDareTutorialModal(true)
    dareContext.setCurrentDrawerOpen(true)
    communityContext.setDrawerOpen(true)
  }

  useEffect(() => {
    if (shelf === true && metaractShelfContext.drawerIsOpen === true) {
      if (userType === "CREATOR" && communityContext.creatorid === userID) {
        dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.CREATOR_COMMUNITY)
      } else {
        dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.FAN_COMMUNITY)
      }
      dareContext.setCurrentDrawerOpen(true)
      communityContext.setDrawerOpen(true)
    }
  }, [])

  useEffect(() => {
    if (!communityContext.isLoading) {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      if (metaract !== undefined) {
        dareContext.setMetaractID(metaract.id)
      }

      getDare()

      if (socket) {
        if (metaract !== undefined) {
          if (metaract.id !== undefined) {
            if (dareContext.hasntJoinedRoom) {
              socket.emit("JOIN_ROOM", `dare_${metaract?.id}`)
              dareContext.setHasntJoinedRoom(false)
            }
            dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
          }
        }
      }
    }
  }, [communityContext.isLoading])

  useEffect(() => {
    const metaract = communityContext.metaracts.find(
      (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
    )
    if (metaract !== undefined) {
      if (socket !== undefined) {
        socket.on("RECEIVE_DARE_HAS_CHANGED", (data) => {
          dareContext.setMetaractID(metaract.id)
          getDare()
          console.log("Event => RECEIVE_DARE_HAS_CHANGED")
        })
      }
    }
    return () => {
      socket.off("RECEIVE_DARE_HAS_CHANGED")
    }
  }, [socket])

  const renderCurrentScreen = () => {
    return (
      <div className="absolute">
        {!isMobile && <DareTutorialModal />}
        <DareDrawer shelf={shelf} />
      </div>
    )
  }
  return (
    <>
      <MetaractCard onClick={handleClick}>
        {!isMobile && (
          <div className="absolute h-full w-[290px] mt-5">
            <CountdownBox className="block absolute mx-auto bottom-5" />
          </div>
        )}
        <div className="flex flex-col gap-4 h-full">
          <CardTitle text="Dare Clash" />
          <div className="h-full flex flex-col items-center justify-center">
            <DareVotingTimeLeft timeend={metaract?.timeend} dareCard />
            <DashCardBar className="mx-auto bottom-5" />
          </div>
        </div>
      </MetaractCard>
      {renderCurrentScreen()}
    </>
  )
}

export default Dare
