import React, { useState, useEffect } from "react"
// import GradientCard from "../../../Common/GradientCard"
// import GradientCardContentText from "../../../Common/GradientCardContentText"
import moment from "moment"

const DareVotingTimeLeft = ({ timeend, dareCard }) => {
  const [timeLeft, setTimeLeft] = useState("00h 00m 00s")

  const msToTime = (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    hours = hours < 10 ? "0" + hours : hours
    minutes = minutes < 10 ? "0" + minutes : minutes
    seconds = seconds < 10 ? "0" + seconds : seconds

    return hours + "h " + minutes + "m " + seconds + "s "
  }

  const setTime = () => {
    const newTimeLeft = moment(timeend).diff(moment())
    if (newTimeLeft < 0 || isNaN(newTimeLeft)) {
      setTimeLeft("00h 00m 00s")
    } else {
      const formattedtimeleft = msToTime(newTimeLeft)
      setTimeLeft(formattedtimeleft)
    }
  }

  useEffect(() => {
    setTime()

    const interval = setInterval(() => {
      setTime()
    }, 1000)
    return () => clearInterval(interval)
  }, [timeend])

  const Time = () => (
    <h1
      className={`font-ocr text-center text-dashboardhighlight ${
        dareCard && "mb-4"
      }`}
    >
      {timeLeft.trim() === "00h 00m 00s"
        ? "WAITING FOR CREATOR"
        : `IN ${timeLeft}`}
    </h1>
  )
  if (dareCard) return <Time />
  return (
    <div
      className="rounded-lg bg-dashboardhighlight/[.15] flex flex-col
      items-center px-12 py-6 text-xl w-full"
    >
      <Time />
    </div>
  )
}

export default DareVotingTimeLeft
