import { createContext, useState } from "react"

export const CommunityContext = createContext()

const CommunityProvider = ({ children }) => {
  const [sidebarVisibleState, setSidebarVisibleState] = useState(true)
  const [id, setID] = useState("")
  const [handle, setHandle] = useState("")
  const [islive, setIsLive] = useState(true)
  const [name, setName] = useState("")
  const [supporters, setSupporters] = useState(0)
  const [creatorid, setCreatorID] = useState("")
  const [metaracts, setMetaracts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [description, setDescription] = useState("")
  const [src, setSrc] = useState("")
  const [creator, setCreator] = useState("")
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [socket, setSocket] = useState(false)
  return (
    <CommunityContext.Provider
      value={{
        sidebarVisibleState,
        setSidebarVisibleState,
        id,
        setID,
        handle,
        setHandle,
        islive,
        setIsLive,
        name,
        setName,
        supporters,
        setSupporters,
        creatorid,
        setCreatorID,
        metaracts,
        setMetaracts,
        isLoading,
        setIsLoading,
        description,
        setDescription,
        src,
        setSrc,
        creator,
        setCreator,
        drawerOpen,
        setDrawerOpen,
        socket,
        setSocket,
      }}
    >
      {children}
    </CommunityContext.Provider>
  )
}

export default CommunityProvider
