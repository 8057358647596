import React, { useState } from "react"

import SCREENS from "./config/screens"
import CreateCommunity from "./screens/CreateCommunity"
import EmailPasssword from "./screens/EmailPassword"
import FinishProfile from "./screens/FinishProfile"
import { AuthContainer } from "../AuthContainer"
import { AuthHeader } from "../AuthHeader"

const SignupForm = ({ isCreator }) => {
  const [currentScreenState, setCurrentScreenState] = useState(
    SCREENS.EMAIL_PASSWORD
  )
  const titles = {
    [SCREENS.EMAIL_PASSWORD]: "Sign Up for Patch",
    [SCREENS.FINISH_PROFILE]: "Create Your Profile",
    [SCREENS.CREATE_COMMUNITY]: "Create Your Community",
  }
  const renderCurrentScreen = () => {
    if (currentScreenState === SCREENS.EMAIL_PASSWORD) {
      return (
        <EmailPasssword
          isCreator={isCreator}
          setCurrentScreenState={setCurrentScreenState}
        />
      )
    }
    if (currentScreenState === SCREENS.FINISH_PROFILE) {
      return (
        <FinishProfile
          isCreator={isCreator}
          setCurrentScreenState={setCurrentScreenState}
        />
      )
    }
    if (currentScreenState === SCREENS.CREATE_COMMUNITY) {
      return <CreateCommunity />
    }
  }

  return (
    <AuthContainer
      header={
        <AuthHeader
          text={titles[currentScreenState]}
          // TODO: put this properties back to show arrow
          // back onClick={() => ""}
        />
      }
    >
      {renderCurrentScreen()}
    </AuthContainer>
  )
}

export default SignupForm
