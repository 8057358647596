import styled from "styled-components";

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d1d1d1;
  margin: 10px 0;
`;

export const SingleFeedItem = styled.div`
  padding: 12px 16px;
  width: calc(100% - 32px);
  background: #ffffff;
  border: 2px solid #d1d1d1;
  border-radius: 8px;
  margin-bottom: 10px;
`;

export const SmallAvatar = styled.div`
  border-radius: 50%;
  background-color: red;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const TitleText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #101010;
`;

export const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #101010;
`;
