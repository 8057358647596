import { createContext, useState } from "react"

export const VersusContext = createContext()

const NEW_VERSUS_SCREENS = {
  SETUP: "SETUP",
  CHOOSE_CREATOR: "CHOOSE_CREATOR",
  POWER_UPS: "POWER_UPS",
  ACCEPT_BATTLE: "ACCEPT_BATTLE",
  LOADING: "LOADING",
}

const VersusProvider = ({ children }) => {
  const [versusBattleInformationState, setVersusBattleInformationState] =
    useState([])
  const [newVersusDrawerOpenState, setNewVersusDrawerOpenState] =
    useState(false)
  const [currentNewVersusScreensState, setCurrentNewVersusScreensState] =
    useState(NEW_VERSUS_SCREENS.SETUP)
  const [powerUpsState, setPowerUpStates] = useState([])
  const [socketState, setSocketState] = useState(null)
  const [battleFeed, setBattleFeed] = useState([])
  const [setupState, setSetupState] = useState({})
  const [currentDrawerOpen, setCurrentDrawerOpen] = useState(false)
  const [versus, setVersus] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [creator, setCreator] = useState()
  const [selectedCreator, setSelectedCreator] = useState()
  const [battleDrawerOpen, setBattleDrawerOpen] = useState(false)
  const [versusItems, setVersusItems] = useState([])
  const [hasntJoinedRoom, setHasntJoinedRoom] = useState(true)

  return (
    <VersusContext.Provider
      value={{
        newVersusDrawerOpenState,
        setNewVersusDrawerOpenState,
        newVersusScreens: NEW_VERSUS_SCREENS,
        currentNewVersusScreensState,
        setCurrentNewVersusScreensState,
        powerUpsState,
        setPowerUpStates,
        socketState,
        setSocketState,
        battleFeed,
        setBattleFeed,
        versusBattleInformationState,
        setVersusBattleInformationState,
        setupState,
        setSetupState,
        currentDrawerOpen,
        setCurrentDrawerOpen,
        versus,
        setVersus,
        isLoading,
        setIsLoading,
        creator,
        setCreator,
        selectedCreator,
        setSelectedCreator,
        battleDrawerOpen,
        setBattleDrawerOpen,
        versusItems,
        setVersusItems,
        hasntJoinedRoom,
        setHasntJoinedRoom,
      }}
    >
      {children}
    </VersusContext.Provider>
  )
}

export default VersusProvider
