import React, { useContext } from "react";

import Stepper from "../../components/CommunitySetup/Stepper";
import CardContainer from "../../components/CommunitySetup/CardContainer";
import { CommunitySetupContext } from "../../context/communitySetup";
import COMMUNITY_SETUP_SCREENS from "../../config/screens/CommunitySetup";
import InfoScreen from "../../components/CommunitySetup/screens/InfoScreen";
import BrandingScreen from "../../components/CommunitySetup/screens/BrandingScreen";
import MetaractSetupScreen from "../../components/CommunitySetup/screens/MetaractSetupScreen";

import * as Styled from "./styles";

const CommunitySetup = () => {
  const communitySetupContext = useContext(CommunitySetupContext);

  const renderContent = () => {
    if (
      communitySetupContext.currentScreenState ===
      COMMUNITY_SETUP_SCREENS.INFO.key
    ) {
      return <InfoScreen />;
    }
    if (
      communitySetupContext.currentScreenState ===
      COMMUNITY_SETUP_SCREENS.BRANDING.key
    ) {
      return <BrandingScreen />;
    }
    if (
      communitySetupContext.currentScreenState ===
      COMMUNITY_SETUP_SCREENS.YOUR_FIRST_METARACT.key
    ) {
      return <MetaractSetupScreen />;
    }
  };

  return (
    <Styled.Container>
      <Styled.Title>Build Your Community</Styled.Title>
      <Stepper />
      {communitySetupContext.currentScreenState ===
      COMMUNITY_SETUP_SCREENS.YOUR_FIRST_METARACT.key ? (
        renderContent()
      ) : (
        <CardContainer>{renderContent()}</CardContainer>
      )}
    </Styled.Container>
  );
};

export default CommunitySetup;
