import React from "react"
import { useNavigate } from "react-router-dom"

import ROUTES from "../../../config/routes"

const ForgotPasswordLink = () => {
  const navigate = useNavigate()

  return (
    <div
      className="text-grey underline text-center"
      onClick={() => navigate(ROUTES.RESET_PASSWORD.path)}
    >
      Forgot your password?
    </div>
  )
}

export default ForgotPasswordLink
