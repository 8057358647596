import React, { useState, useContext } from "react"
import axios from "axios"
import io from "socket.io-client"
import * as R from "ramda"
import { toast } from "react-toastify"
import { XMarkIcon } from "@heroicons/react/20/solid"

import { CommunityContext } from "../../../../../../../context/community"
import { GunRunnerContext } from "../../../../../../../pages/GunRunner/context"
import { UsersContext } from "../../../../../../../context/users"
import { SocketContext } from "../../../../../../../context/socket"
import SCREENS from "../../../../../../../pages/GunRunner/config/SCREENS"

const GunRunnerFanBuy = () => {
  const communityContext = useContext(CommunityContext)
  const usersContext = useContext(UsersContext)
  const gunRunnerContext = useContext(GunRunnerContext)
  const socket = useContext(SocketContext)

  const userID = R.pathOr("NA", ["userDBObject", "id"], usersContext)

  const [username, setUsername] = useState("")
  const [price, setPrice] = useState(0)
  const [gameid, setGameid] = useState("")
  const [serverid, setServerid] = useState("")
  const [platform, setPlatform] = useState("PC")

  console.log(gunRunnerContext, "gunrunnercontext")
  const handleChangePlatform = (event) => {
    setPlatform(event.target.value)
  }

  const handleChangeUsername = (event) => {
    setUsername(event.target.value)
  }

  const handleSubmit = (event) => {
    if (userID === "NA") {
      toast.error("Please login to add to a gunrunner")
      return
    }
    const metaract = communityContext.metaracts.find(
      (metaract) =>
        metaract.type === "GUNRUNNER" && metaract.status === "ACTIVE"
    )

    const newgunrunneritem = {
      price: Number(gunRunnerContext.gunRunner.price),
      status: "ACTIVE",
      userid: userID,
      metaractid: metaract.id,
      gameid: gunRunnerContext.gunRunner.game,
      serverid: serverid,
      username: username,
      platform: platform,
    }
    console.log(newgunrunneritem)

    const addNewGunRunner = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_API}gunrunner/creategunrunneritem`,
          newgunrunneritem
        )

        console.log("Added new Gun-Runner with ID: ", res.data)
        toast("Add Gun-Runner success! Wait to play with creator!")
        if (socket) {
          socket.emit("SEND_GUNRUNNER_HAS_CHANGED", `gunrunner_${metaract.id}`)
        }
      } catch (error) {
        console.log(error)
      }
    }

    addNewGunRunner()

    gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.INIT)
  }

  return (
    <div className="flex rounded-lg flex-col w-[var(--drawer-width)] h-screen bg-daresidepanelbg">
      <div className="flex justify-between  mx-8 mt-8 mb-10 ">
        <div className="bg-dashboardhighlight hover:cursor-pointer rounded-sm drop-shadow items-center py-1 px-4 flex justify-center">
          <p className="font-ocr text-sm">GUNRUNNER</p>
        </div>
        <div
          onClick={() => {
            gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.INIT)
          }}
          className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
        >
          <XMarkIcon />
        </div>
      </div>
      <div className="flex flex-col w-full justify-center items-center">
        <div className="flex justify-center items-center mt-5 mb-10">
          <h1 className="font-ocr text-4xl text-dashboardtext">
            {" "}
            JOIN CREATOR QUEUE
          </h1>
        </div>
        <div className="flex flex-col w-full px-10 items-start justify-center ">
          <h2 className="font-proxima text-lg text-dashboardtext">Platform</h2>
          <input
            name="platform"
            placeholder="e.g. PC, PS4, XBOX"
            onChange={handleChangePlatform}
            className="mt-2 border border-dashboardframes outline-none text-dashboardtext bg-transparent font-proxima rounded-lg py-2 w-full px-2 items-center justify-center flex"
          ></input>
        </div>
        <br></br>
        <div className="flex flex-col w-full px-10 items-start justify-center  mt-2">
          <h2 className="font-proxima text-lg text-dashboardtext">Username</h2>
          <input
            name="username"
            placeholder="Enter your username"
            onChange={handleChangeUsername}
            className="mt-2 border border-dashboardframes bg-transparent outline-none text-dashboardtext  font-proxima rounded-lg py-2 w-full px-2 items-center justify-center"
          ></input>
        </div>
        <br></br>
        <div className="flex flex-col  w-full px-10 justify-center  mt-2">
          <div className="flex w-full my-6 justify-between items-center">
            <h2 className="font-proxima text-lg text-dashboardtext">
              Pay using
            </h2>
            <h2 className="font-proxima text-lg text-dashboardtext ">
              Stripe Connect
            </h2>
          </div>
          <div className="flex w-2/3 justify-between items-center py-5 px-5 pb-8 mx-auto">
            <h2 className="font-proxima text-xl text-dashboardalttext">
              PRICE:
            </h2>
            <h2 className="font-proxima text-xl text-dashboardhighlight">
              {`USD ${Math.round(
                gunRunnerContext.gunRunner.price
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`}
            </h2>
          </div>
          <p className=" mt-2 text-dashboardalttext text-xs">
            This contribution allows you to book a place in the creator queue.
            It will be refunded if the you never get to play with the creator
            for whatever reason.
          </p>
        </div>

        <button
          onClick={handleSubmit}
          className="group flex mt-10 py-3 px-4 font-ocr text-xl border border-dashboardhighlight bg-black items-center
            justify-center text-dashboardtext rounded-full
             hover:bg-dashboardhighlight hover:text-black transition ease-in-out"
        >
          <h1>BOOK SESSION</h1>
          <svg
            viewBox="0 0 24 24"
            className="w-6 h-6 stroke stroke-dashboardtext group-hover:stroke-black ml-1"
          >
            <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default GunRunnerFanBuy
