import { createContext, useState } from "react"
import SCREENS from "../config/SCREENS"

export const DareContext = createContext()

const DareProvider = ({ children }) => {
  const [isLive, setIsLive] = useState(false)
  const [dareDetailsDare, setDareDetailsDare] = useState({
    dareTitle: "",
    dareDetail: "",
    darePrice: 0,
    dareID: "",
  })

  const [currentDrawerScreen, setCurrentDrawerScreen] = useState(
    SCREENS.DRAWERS.INIT
  )
  const [currentDrawerOpen, setCurrentDrawerOpen] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(SCREENS.SCREENS.INIT)
  const [creatorLive, setCreatorLive] = useState(true)
  const [hasClashStarted, setClashStarted] = useState(false)
  const [clashInProgress, setClashInProgress] = useState(false)
  const [metaractid, setMetaractID] = useState("")
  const [pickdareid, setPickDareID] = useState("")
  const [timeEnd, setTimeEnd] = useState(0)
  const [dareTutorialModal, setDareTutorialModal] = useState(false)
  const [hasntJoinedRoom, setHasntJoinedRoom] = useState(true)
  const [socket, setSocket] = useState()
  const [dare, setDare] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [OutOfTime, SetOutOfTime] = useState(false)

  return (
    <DareContext.Provider
      value={{
        isLive,
        setIsLive,
        dareDetailsDare,
        setDareDetailsDare,
        currentDrawerScreen,
        setCurrentDrawerScreen,
        currentDrawerOpen,
        setCurrentDrawerOpen,
        creatorLive,
        setCreatorLive,
        hasClashStarted,
        setClashStarted,
        clashInProgress,
        setClashInProgress,
        timeEnd,
        setTimeEnd,
        metaractid,
        setMetaractID,
        currentScreen,
        setCurrentScreen,
        pickdareid,
        setPickDareID,
        dareTutorialModal,
        setDareTutorialModal,
        hasntJoinedRoom,
        setHasntJoinedRoom,
        socket,
        setSocket,
        dare,
        setDare,
        isLoading,
        setIsLoading,
        OutOfTime,
        SetOutOfTime,
      }}
    >
      {children}
    </DareContext.Provider>
  )
}

export default DareProvider
