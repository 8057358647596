import styled from "styled-components";

export const Label = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #101010;
  margin-bottom: 10px;
`;

export const TextInput = styled.textarea`
  width: ${({ width = 540 }) => `${width}px`};
  padding: 18px 20px;
  border: 1px solid #d1d1d1;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #878787;
  min-height: ${({ height = 140 }) => `${height}px`};
`;

export const LabelContainer = styled.div`
  width: 570px;
  display: flex;
  justify-content: space-between;
`;

export const CharacterCounterText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #777777;
`;
