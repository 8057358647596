import styled from "styled-components";

export const Container = styled.div`
  width: 220px;
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #101010;
  margin-bottom: 32px;
`;
