import React, { useMemo } from "react"
import { TwitchEmbed } from "react-twitch-embed"

const StreamWindow = ({ src, width, height, handleReady, id }) => {
  if (!src) return <div></div>
  return (
    <TwitchEmbed
      channel={src}
      autoplay
      muted
      withChat={false}
      darkMode={false}
      onVideoReady={handleReady}
      height={height}
      width={width}
      id={id}
    />
  )
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.src === nextProps.src &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height
  )
}

export default React.memo(StreamWindow, areEqual)
