import React, { useContext } from "react"
import axios from "axios"
import { FileUploader } from "react-drag-drop-files"
import { toast } from "react-toastify"
import * as R from "ramda"
import FormData from "form-data"

import * as Styled from "./styles"
import { UsersContext } from "../../../context/users"

window.Buffer = window.Buffer || require("buffer").Buffer

const ProfilePicture = ({
  setIsLoadingState,
  customStyles,
  size,
  title = "Drag and drop your image here",
  description = "Use a PNG or JPG file, recommended dimensions are 400px by 400px, with a size limit of 4MB.",
}) => {
  const usersContext = useContext(UsersContext)

  const handleUpload = async (file) => {
    try {
      setIsLoadingState(true)
      let files = new FormData()
      files.append("file", file, usersContext.userDBObject?.id)
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}upload`,
        files,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${files._boundary}`,
          },
        }
      )
      const url = R.pathOr("", ["data", "data", "Location"], res)
      console.log("URL", "=>", url)

      const res2 = await axios.post(
        `${process.env.REACT_APP_SERVER_API}user/updateuser/${usersContext.userDBObject.id}`,
        { profilepictureurl: url }
      )
      console.log("=> update_response=>", res2.data)
    } catch (error) {
      toast.error("Oops! Something went wrong")
    } finally {
      setIsLoadingState(false)
    }
  }

  const renderCustomStyle = () => {
    if (size === "small") {
      return (
        <FileUploader>
          <Styled.Container>
            <Styled.IconContainer>
              <Styled.UpArrow />
            </Styled.IconContainer>
            <Styled.TextContainer>
              <Styled.TitleText>{title}</Styled.TitleText>
              <Styled.DescriptionText>{description}</Styled.DescriptionText>
            </Styled.TextContainer>
          </Styled.Container>
        </FileUploader>
      )
    }
    if (size === "large") {
      return (
        <FileUploader>
          <Styled.ContainerLarge>
            <Styled.IconContainerLarge>
              <Styled.UpArrow />
            </Styled.IconContainerLarge>
            <Styled.TextContainerLarge>
              <Styled.TitleText>{title}</Styled.TitleText>
              <Styled.DescriptionText>{description}</Styled.DescriptionText>
            </Styled.TextContainerLarge>
          </Styled.ContainerLarge>
        </FileUploader>
      )
    }
  }

  return customStyles ? (
    renderCustomStyle()
  ) : (
    <FileUploader
      handleChange={handleUpload}
      name="file"
      types={["JPG", "PNG", "JPEG", "HEIC"]}
      classes="min-w-min"
    />
  )
}

export default ProfilePicture
