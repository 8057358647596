import React, { useContext } from "react"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { DareContext } from "../../../../../../../pages/Dare/context"
import DareCreatorCommunity from "../../../CreatorCommunity"
import DareCreatorDareDetails from "../CreatorDareDetails"
import CreatorLaunchDare from "../CreatorLaunchDare"
import DareCreatorLiveDare from "../CreatorLiveDare"

const CreatorDrawer = () => {
  const dareContext = useContext(DareContext)
  if (dareContext.currentScreen === SCREENS.SCREENS.INIT) {
    return <DareCreatorCommunity />
  }
  if (dareContext.currentScreen === SCREENS.SCREENS.CREATOR_DARE_SETTINGS) {
    return <CreatorLaunchDare />
  }
  if (dareContext.currentScreen === SCREENS.SCREENS.CREATOR_DARE_DETAILS) {
    return <DareCreatorDareDetails />
  }
  if (dareContext.currentScreen === SCREENS.SCREENS.CREATOR_LIVE_DARE) {
    return <DareCreatorLiveDare />
  }
}

export default CreatorDrawer
