import React, { useState, useEffect } from "react"
import WalletPayment from "./screens/WalletPayment"

const Wallet = () => {
  const [screen, setScreen] = useState("DEFAULT")
  const [amountID, setAmountID] = useState(0)
  const rendercontent = () => {
    if (screen === "DEFAULT")
      return (
        <div className="flex flex-col sm:flex-row sm:px-4  items-center justify-center w-full h-full ">
          <div
            onClick={() => {
              setScreen("PAYMENT")
              setAmountID(1)
            }}
            className="flex hover:cursor-pointer my-3 sm:mx-3 rounded-lg justify-center items-center bg-dashboardbackground w-20 h-20"
          >
            <h1 className="font-ocr text-dashboardhighlight text-lg">$5</h1>
          </div>
          <div
            onClick={() => {
              setScreen("PAYMENT")
              setAmountID(2)
            }}
            className="flex hover:cursor-pointer my-3 sm:mx-3 rounded-lg justify-center items-center bg-dashboardbackground w-20 h-20"
          >
            <h1 className="font-ocr text-dashboardhighlight text-lg">$10</h1>
          </div>
          <div
            onClick={() => {
              setScreen("PAYMENT")
              setAmountID(3)
            }}
            className="flex hover:cursor-pointer my-3 sm:mx-3 rounded-lg justify-center items-center bg-dashboardbackground w-20 h-20"
          >
            <h1 className="font-ocr text-dashboardhighlight text-lg">$20</h1>
          </div>
          <div
            onClick={() => {
              setScreen("PAYMENT")
              setAmountID(4)
            }}
            className="flex hover:cursor-pointer my-3 sm:mx-3 rounded-lg justify-center items-center bg-dashboardbackground w-20 h-20"
          >
            <h1 className="font-ocr text-dashboardhighlight text-lg">$50</h1>
          </div>

          <div
            onClick={() => {
              setScreen("PAYMENT")
              setAmountID(5)
            }}
            className="flex hover:cursor-pointer mx-1  rounded-lg justify-center items-center bg-dashboardbackground w-20 h-20"
          >
            <h1 className="font-ocr text-dashboardhighlight text-lg">$100</h1>
          </div>
        </div>
      )
    if (screen === "PAYMENT") return <WalletPayment amountID={amountID} />
  }
  return <div className="flex w-full h-full">{rendercontent()}</div>
}

export default Wallet
