import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  margin-top: 32px;
`;

export const ButtonContainer = styled.div`
  width: 409px;
  margin-top: 24px;
  margin-bottom: 64px;
`;

export const CreateMetaractButton = styled.button`
  width: 239px;
  height: 58px;
  background: #101010;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 16px 20px;
  margin-right: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
`;

export const PlusIcon = styled(AddIcon)`
  && {
    font-size: 20px;
    color: #ffffff;
    margin-right: 10px;
  }
`;

export const DoItLaterButton = styled.button`
  padding: 16px 20px;
  width: 150px;
  height: 58px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  color: #101010;
`;
