const SideBarItem = ({ onClick, icon }) => (
  <div
    onClick={onClick}
    className="hover:bg-dashboardbackground my-2 rounded-md mx-auto"
  >
    {icon}
  </div>
)

export default SideBarItem
