import React, { useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as GlobeIcon } from "../../../assets/svg/globe.svg"
import { ReactComponent as MessageIcon } from "../../../assets/svg/message.svg"
import { ReactComponent as HomeIcon } from "../../../assets/svg/home.svg"

import { UsersContext } from "../../../context/users"

import axios from "axios"
import * as R from "ramda"

import SideBarCommunity from "../SideBarCommunity"
import SideBarItem from "./SideBarItem"

const SideBar = () => {
  const navigate = useNavigate()
  const usersContext = useContext(UsersContext)
  const [myCommunities, setMyCommunities] = useState([])
  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext)
  const userID = R.pathOr("NA", ["userDBObject", "id"], usersContext)

  const handlehome = () => {
    navigate("/")
  }

  const getSideBarCommunities = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/sidebarcommunities/${userID}`
      )
      const mycommunities = R.pathOr([], ["data"], res.data)

      const mycommunitiesmapped = mycommunities.map((communitytableentry) => {
        return communitytableentry.community
      })
      setMyCommunities(mycommunitiesmapped)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (usersContext.userIsSignedInState) {
      getSideBarCommunities()
    }
  }, [usersContext.userIsSignedInState])

  return (
    <div className="fixed bg-dashboardboxbackground flex flex-col align-center rounded-r-lg h-screen pt-4 min-h-40 w-16 min-w-16 shadow-md shadow-dashboardbackground mt-2">
      <SideBarItem
        onClick={handlehome}
        icon={<HomeIcon className="w-5 h-5 m-3" />}
      />
      <SideBarItem icon={<GlobeIcon className="w-5 h-5 m-3" />} />
      <SideBarItem icon={<MessageIcon className="w-5 h-5 m-3" />} />

      <div className="mt-3 mb-4 w-8 mx-auto  border-dashboardalttext border text-dashboardhighlight flex"></div>
      {myCommunities.map((community) => {
        return <SideBarCommunity community={community} key={community.id} />
      })}
    </div>
  )
}

export default SideBar
