import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
// import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { ReactComponent as DropDownIcon } from "../../../assets/svg/dropdown.svg"

const TobBarDropdown = ({ dropElement, data = [], children }) => {
  return (
    <Menu as="div">
      <Menu.Button className="w-full">
        {dropElement ? (
          dropElement && typeof dropElement === "function" ? (
            dropElement()
          ) : (
            dropElement
          )
        ) : (
          <button>
            <DropDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </button>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className=" panel fixed top-[50px] right-8">
          {data.map(({ value }) => {
            return (
              <Menu.Item>
                {({ active }) => (
                  <a
                    className="cursor-pointer justify-center items-center"
                    href="dropdown-item"
                  >
                    {value}
                  </a>
                )}
              </Menu.Item>
            )
          })}

          {!data.length && children && <>{children}</>}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default TobBarDropdown
