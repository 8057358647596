import styled from "styled-components";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export const Container = styled.div`
  position: absolute;
  top: 80px;
  right: 100px;
  z-index: 100;
  width: 360px;
  height: 631px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 20px;
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
`;

export const MoreIcon = styled(MoreHorizIcon)`
  && {
    cursor: pointer;
  }
`;

export const NotificationsList = styled.div`
  height: 486px;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d1d1d1;
  margin-bottom: 20px;
  margin-top: 16px;
`;

export const PastNotifButton = styled.button`
  margin-top: 16px;
  width: 320px;
  height: 47px;
  padding: 13px 20px;
  background: #ffffff;
  border: 1.5px solid #d1d1d1;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #101010;
`;
