import styled from "styled-components";
import { Button } from "@mui/material";

const StandardButton = styled(Button)`
  && {
    border: 1.5px solid #d1d1d1;
    border-radius: 16px;
    color: #101010;
    font-style: normal;
    font-size: 20px;
    text-transform: none;
    padding: 0;
    margin: 0;
    padding: 13px 20px;
    background-color: #ffffff;
    border-color: #f0f0f0;
  }
`;

export default StandardButton;
