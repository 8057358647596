import { useState } from "react"
// import { ReactComponent as TopLeftAngle } from "../../../assets/svg/top-left-angle.svg"
// import { ReactComponent as TopRightAngle } from "../../../assets/svg/top-right-angle.svg"
// import { ReactComponent as BottomLeftAngle } from "../../../assets/svg/bottom-left-angle.svg"
// import { ReactComponent as BottomRightAngle } from "../../../assets/svg/bottom-right-angle.svg"
import TopLeft from "../../../assets/png/top-left-angle.png"
import TopRight from "../../../assets/png/top-right-angle.png"
import BottomLeft from "../../../assets/png/bottom-left-angle.png"
import BottomRight from "../../../assets/png/bottom-right-angle.png"

const MetaractCard = ({ onClick, children, styles = { width: 320 } }) => {
  const [effect, setEffect] = useState(false)

  return (
    <div
      onMouseOut={() => {
        setEffect(false)
      }}
      onMouseOver={() => {
        setEffect(true)
      }}
      onClick={onClick}
      className="relative hover:scale-105 hover:cursor-pointer transform transition duration-500"
    >
      <div className="card" style={styles}>
        {children}
      </div>
      {effect && (
        <div className="absolute inset-0">
          <>
            <img
              className="block absolute top-0 -mt-6 -ml-6"
              src={TopLeft}
              alt="top-left-glow"
            />
            <img
              className="block absolute top-auto right-0 -mt-6 -mr-6"
              src={TopRight}
              alt="top-right-glow"
            />
            <img
              className="block absolute bottom-0 -mb-6 -ml-6"
              src={BottomLeft}
              alt="bottom-left-glow"
            />
            <img
              className="block absolute bottom-0 right-0 -mb-6 -mr-6"
              src={BottomRight}
              alt="bottom-right-glow"
            />
            {/*
            <TopLeftAngle className="block absolute top-0 -mt-6 -ml-6" />
            <TopRightAngle className="block absolute top-auto right-0 -mt-6 -mr-6" />
            <BottomLeftAngle className="block absolute bottom-0 -mb-6 -ml-6" />
            <BottomRightAngle className="block absolute bottom-0 right-0 -mb-6 -mr-6" /> */}
          </>
        </div>
      )}
    </div>
  )
}

export default MetaractCard
