import React from "react"

import ResetPasswordForm from "../../components/Authentication/ResetPasswordForm"

import { AuthContainer } from "../../components/Authentication/AuthContainer"
import { AuthHeader } from "../../components/Authentication/AuthHeader"

const ResetPassword = () => {
  return (
    <AuthContainer header={<AuthHeader text="Reset Password" />}>
      <ResetPasswordForm />
    </AuthContainer>
  )
}

export default ResetPassword
