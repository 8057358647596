import React from "react";
import { Flex } from "rebass";

import Card from "../../Common/Card";
import Input from "../../Common/Input";
import SubmitButton from "../../Authentication/SubmitButton";
import ProfilePicture from "../../Authentication/ProfilePicture";
import { Label } from "../../Common/Input/styles";

import * as Styled from "./styles";

const PersonalInformation = () => {
  return (
    <div>
      <Flex mb="16px">
        <Card>
          <Flex mb="32px">
            <Input label="Full name" />
          </Flex>
          <Flex mb="32px">
            <Input label="Email" />
          </Flex>
          <SubmitButton text="Update" />
        </Card>
      </Flex>
      <Flex mb="16px">
        <Card>
          <Flex mb="8px">
            <Input label="Username" />
          </Flex>
          <Styled.HelperText>
            You can only set your username once.
          </Styled.HelperText>
        </Card>
      </Flex>
      <Card>
        <Label>Profile image</Label>
        <ProfilePicture />
      </Card>
    </div>
  );
};

export default PersonalInformation;
