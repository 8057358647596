import React, { useContext } from "react"

import { VersusContext } from "../../../../pages/Versus/context"
import Setup from "./screens/Setup"
import ChooseCreator from "./screens/ChooseCreator"
import PowerUps from "./screens/PowerUps"
import AcceptBattle from "./screens/AcceptBattle"
import Loading from "./screens/Loading"

const NewDrawer = () => {
  const versusContext = useContext(VersusContext)

  const renderContent = () => {
    console.log(
      "currentVersusScreenState =>",
      versusContext.currentNewVersusScreensState
    )
    if (
      versusContext.currentNewVersusScreensState ===
      versusContext.newVersusScreens.SETUP
    ) {
      return <Setup />
    }
    if (
      versusContext.currentNewVersusScreensState ===
      versusContext.newVersusScreens.CHOOSE_CREATOR
    ) {
      return <ChooseCreator />
    }
    if (
      versusContext.currentNewVersusScreensState ===
      versusContext.newVersusScreens.POWER_UPS
    ) {
      return <PowerUps />
    }
    if (
      versusContext.currentNewVersusScreensState ===
      versusContext.newVersusScreens.ACCEPT_BATTLE
    ) {
      return <AcceptBattle />
    }
    if (
      versusContext.currentNewVersusScreensState ===
      versusContext.newVersusScreens.LOADING
    ) {
      return <Loading />
    }
  }

  return (
    <div className="w-[560px] h-screen max-h-screen">{renderContent()}</div>
  )
}

export default NewDrawer
