import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f5f5;
  min-height: 90vh;
  display: flex;
  padding-top: 88px;
  padding-left: 207px;
  padding-bottom: 81px;
`;
