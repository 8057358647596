import styled from "styled-components";
import { Avatar } from "@mui/material";

export const Container = styled.div`
  width: 1224px;
  height: 275px;
  background: linear-gradient(180deg, #6e27c8 0%, #6c93f9 100%);
  border-radius: 16px;
  position: relative;
`;

export const ChangeImageButton = styled.button`
  border: 1px solid #d1d1d1;
  filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.08));
  border-radius: 12px;
  width: 162px;
  height: 62px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
`;

export const CustomAvatar = styled(Avatar)`
  && {
    width: 180px;
    height: 180px;
    border: 8px solid #ffffff;
    position: absolute;
    left: 80px;
    bottom: -40%;
  }
`;
