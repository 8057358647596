import styled from "styled-components";
import PeopleIcon from "@mui/icons-material/People";

export const CreatorAvatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background-color: green;
  margin-right: 16px;
`;

export const CreatorTitle = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
`;

export const CreatorSupporterText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #101010;
`;

export const GroupIcon = styled(PeopleIcon)`
  && {
    color: #DADADA;
    font-size: 1.2rem;
    margin-right: 7px;
    margin-bottom: 3px;
  }
`;

export const VersusText = styled.p`
  margin: 0;
  padding: 0;
  //   IMPORT FONT FOR THIS!
  font-weight: 400;
  font-size: 55px;
  color: ${({ color }) => color};
  margin: -5px 0;
`;

export const CreatorDonationAmount = styled.div`
  width: 100px;
  height: 20px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
  text-align: ${({ textAlign = "left" }) => textAlign};
`;

export const DollarsInPotText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  color: #101010;
  margin-right: 8px;
`;

export const PotText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #101010;
`;
