import { AuthContainer } from "../../components/Authentication/AuthContainer"
import { AuthHeader } from "../../components/Authentication/AuthHeader"

import SelectFanOrCreator from "../../components/Authentication/SelectFanOrCreator"

const FanOrCreator = () => {
  return (
    <AuthContainer header={<AuthHeader text="Hi there!" />}>
      <SelectFanOrCreator />
    </AuthContainer>
  )
}

export default FanOrCreator
