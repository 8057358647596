import React from "react"
import { useNavigate } from "react-router-dom"

const SignInButton = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/signin")
  }

  return (
    <div className="flex justify-center py-1">
      <button
        onClick={handleClick}
        className="bg-transparent hover:bg-dashboardhighlight text-dashboardtext hover:text-black font-ocr pt-2 pb-1 px-4 text-sm border-dashboardhighlight border-2 rounded-full"
      >
        SIGN IN
      </button>
    </div>
  )
}

export default SignInButton
