import React, { useContext } from "react";
import { Flex } from "rebass";

import ProfilePicture from "../../../Authentication/ProfilePicture";
import SubmitButton from "../../../Authentication/SubmitButton";
import { CommunitySetupContext } from "../../../../context/communitySetup";
import COMMUNITY_SETUP_SCREENS from "../../../../config/screens/CommunitySetup";

import * as Styled from "./styles";

const BrandingScreen = () => {
  const communitySetupContext = useContext(CommunitySetupContext);

  const handleSave = () => {
    communitySetupContext.setCurrentScreenState(
      COMMUNITY_SETUP_SCREENS.YOUR_FIRST_METARACT.key
    );
  };

  return (
    <div>
      <Flex flexDirection="column" mb="32px">
        <Styled.TitleText>Community Logo</Styled.TitleText>
        <ProfilePicture
          customStyles
          size="small"
          title="Drag and drop your community logo here"
          description="Use a PNG or JPG file, recommended dimensions are 400px by 400px, with a size limit of 4MB."
        />
        <Styled.HelperText>
          This image acts as the logo for your community across the platform.
        </Styled.HelperText>
      </Flex>
      <Flex flexDirection="column" mb="32px">
        <Styled.TitleText>Community Banner</Styled.TitleText>
        <ProfilePicture
          customStyles
          size="large"
          title="Drag and drop your community banner here"
          description="Use a PNG or JPG file, recommended dimensions are 2880px by 720px, with a size limit of 10MB."
        />
        <Styled.HelperText>
          This banner image appears on your community page.
        </Styled.HelperText>
      </Flex>
      <SubmitButton onClick={handleSave} text="Save & Continue" />
    </div>
  );
};

export default BrandingScreen;
