import { TbHexagon3D } from "react-icons/tb"

export const DrawerInput = ({
  title,
  placeholder,
  onChange,
  leftLabel,
  type,
  titleStyle = "",
}) => (
  <div className="DrawerInput flex flex-col w-full items-start justify-center ">
    {title && (
      <div className="flex justify-between items-center w-full">
        <h3
          className={`font-proxima text-base font-semibold sm:text-lg text-dashboardtext ${titleStyle}`}
        >
          {title}
        </h3>
        {leftLabel && (
          <h5 className="font-proxima text-base text-dashboardalttext">
            {leftLabel}
          </h5>
        )}
      </div>
    )}
    <input
      type={type || "text"}
      name={title && title?.toLowerCase()}
      placeholder={placeholder}
      onChange={onChange}
      className="mt-2 border border-dashboardframes outline-none text-dashboardtext bg-transparent font-proxima rounded-lg py-2 w-full px-2 items-center justify-center flex autofill:!bg-gray-600 autofill:shadow-[inset_0_0_0px_1000px_rgb(53,61,66)] autofill:text-white"
    ></input>
  </div>
)
