import styled from "styled-components";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export const Container = styled.div`
  background-color: #e4e8eb;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 14px 24px;
  width: 604px;
  height: 57px;
  margin-bottom: 32px;
  justify-content: space-between;
`;

export const ActiveStepText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #101010;
`;

export const InactiveStepText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #536471;
`;

export const ArrowIcon = styled(ArrowRightIcon)`
  && {
    margin: 0 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #101010;
  }
`;
