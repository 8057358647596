import React from "react"
import { useNavigate } from "react-router-dom"

const SideBarCommunity = ({ community }) => {
  const profilepicurl = community.creator.profilepictureurl
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/community/${community.id}`)
  }
  return (
    <div
      style={{ backgroundImage: `url(${profilepicurl})` }}
      className="w-9 h-9 mx-auto bg-cover bg-no-repeat bg-center my-3 bg-dashboardhighlight rounded-full hover:rounded-md transition-all duration-100 hover:cursor-pointer"
      onClick={handleClick}
    ></div>
  )
}

export default SideBarCommunity
