import { MdCheck } from "react-icons/md"

export const CreatorRow = ({ community, onClick, isInvited = false }) => {
  return (
    <div key={community.id} className="flex justify-between mt-8 mb-8">
      <div className="flex items-center">
        <div
          style={{
            backgroundImage: `url(${community?.creator?.profilepictureurl})`,
          }}
          className="w-10 h-10 rounded-xl bg-cover bg-center bg-no-repeat "
        ></div>
        <div className="flex flex-col ml-6">
          <p className="font-proxima text-md text-dashboardtext">
            {community.name}
          </p>
          <div className="flex items-center ">
            <div className="w-1 h-1 rounded-full bg-red-300"></div>
            <p className="text-dashboardalttext ml-2 text-sm font-proxima">
              {community.islive && "LIVE"}
            </p>
          </div>
        </div>
      </div>
      {isInvited ? (
        <div className="w-24 py-3 self-center border bg-dashboardbackground text-dashboardtext font-proxima text-md border-dashboardhighlight rounded-xl justify-center items-center">
          <MdCheck className="mx-auto" />
        </div>
      ) : (
        <button
          onClick={onClick}
          className="w-24 border bg-dashboardbackground text-dashboardtext font-proxima text-md border-dashboardhighlight rounded-xl justify-center items-center"
        >
          Invite
        </button>
      )}
    </div>
  )
}
