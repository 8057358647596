import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const useContactUs = () => {
  const [isLoadingState, setIsLoading] = useState(false);

  const submitForm = async (formState) => {
    try {
      setIsLoading(true);
      await axios.post("http://localhost:8080/slackbot/contact", formState);
      console.log("success");
    } catch (error) {
      toast.error("Oops! Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    submitForm,
    isLoadingState,
  };
};

export default useContactUs;
