import React, { useContext } from "react"
import { DareContext } from "../../../../pages/Dare/context"
import SCREENS from "../../../../pages/Dare/config/SCREENS"
// import darecreatorcontainer from "./styles/darecontainersvg/containerborder.svg"
import { ReactComponent as PlusIcon } from "../../../../assets/svg/plus-icon.svg"
import { ReactComponent as EditIcon } from "../../../../assets/svg/edit.svg"
import { useScreen } from "../../../../hooks/useScreen"

const DareCard = ({
  title,
  description,
  price,
  id,
  type,
  users,
  index,
  noBorder,
  withButton,
  toPick,
  onPick,
  picked,
}) => {
  const dareContext = useContext(DareContext)
  const { isMobile } = useScreen()
  const handleClick = () => {
    if (type === "CREATOR") {
      dareContext.setCurrentScreen(SCREENS.SCREENS.CREATOR_DARE_DETAILS)
    } else {
      dareContext.setCurrentScreen(SCREENS.SCREENS.FAN_DARE_DETAILS)
    }

    const selectedDare = {
      title: title,
      description: description,
      price: price,
      id: id,
      users: users,
    }
    dareContext.setDareDetailsDare(selectedDare)
  }
  const value = Math.round(price).toLocaleString("en-US")
  return (
    <div
      className={`flex h-[70px] hover:cursor-pointer justify-between items-center px-5 py-3 bg-center text-dashboardtext rounded-md ${
        !noBorder && !picked && "border border-dashboardtext"
      } ${picked && "border-2 border-dashboardhighlight"}`}
    >
      <div className="flex gap-x-3 font-ocr text-xl mr-2">
        {index && <p className="self-center">#{index}</p>}
        <div
          className={`font-proxima ${
            withButton ? "w-40 sm:w-52" : "w-48 sm:w-60"
          }`}
        >
          <h1 className="text-base sm:text-lg truncate">{title}</h1>
          <p className="text-xs text-dashboardalttext truncate">
            {description}
          </p>
        </div>
      </div>
      <div className="flex gap-x-3">
        <p className="font-hk w-18 leading-4">
          {`${!isMobile ? "USD" : ""} $${!isNaN(value) ? value : ""}`}
          <br />
          <span className="text-xs text-dashboardalttext">In pot</span>
        </p>
        {withButton && (
          <button
            onClick={handleClick}
            className={`z-[100] font-bold font-ocr text-xl h-fit self-center bg-black text-dashboardhighlight hover:bg-dashboardhighlight hover:text-black ${
              type !== "CREATOR" ? "p-2 rounded-sm" : "p-0 rounded-[10px]"
            }`}
          >
            {type !== "CREATOR" ? (
              <PlusIcon />
            ) : (
              <EditIcon className="w-8 h-8 text-dashboardtext hover:text-black" />
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default DareCard
