import React, { useState, useContext } from "react"
import axios from "axios"
import io from "socket.io-client"
import * as R from "ramda"

import { MetaractShelfContext } from "../../../pages/MetaractShelf/context"
import {
  DrawerButton,
  DrawerDareBox,
  DrawerDareMainInfo,
  DrawerDareDetailsInfo,
  DrawerContainer,
} from "../../Metaracts/Common"

const MetaractShelfDareItemDetails = () => {
  const metaractShelfContext = useContext(MetaractShelfContext)
  const socket = io.connect(`${process.env.REACT_APP_SERVER_API}`)
  const { title, description, price, id, users } = metaractShelfContext.dareitem
  const [dareBy, setDareBy] = useState("")
  const getDareBy = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuser/${users[0]}`
      )

      const dareBy = R.pathOr("", ["data", "data", "name"], response)

      setDareBy(dareBy)
    } catch (error) {
      console.log(error)
    }
  }

  getDareBy()

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_API}dareitem/updatedareitem/${id}`,
        { status: "REMOVED" }
      )
      console.log("dareitem removed", response.data)
      if (socket) {
        socket.emit(
          "SEND_DARE_HAS_CHANGED",
          `dare_${metaractShelfContext.metaractid}`
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      metaractShelfContext.setDrawerIsOpen(false)
    }
  }

  return (
    <DrawerContainer
      type="dare"
      title="DARE DETAILS"
      onClose={() => {
        metaractShelfContext.setDrawerIsOpen(false)
      }}
      footer={<DrawerButton text="COMPLETE DARE" onClick={handleSubmit} />}
    >
      <DrawerDareBox boxTitle="dare details">
        <DrawerDareMainInfo title={title} description={description} />
        <DrawerDareDetailsInfo
          dareBy={dareBy}
          price={price}
          usersLength={users?.length}
        />
      </DrawerDareBox>
    </DrawerContainer>
  )
}

export default MetaractShelfDareItemDetails
