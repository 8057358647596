import React, { useContext } from "react"
import axios from "axios"

import { CommunityContext } from "../../../../context/community"
import { DareContext } from "../../../../pages/Dare/context"

import DareCard from "../DareCard"
import SCREENS from "../../../../pages/Dare/config/SCREENS"
import { SocketContext } from "../../../../context/socket"
import { DrawerButton, DrawerContainer } from "../../Common"

const CreatorDareInProgress = () => {
  const communityContext = useContext(CommunityContext)
  const dareContext = useContext(DareContext)
  const socket = useContext(SocketContext)

  const handleSubmit = async () => {
    try {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/completedare/${dareContext.dareDetailsDare.id}`,
        { status: "COMPLETED" }
      )
      const resget = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${dareContext.metaractid}`
      )
      const { islive, timeend, minprice } = resget.data.data?.description

      const description = {
        islive: islive,
        timeend: timeend,
        minprice: minprice,
      }

      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${dareContext.metaractid}`,
        { description: description }
      )
      console.log("dareitem completed", res.data)
      if (socket) {
        socket.emit("SEND_DARE_HAS_CHANGED", `dare_${metaract.id}`)
      }
    } catch (error) {
      console.log(error)
    }
    dareContext.setCurrentScreen(SCREENS.SCREENS.CREATOR_LIVE_DARE)
    dareContext.setClashInProgress(false)
  }

  return (
    <DrawerContainer
      type="dare"
      back
      title="COMPLETE DARES COLLECT THE POT"
      onClose={() => {
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
      }}
      footer={<DrawerButton text="COMPLETE DARE" onClick={handleSubmit} />}
    >
      <div className="mt-8">
        <DareCard
          description={dareContext.dareDetailsDare.description}
          title={dareContext.dareDetailsDare.title}
          price={dareContext.dareDetailsDare.price}
          key={1}
        ></DareCard>
      </div>
    </DrawerContainer>
  )
}

export default CreatorDareInProgress
