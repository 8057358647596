import styled from "styled-components";


export const Card = styled.div`
    width: 23vw;
    height: 48vh;
    background: #141d2c;
    box-shadow: 2px 2px 4px rgba(73, 244, 255, 0.3);
    outline: 1px solid rgba(73, 244, 255, 0.80);
    `


