const COMMUNITY_SETUP_SCREENS = {
  INFO: {
    key: "INFO",
    title: "Info",
  },
  BRANDING: {
    key: "BRANDING",
    title: "Branding",
  },
  YOUR_FIRST_METARACT: {
    key: "YOUR_FIRST_METARACT",
    title: "Your first metaract",
  },
};

export default COMMUNITY_SETUP_SCREENS;
