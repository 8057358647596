import React, { useState } from "react";

import SCREENS from "./config/screens";
import * as Styled from "./styles";

const Tabs = () => {
  const [currentScreenState, setCurrentScreenState] = useState(
    SCREENS.METARACT_HISTORY
  );

  return (
    <Styled.Container>
      <Styled.Line>
        <Styled.Tab
          active={SCREENS.METARACT_HISTORY === currentScreenState}
          onClick={() => setCurrentScreenState(SCREENS.METARACT_HISTORY)}
        >
          Metaract History
        </Styled.Tab>
        <Styled.Tab
          active={SCREENS.JOINED_COMMUNITIES === currentScreenState}
          onClick={() => setCurrentScreenState(SCREENS.JOINED_COMMUNITIES)}
        >
          Joined Communities
        </Styled.Tab>
      </Styled.Line>
    </Styled.Container>
  );
};

export default Tabs;
