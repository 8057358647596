import styled from "styled-components";

const Title = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #E6E6EB;
`;

export default Title;

