import React, { useContext } from "react"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { GunRunnerContext } from "../../../../../../../pages/GunRunner/context"
import GunRunnerCreatorCommunity from "../../../GunRunnerCreatorCommunity"

const GunRunnerCreatorDrawer = () => {
  const gunRunnerContext = useContext(GunRunnerContext)

  if (gunRunnerContext.currentScreen === SCREENS.DRAWERS.INIT) {
    return <div><p>hihi</p></div>
  } else if (gunRunnerContext.currentScreen === SCREENS.DRAWERS.CREATOR_COMMUNITY) {
    return <GunRunnerCreatorCommunity/>
  }

  return <div><p>somethings wrong</p></div>
}

export default GunRunnerCreatorDrawer
