import React, { useContext, useRef } from "react"
import { Drawer } from "@mui/material"
import { DareContext } from "../../../../../pages/Dare/context"
import { CommunityContext } from "../../../../../context/community"
import SCREENS from "../../../../../pages/Dare/config/SCREENS"
import FanDrawer from "./screens/FanDrawer"
import CreatorDrawer from "./screens/CreatorDrawer"
import StreamWindow from "../../../../StreamWindow"
import { MetaractShelfContext } from "../../../../../pages/MetaractShelf/context"
import { useScreen } from "../../../../../hooks/useScreen"

const DareDrawer = ({ shelf }) => {
  const embed = useRef() // We use a ref instead of state to avoid rerenders.
  const handleReady = (e) => {
    console.log("fired handleReady")
    embed.current = e
  }

  const dareContext = useContext(DareContext)
  const communityContext = useContext(CommunityContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const { isMobile } = useScreen()

  const renderContent = () => {
    if (dareContext.currentDrawerScreen === SCREENS.DRAWERS.CREATOR_COMMUNITY) {
      return <CreatorDrawer />
    }
    if (dareContext.currentDrawerScreen === SCREENS.DRAWERS.FAN_COMMUNITY) {
      return <FanDrawer />
    }
  }
  return (
    <Drawer
      anchor="right"
      open={dareContext.currentDrawerOpen}
      onClose={() => {
        dareContext.setCurrentDrawerOpen(false)
        dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.INIT)
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
        communityContext.setDrawerOpen(false)
        metaractShelfContext.setDrawerIsOpen(false)
      }}
      PaperProps={{
        sx: { "&::-webkit-scrollbar": { display: "none" } }, // this removes the scrollbar in drawer
        elevation: 0,
        style: { backgroundColor: "transparent" },
      }}
    >
      <div className="DareDrawer flex w-screen min-h-[800px] h-screen">
        {!isMobile && (
          <div className="streamWindow">
            {!shelf && (
              <StreamWindow
                src={communityContext.src}
                handleReady={handleReady}
                width="100%"
                height="100%"
              />
            )}
          </div>
        )}

        <div
          data-testid={dareContext.currentDrawerScreen}
          className="DareDrawerScreenContent flex-col fixed left-0 sm:left-auto right-0 flex w-screen sm:w-[var(--drawer-width)] min-screen sm:min-w-[var(--drawer-width)] rounded-lg bg-daresidepanelbg h-screen"
        >
          {renderContent()}
        </div>
      </div>
    </Drawer>
  )
}
export default DareDrawer
