import React, { useContext } from "react"
import { Drawer } from "@mui/material"
import { MetaractShelfContext } from "../../pages/MetaractShelf/context"

import MetaractShelfDareItemDetails from "../MetaractShelfGrid/MetaractShelfDareDrawer"
import MetaractShelfLaunchDare from "./screens/MetaractShelfLaunchDare"
import SCREENS from "../../pages/MetaractShelf/config/SCREENS"
import MetaractShelfLaunchGunRunner from "./screens/MetaractShelfLaunchGunRunner"
import Dare from "../../pages/Dare"
import Versus from "../../pages/Versus"

import NewDrawer from "../Metaracts/Versus/NewDrawer"
import GunRunner from "../../pages/GunRunner"

const MetaractShelfDrawer = () => {
  const metaractShelfContext = useContext(MetaractShelfContext)

  const renderContent = () => {
    if (
      metaractShelfContext.currentDrawerScreen === SCREENS.DRAWERS.LAUNCHDARE
    ) {
      return <MetaractShelfLaunchDare />
    } else if (
      metaractShelfContext.currentDrawerScreen === SCREENS.DRAWERS.DAREDETAILS
    ) {
      return <MetaractShelfDareItemDetails />
    } else if (
      metaractShelfContext.currentDrawerScreen === SCREENS.DRAWERS.LAUNCHVERSUS
    ) {
      return <NewDrawer />
    } else if (
      metaractShelfContext.currentDrawerScreen ===
      SCREENS.DRAWERS.LAUNCHGUNRUNNER
    ) {
      return <MetaractShelfLaunchGunRunner />
    } else if (
      metaractShelfContext.currentDrawerScreen === SCREENS.DRAWERS.CREATORDARE
    ) {
      return <Dare shelf={true} />
    } else if (
      metaractShelfContext.currentDrawerScreen === SCREENS.DRAWERS.CREATORVERSUS
    ) {
      return <Versus shelf={true} />
    } else if (
      metaractShelfContext.currentDrawerScreen ===
      SCREENS.DRAWERS.CREATORGUNRUNNER
    ) {
      return <GunRunner shelf={true} />
    }
  }

  return (
    <Drawer
      anchor="right"
      open={metaractShelfContext.drawerIsOpen}
      onClose={() => metaractShelfContext.setDrawerIsOpen(false)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <div className="flex rounded-lg bg-daresidepanelbg w-[var(--drawer-width)] fixed right-0 ">
        {renderContent()}
      </div>
    </Drawer>
  )
}

export default MetaractShelfDrawer
