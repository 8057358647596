import React, { useContext, useEffect } from "react"

import { CommunityContext } from "../../context/community"
import { VersusContext } from "./context"
import VersusDrawer from "../../components/VersusBattle/VersusDrawer"
import { MetaractShelfContext } from "../MetaractShelf/context"
import { CardTitle } from "../../components/Common/atoms/CardTitle"
import MetaractCard from "../../components/Common/MetaractCard"
import { ImgAvatar } from "../../components/Common/atoms/ImgAvatar"

const Versus = ({ shelf }) => {
  const versusContext = useContext(VersusContext)
  const communityContext = useContext(CommunityContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  useEffect(() => {
    if (shelf && metaractShelfContext.drawerIsOpen) {
      versusContext.setCurrentDrawerOpen(true)
      communityContext.setDrawerOpen(true)
    }
  }, [])
  return (
    <>
      <MetaractCard
        onClick={() => {
          versusContext.setCurrentDrawerOpen(true)
          communityContext.setDrawerOpen(true)
        }}
      >
        <div className="flex flex-col gap-4">
          <CardTitle text="Versus" />
          <div className="flex flex-row w-full justify-between text-center">
            {/* TODO wins and losses data must come from versusContext ? */}
            <p className="font-ocr text-dashboardhighlight text-xs text-center">
              7 WINS
            </p>
            <p className="font-ocr text-dashboardhighlight text-xs text-center">
              3 LOSSES
            </p>
          </div>
          <div className="flex flex-row w-full justify-between text-center">
            <p className="font-ocr text-white text-base text-left">
              Last <br /> match
            </p>
            <div className="flex flex-row gap-3">
              <ImgAvatar
                alt="creator"
                src={versusContext?.creator?.creator?.profilepictureurl || null}
                square
              />
              <ImgAvatar
                alt="oponent"
                src={versusContext?.selectedCreator?.creator?.profilepictureurl}
                square
              />
            </div>
          </div>
        </div>
      </MetaractCard>
      <VersusDrawer className="z-30" />
    </>
  )
}

export default Versus
