import React from "react"
import { useNavigate } from "react-router-dom"

const Jumbotron = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/creator-signup")
  }

  return (
    <div className="flex w-11/12 ml-0 h-80 rounded-md bg-jumbotronimage bg-cover bg-center bg-no-repeat flex-col justify-start">
      <div className="flex flex-col items-start w-full h-full ">
        <h1 className="text-dashboardtext font-proxima text-4xl ml-12 mt-20 ">
          Buy and sell interactions
        </h1>
        <h1 className="text-dashboardtext font-proxima text-4xl ml-12 mt-2">
          from your favorite creators.
        </h1>
        <button
          className="text-dashboardtext font-ocr flex justify-between items-center py-3 px-7 bg-transparent border-dashboardhighlight border-2 rounded-full text-l ml-12 mt-8"
          onClick={handleClick}
        >
          <p>BECOME A CREATOR</p>
          <svg
            className="w-6 h-6 ml-2  stroke-dashboardtext stroke-1"
            viewBox="0 0 24 24"
          >
            <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Jumbotron
