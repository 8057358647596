import React from "react";
import { Flex } from "rebass";

import * as Styled from "./styles";

const Checkbox = ({ label, value, onChange }) => {
  return (
    <Flex alignItems="center">
      <Styled.Checkbox
        value={value}
        onChange={(event) => onChange(event.target.checked)}
        type="checkbox"
      />
      <Styled.Label>{label}</Styled.Label>
    </Flex>
  );
};

export default Checkbox;
