import React, { useState } from "react";

import * as Styled from "./styles";
// import DragAndDropGrid from "../../../Common/DragAndDropGrid";
import EmptyMetaractGrid from "../../EmptyMetaractGrid";

const MetaractSetupScreen = () => {
  return (
    <Styled.Container>
      <Styled.TitleText>
        Create your first metaract to start engaging with your fans!
      </Styled.TitleText>
      <Styled.ButtonContainer>
        <Styled.CreateMetaractButton>
          <Styled.PlusIcon />
          Create metaract
        </Styled.CreateMetaractButton>
        <Styled.DoItLaterButton>Do it later</Styled.DoItLaterButton>
      </Styled.ButtonContainer>
      {/* <DragAndDropGrid /> */}
      <EmptyMetaractGrid />
    </Styled.Container>
  );
};

// IMPLEMENTATION

// 1) I would have some sort of dropdown with options for all of the metaracts (the options can be hardcoded or pulled from a firebase table)
// 2) Then I would have those options rendered as divs, and store them all in some array or object
// 3) Then using the DragAndDropGrid, set the elements of the array/objects as elements on this grid so people can drag and drop to change the order
// 4) After that, store this array returned from the drag and drop grid in firebase, probably in the community collection (i would
// make a seprate field for this)
// 5) Return this array of metaracts when pulling a users community base, and resuse the grid component to display them

export default MetaractSetupScreen;
