import React from "react";

import * as Styled from "./styles";

const EmptyMetaractGrid = () => {
  return (
    <Styled.Container>
      <Styled.SingleGridItem />
      <Styled.SingleGridItem />
      <Styled.SingleGridItem />
      <Styled.SingleGridItem />
      <Styled.SingleGridItem />
      <Styled.SingleGridItem />
      <Styled.SingleGridItem />
      <Styled.SingleGridItem />
    </Styled.Container>
  );
};

export default EmptyMetaractGrid;
