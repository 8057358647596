import styled from "styled-components"
import PeopleIcon from "@mui/icons-material/People"
import AddIcon from "@mui/icons-material/Add"
import StandardButton from "../../../Common/StandardButton"

export const ImageContainer = styled.div`
  width: 477px;
  height: 240px;
  background-image: url(https://picsum.photos/477/240);
  margin-bottom: 12px;
  border: 2px solid #e6e6e6;
  border-radius: 12px;
`

export const StreamerImageContainer = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 16px;
  background-image: url(https://picsum.photos/80);
  margin-right: 16px;
`

export const IsLiveText = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px 4px;
  background-color: #ff1e00;
  font-size: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-style: normal;
`

export const CustomPeopleIcon = styled(PeopleIcon)`
  && {
    color: #000000;
    margin-right: 7px;
  }
`

export const ByText = styled.p`
  margin: 0;
  padding: 0;
  color: #878787;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-right: 2px;
`

export const JoinButton = styled(StandardButton)`
  && {
    background-color: ${({ joined }) => (joined ? "#F0F0F0" : "#ffffff")};
    border-color: ${({ joined }) => (joined ? "#F0F0F0" : "#d1d1d1")};
  }
`

export const JoinButtonIcon = styled(AddIcon)`
  && {
    font-size: 20px;
  }
`
