import React from "react";

import * as Styled from "./styles";
import { Flex } from "rebass";

const TextArea = ({
  label,
  placeholder,
  value,
  onChange,
  type,
  characterCounter,
  maxLength,
  width,
  height,
}) => {
  return (
    <div>
      {label && (
        <Styled.LabelContainer>
          <Styled.Label>{label}</Styled.Label>
          {characterCounter && (
            <Flex alignItems="center">
              <Styled.CharacterCounterText>
                {value.length}
              </Styled.CharacterCounterText>
              <Styled.CharacterCounterText>/</Styled.CharacterCounterText>
              <Styled.CharacterCounterText>
                {maxLength}
              </Styled.CharacterCounterText>
            </Flex>
          )}
        </Styled.LabelContainer>
      )}
      <Styled.TextInput
        width={width}
        height={height}
        maxLength={maxLength}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        type={type || "text"}
      />
    </div>
  );
};

export default TextArea;
