import { useContext } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { FiArrowLeft } from "react-icons/fi"
import { CommunityContext } from "../../../context/community"
import { useScreen } from "../../../hooks/useScreen"
import { ImgAvatar } from "../../Common/atoms/ImgAvatar"

const titles = {
  dare: "Dare Clash",
  versus: "Versus",
}

const TypeTag = ({ text }) => (
  <div className="bg-dashboardhighlight text-daresidepanelbg rounded-sm drop-shadow items-center py-1 px-4 flex justify-center h-8">
    <p className="font-ocr text-sm">{text}</p>
  </div>
)
export const DrawerHeader = ({ onClick, type = "dare", back }) => {
  const communityContext = useContext(CommunityContext)
  const { isMobile } = useScreen()
  return (
    <div className="flex justify-between mb-3 sm:mb-0 mt-6 md:mt-8 px-6 sm:px-10 text-dashboardtext items-center">
      {!isMobile && <TypeTag text={titles[type]?.toUpperCase()} />}
      {onClick && (
        <div
          onClick={onClick}
          className="w-8 h-8  hover:text-dashboardhighlight hover:cursor-pointer"
        >
          {!isMobile &&
            (back ? <FiArrowLeft className="text-[32px]" /> : <XMarkIcon />)}
          {isMobile && back ? (
            <FiArrowLeft className="text-[32px]" />
          ) : (
            <div></div>
          )}
        </div>
      )}
      {isMobile && (
        <p className="text-sm w-full text-center">
          <span
            className={`text-dashboardhighlight font-semibold ${
              !onClick && "text-base"
            }`}
          >
            {titles[type]}
          </span>
          <>
            {!onClick ? <br /> : " "}
            hosted by{" "}
            <span className="font-semibold">
              @{communityContext?.creator?.name}
            </span>
            <ImgAvatar
              alt="creator profile"
              src={communityContext.creator.profilepictureurl}
              styles="inline ml-2 !w-5 !h-5"
            />
          </>
        </p>
      )}
      {isMobile && onClick && <div className="w-8 h-8" />}
    </div>
  )
}
