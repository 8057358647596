import React, { useState, useContext, useEffect, Fragment } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import * as R from "ramda"

import { UsersContext } from "../../context/users"
import { CommunityContext } from "../../context/community"

import SideBar from "../../components/Navigation/SideBar"

import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
} from '@heroicons/react/20/solid'
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import {
  BanknotesIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid'

const statusStyles = {
  COMPLETE: 'bg-green-100 text-green-800',
  COMPLETED: 'bg-green-100 text-green-800',
  PENDING: 'bg-yellow-100 text-yellow-800',
  REFUNDED: 'bg-gray-100 text-gray-800',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MetaractShelfAnalytics = () => {
  const usersContext = useContext(UsersContext)
  const communityContext = useContext(CommunityContext)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const { id } = useParams()

  const [isValidCommunity, setIsValidCommunity] = useState(false)
  const [communityMetaracts, setCommunityMetaracts] = useState([])
  const [earnings, setEarnings] = useState(0)
  const [transactionlist, setTransactionList] = useState([])
  const [transactions, setTransactions] = useState(0)
  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext)
  const getCommunity = async () => {
    try {
      communityContext.setIsLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${id}`
      )
      console.log(
        "Queried => getCommunity fired on Community Page",
        "=>",
        response.data.data
      )
      const community = response.data.data

      if (community) {
        communityContext.setName(community.name)
        communityContext.setHandle(community.handle)
        communityContext.setIsLive(community.islive)
        communityContext.setSupporters(community.supporters)
        communityContext.setCreatorID(community.creatorid)
        communityContext.setMetaracts(
          community.metaracts.map((metaract) => metaract.metaract)
        )
        communityContext.setDescription(community.description)
        communityContext.setSrc(community.streamsrc)
        communityContext.setCreator(community.creator)
        communityContext.setID(community.id)
        setEarnings(
          community.transactions
            .filter((transaction) => transaction.status === "COMPLETED")
            .reduce((s, transaction) => transaction.amount + s, 0)
        )
        setTransactions(
          community.transactions.reduce((s, transaction) => s + 1, 0)
        )
        setTransactionList(community.transactions)
        console.log(community.transactions)
        setIsValidCommunity(true)
      } else {
        setIsValidCommunity(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      communityContext.setIsLoading(false)
    }
  }

  const getMetaracts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaracts?communityid=${id}&take=10&skip=0&orderBy="asc"`
      )
      console.log(
        "Queried => getMetaracts fired on Metaract Shelf Page",
        "=>",
        response.data.data
      )
      setCommunityMetaracts(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCommunity()
  }, [])

  useEffect(() => {
    if (!communityContext.isLoading) {
      getMetaracts()
    }
  }, [communityContext.isLoading])

  if (userType === "FAN" || !isValidCommunity) {
    return (
      <div>
        <h1>Error, you are a fan.</h1>
      </div>
    )
  }

  const stats = [
    { name: 'Fans', value:`${communityContext.supporters}`    , change: '+4.75%', changeType: 'positive' },
    { name: 'Transactions', value: `${transactions}`, change: '+54.02%', changeType: 'negative' },
    { name: 'Earnings', value: `$ ${earnings}`, change: '-1.39%', changeType: 'positive' },
    { name: 'Metaracts', value: `${communityMetaracts.length}`, change: '+10.18%', changeType: 'negative' },
  ]

  
  return (
    <div className="flex w-screen h-full bg-dashboardbackground">
      <div className="flex w-1/12">
        <SideBar />
      </div>
      <div className="flex w-11/12 bg-dashboardbackground">
        <div className="flex flex-col w-full">
          <h1 className="text-4xl font-bold font-proxima text-dashboardtext pt-20">
            Analytics Dashboard
          </h1>
          <div className="my-10 border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {stats.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                    'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-dashboardtext px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                  )}
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                  <dd
                    className={classNames(
                      stat.changeType === 'negative' ? 'text-dashboardhighlight' : 'text-rose-600',
                      'text-xs font-medium'
                    )}
                  >
                    {stat.change}
                  </dd>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-dashboardtext">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* Activity list (smallest breakpoint only) */}
          <div className="shadow sm:hidden">
                <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                  {transactionlist.map((transaction) => (
                    <li key={transaction.id}>
                      <a href={transaction.href} className="block bg-white px-4 py-4 hover:bg-gray-50">
                        <span className="flex items-center space-x-4">
                          <span className="flex flex-1 space-x-2 truncate">
                            <BanknotesIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <span className="flex flex-col truncate text-sm text-gray-500">
                              <span className="truncate">{transaction.name}</span>
                              <span>
                                <span className="font-medium text-gray-900">{transaction.amount}</span>{' '}
                                {transaction.currency}
                              </span>
                            </span>
                          </span>
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>

                <nav
                  className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
                  aria-label="Pagination"
                >
                  <div className="flex flex-1 justify-between">
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                </nav>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-dashboardbackground">
                        <thead>
                          <tr>
                            <th
                              className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Transaction
                            </th>
                            <th
                              className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Amount
                            </th>
                            <th
                              className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                              scope="col"
                            >
                              Status
                            </th>
                            <th
                              className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {transactionlist.map((transaction) => (
                            <tr key={transaction.id} className="bg-white">
                              <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                <div className="flex">
                                  <a href={transaction.href} className="group inline-flex space-x-2 truncate text-sm">
                                    <BanknotesIcon
                                      className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    <p className="truncate text-gray-500 group-hover:text-gray-900">
                                      {transaction.itemtype}
                                    </p>
                                  </a>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                {"$"}
                                <span className="font-medium text-gray-900">{transaction.amount}</span>
                                {" USD"}
                              </td>
                              <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                <span
                                  className={classNames(
                                    statusStyles[transaction.status],
                                    'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                                  )}
                                >
                                  {transaction.status}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  )
}

export default MetaractShelfAnalytics
