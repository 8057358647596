import React from "react"

const PowerUpCard = ({ title, price }) => {
  return (
    <div className="flex border-dashboardalttext flex-col p-4 rounded-lg border-2">
      <p className="font-proxima text-dashboardtext">{price}</p>
      <p className="font-proxima text-dashboardtext">{title}</p>
    </div>
  )
}

export default PowerUpCard
