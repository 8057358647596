import React from "react"
import { FaInstagram, FaTwitch, FaTwitter } from "react-icons/fa"

import { CardTitle } from "../../Common/atoms/CardTitle"

const iconStyle =
  "text-dashboardalttext group-hover:text-dashboardhighlight w-6 h-6"
// TDOD add proper urls
const socials = [
  {
    name: "Instagram",
    url: "https://www.instagram.com/tinycaity/?hl=en",
    icon: <FaInstagram className={iconStyle} />,
  },
  {
    name: "Twitch",
    url: "https://twitch.tv/tinycaity",
    icon: <FaTwitch className={iconStyle} />,
  },
  {
    name: "Twitter",
    url: "https://twitter.com/CaityTiny",
    icon: <FaTwitter className={iconStyle} />,
  },
]

const LinkItem = ({ name, url, icon }) => (
  <a rel="noreferrer" target="_blank">
    <div className="group flex items-center ">
      {icon}
      <p className="ml-2 text-proxima underline text-md text-dashboardtext group-hover:text-dashboardhighlight">
        {name}
      </p>
    </div>
  </a>
)

const Links = () => {
  return (
    <div className="bg-dashboardboxbackground shadow-2xl w-60 lg:w-80 max-w-3xl pl-4 pr-12 py-4 rounded-lg bg-opacity-60 flex flex-col">
      <CardTitle text="Links" />
      <div className="flex flex-col ml-4 mt-7 mb-5 gap-y-4">
        {socials.map((item, i) => (
          <LinkItem
            key={item.name + item.url}
            name={item.name}
            url={item.url}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  )
}

export default Links
