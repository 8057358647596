const ArrowSVG = ({ disabled, styles }) => (
  <svg
    viewBox="0 0 24 24"
    className={`${styles} stroke stroke-2 stroke-dashboardtext ${
      !disabled && "group-hover:stroke-black"
    }`}
  >
    <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
  </svg>
)

export const Button = ({ onClick, text, disabled, styles, size = "big" }) => {
  const buttonStyle = {
    big: " mb-auto mt-4 px-5 pt-3 pb-2 ",
    medium: " px-4 pt-1 pb-0.5 ",
    small: "pl-4 pr-2 pt-1 pb-0.5",
  }
  const fontSize = {
    big: " text-lg ",
    medium: "text-base",
    small: " text-xs text-[10px] ",
  }
  const iconStyle = {
    big: "w-5 h-5 ml-2",
    medium: "w-4 h-4 m-2",
    small: "w-3 h-3 m-1",
  }
  return (
    <button
      onClick={disabled ? () => {} : onClick}
      className={`group flex font-ocr text-xl border border-dashboardhighlight bg-black/25 items-center justify-center self-center text-dashboardtext rounded-full ease-in-out ${
        disabled
          ? "opacity-50"
          : " hover:bg-dashboardhighlight hover:text-black transition"
      } ${styles ? styles : size === "big" && "mx-16"} ${buttonStyle[size]}`}
    >
      <p className={fontSize[size]}>{text?.toUpperCase()}</p>
      <ArrowSVG styles={iconStyle[size]} />
    </button>
  )
}
