import React from "react"
import TopBar from "../../components/Navigation/TopBar"
import Dashboard from "../../pages/Dashboard"
import ResetPassword from "../../pages/ResetPassword"
import FanOrCreator from "../../pages/FanOrCreator"
import Contact from "../../pages/Contact"
import FanAccountSettings from "../../pages/AccountSettings/FanAccountSettings"
import CreatorAccountSettings from "../../pages/AccountSettings/CreatorAccountSettings"
import Profile from "../../pages/Profile"
import CommunitySetup from "../../pages/CommunitySetup"
import Community from "../../pages/Community"
import GunRunner from "../../pages/GunRunner"
import Payment from "../../pages/Payment"
import Versus from "../../pages/Versus"
import VersusBattle from "../../pages/VersusBattle"
import Dare from "../../pages/Dare"
import MetaractShelf from "../../pages/MetaractShelf"
import MetaractShelfDetails from "../../pages/MetaractShelfDetails"
import SignInTest from "../../pages/SigninTest"
import SignIn from "../../pages/SignIn"
import MetaractShelfAnalytics from "../../pages/MetaractShelfAnalytics"
import SignupForm from "../../components/Authentication/SignupForm"

const ROUTES = {
  DASHBOARD: {
    path: "/",
    renderRoute: () => {
      return (
        <div className="w-screen flex flex-col h-screen">
          <TopBar />
          <Dashboard />
        </div>
      )
    },
  },
  FAN_SIGNUP: {
    path: "/fan-signup",
    renderRoute: () => <SignupForm />,
  },
  FAN_SIGNUP_DARE: {
    path: "/fan-signup/:id",
    renderRoute: () => <SignupForm />,
  },
  SIGN_IN: {
    path: "/signin",
    renderRoute: () => <SignIn />,
  },
  SIGN_IN_DARE: {
    path: "/signin/:id",
    renderRoute: () => <SignIn />,
  },
  RESET_PASSWORD: {
    path: "/reset-password",
    renderRoute: () => <ResetPassword />,
  },
  CREATOR_SIGNUP: {
    path: "/creator-signup",
    renderRoute: () => <SignupForm isCreator />,
  },
  FAN_OR_CREATOR: {
    path: "/fan-or-creator",
    renderRoute: () => <FanOrCreator />,
  },
  CONTACT: {
    path: "/contact",
    renderRoute: () => <Contact />,
  },
  FAN_ACCOUNT_SETTINGS: {
    path: "/fan-account-settings",
    renderRoute: () => <FanAccountSettings />,
  },
  CREATOR_ACCOUNT_SETTINGS: {
    path: "/creator-account-settings",
    renderRoute: () => <CreatorAccountSettings />,
  },
  PROFILE: {
    path: "/profile",
    renderRoute: () => <Profile />,
  },
  COMMUNITY_SETUP: {
    path: "/community-setup",
    renderRoute: () => <CommunitySetup />,
  },
  COMMUNITY: {
    path: "/community/:id",
    renderRoute: () => {
      return (
        <div>
          <TopBar />
          <Community />
        </div>
      )
    },
  },
  COMMUNITY_DARE_OPEN: {
    path: "/community/:id/:draweropen",
    renderRoute: () => {
      return (
        <div>
          <TopBar />
          <Community />
        </div>
      )
    },
  },
  GUN_RUNNER: {
    path: "/gun-runner",
    renderRoute: () => <GunRunner />,
  },
  PAYMENT: {
    path: "/payment",
    renderRoute: () => <Payment />,
  },
  VERSUS: {
    path: "/versus",
    renderRoute: () => <Versus />,
  },
  VERSUS_BATTLE: {
    path: "/versus-battle",
    renderRoute: () => <VersusBattle />,
  },
  DARE: {
    path: "/dare",
    renderRoute: () => <Dare />,
  },
  SIGNIN_TEST: {
    path: "/signintest",
    renderRoute: () => <SignInTest />,
  },
  METARACTSHELF: {
    path: "/metaractshelf/:id",
    renderRoute: () => {
      return (
        <div>
          <TopBar />
          <MetaractShelf />
        </div>
      )
    },
  },
  METARACTSHELFDETAILS: {
    path: "/metaractshelfdetails/:metaractid",
    renderRoute: () => <MetaractShelfDetails />,
  },
  METARACTSHELFANALYTICS: {
    path: "/metaractshelfanalytics/:id",
    renderRoute: () => {
      return (
        <div>
          <TopBar />
          <MetaractShelfAnalytics />
        </div>
      )
    },
  },
}

export default ROUTES
