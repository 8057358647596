import React, { useContext } from "react"

import { DareContext } from "../../../../../../../pages/Dare/context"
import CreatorDareLoad from "../../../../DareLoadCreator"
import CreatorPickDare from "../../../../CreatorPickDare"
import CreatorDareInProgress from "../../../../CreatorDareInProgress"

const DareCreatorLiveDare = () => {
  const dareContext = useContext(DareContext)

  const renderScreen = () => {
    if (!dareContext.hasClashStarted) {
      return <CreatorDareLoad />
    } else {
      if (!dareContext.clashInProgress) {
        return <CreatorPickDare />
      } else {
        return <CreatorDareInProgress />
      }
    }
  }
  return <div>{renderScreen()}</div>
}

export default DareCreatorLiveDare
