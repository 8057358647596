import { createContext, useState } from "react";
import COMMUNITY_SETUP_SCREENS from "../config/screens/CommunitySetup";

export const CommunitySetupContext = createContext();

const CommunitySetupProvider = ({ children }) => {
  const [currentScreenState, setCurrentScreenState] = useState(
    COMMUNITY_SETUP_SCREENS.INFO.key
  );

  return (
    <CommunitySetupContext.Provider
      value={{
        currentScreenState,
        setCurrentScreenState,
      }}
    >
      {children}
    </CommunitySetupContext.Provider>
  );
};

export default CommunitySetupProvider;
