import styled from "styled-components";

export const Button = styled.button`
  padding-top: 7px;
  width: 540px;
  height: 62px;
  border: 2px solid #18E97F;
  border-radius: 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  font-family: "OCR A Std", monospace;
`; // need to add arrow icon
