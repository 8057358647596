import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import * as R from "ramda"

import { CommunityContext } from "../../../../../../../context/community"
import { DareContext } from "../../../../../../../pages/Dare/context"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { UsersContext } from "../../../../../../../context/users"
import {
  DareItemsList,
  ClashIsLiveBanner,
  DrawerButton,
  DrawerContainer,
} from "../../../../../Common"

const FanDareLive = () => {
  const [topTwoDares, SetTopTwoDares] = useState({
    dareone: { dareDetails: "", dareTitle: "", darePrice: "" },
    daretwo: { dareDetails: "", dareTitle: "", darePrice: "" },
  })
  const [dareItems, SetDareItems] = useState([])
  const communityContext = useContext(CommunityContext)
  const dareContext = useContext(DareContext)
  const userContext = useContext(UsersContext)
  const userID = R.pathOr("", ["userDBObject", "id"], userContext)

  const getDareItems = async () => {
    const items = R.pathOr([], ["dare", "dareitems"], dareContext)
    const sorted = items
      .filter((item) => item.status === "ACTIVE")
      .sort((a, b) => b.price - a.price)
    SetDareItems(sorted)
    /*
    try {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/${metaract.id}/topthreedareitems`
      )
      const dareitems = R.pathOr("", ["data", "data"], res)
      SetDareItems(dareitems)
      console.log("Got dareitmes => ", dareitems)
    } catch (error) {
      console.log(error)
    }
    */
  }

  const getTopTwo = async () => {
    try {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/${metaract.id}/toptwodareitems`
      )
      const dareitems = R.pathOr("", ["data", "data"], res)
      const toptwo = {
        dareone: dareitems[0],
        daretwo: dareitems[1],
      }
      SetTopTwoDares(toptwo)
      console.log(
        "getTopTwo Fired Successfully => server message: ",
        res.data.message
      )
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTopTwo()
    getDareItems()
  }, [dareContext?.dare?.dareitems])

  const handleSubmit = () =>
    dareContext.setCurrentScreen(SCREENS.SCREENS.FAN_ADD_DARE)

  return (
    <DrawerContainer
      onClose={() => dareContext.setCurrentDrawerOpen(false)}
      header={
        <ClashIsLiveBanner
          title1={topTwoDares?.dareone?.title}
          title2={topTwoDares?.daretwo?.title}
        />
      }
      footer={<DrawerButton text="ADD DARE" onClick={handleSubmit} />}
    >
      <DareItemsList
        title="READY FOR VOTING"
        dareItems={dareItems}
        userID={userID}
      />
    </DrawerContainer>
  )
}

export default FanDareLive
