import styled from "styled-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export const Container = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  width: 540px;
  height: 100px;
  padding: 18px 20px;
  gap: 16px;
  display: flex;
  align-items: center;
`;

export const ContainerLarge = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  width: 540px;
  height: 243px;
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
  border: 1px dashed #acacac;
  border-radius: 16px;
  width: 100px;
  height: 100px;
  margin-right: 16px;
`;

export const IconContainerLarge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 160px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px dashed #acacac;
  border-radius: 16px;
`;

export const UpArrow = styled(ArrowUpwardIcon)`
  && {
    color: rgba(0, 0, 0, 0.6);
    font-size: 40px;
  }
`;

export const TextContainer = styled.div`
  width: 384px;
`;

export const TextContainerLarge = styled.div`
  width: 500px;
  height: 67px;
`;

export const TitleText = styled.p`
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 4px;
`;

export const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #536471;
`;
