import React, { useState, useContext } from "react"
import supabase from "../../../../../config/firebase/setup"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"
import * as R from "ramda"

import TermsAndServiceText from "../../../TermsAndServiceText"
import AlternativeText from "../../../AlternativeText"
import ROUTES from "../../../../../config/routes"
import SCREENS from "../../config/screens"
import { UsersContext } from "../../../../../context/users"
import { toast } from "react-toastify"
import { DrawerInput } from "../../../../Metaracts/Common"
import { Button } from "../../../../Common/atoms/Button"
import { useScreen } from "../../../../../hooks/useScreen"
import { Divider } from "../../../../Common/atoms/Divider"

const EmailPasssword = ({ setCurrentScreenState, isCreator }) => {
  const usersContext = useContext(UsersContext)
  const navigate = useNavigate()
  const { isMobile } = useScreen()
  const { id } = useParams()
  const [isLoadingState, setIsLoadingState] = useState(false)
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    emailMe: true,
  })

  const createNewUser = async () => {
    try {
      setIsLoadingState(true)
      const { data, error } = await supabase.auth.signUp({
        email: formState.email,
        password: formState.password,
      })

      console.log(data, "signupresponse")
      console.log(error, "error from signup")

      const signUpUserResponse = data

      usersContext.setFirebaseUserObjectState(signUpUserResponse)

      const backenddb = await axios.post(
        `${process.env.REACT_APP_SERVER_API}user/createuser`,
        {
          firestoreid: signUpUserResponse.user.id,
          name: signUpUserResponse.user.email,
          password: formState.password,
          email: signUpUserResponse.user.email,
          type: isCreator ? "CREATOR" : "FAN",
          emailme: formState.emailMe,
        }
      )
      usersContext.setUserDBObject(R.pathOr({}, ["data", "data"], backenddb))

      setCurrentScreenState(SCREENS.FINISH_PROFILE)
    } catch (error) {
      toast.error("Oops! Something went wrong")
    } finally {
      setIsLoadingState(false)
    }
  }

  return (
    <>
      {DrawerInput({
        type: "email",
        placeholder: "Email address",
        onChange: (event) =>
          setFormState({ ...formState, email: event.target.value }),
        title: "Email",
        titleStyle: "sm:text-xl",
      })}

      {DrawerInput({
        type: "password",
        placeholder: "Password",
        onChange: (event) =>
          setFormState({ ...formState, password: event.target.value }),
        title: "Password",
        titleStyle: "sm:text-xl",
      })}
      <Button
        onClick={createNewUser}
        text="CREATE ACCOUNT"
        size={isMobile ? "medium" : "big"}
        styles="mx-8 mt-10 mb-4 sm:mt-5 sm:mb-5 font-proxima"
      />
      <TermsAndServiceText />
      <Divider />
      <AlternativeText
        text="Already have an account?"
        buttonText="SIGN IN"
        onClick={() => {
          if (id) {
            navigate(`/signin/${id}`)
          } else {
            navigate(`/signin`)
          }
        }}
        isMobile
        styles={"font-proxima"}
      />
    </>
  )
}

export default EmailPasssword
