import React, { useState, useContext, useEffect, Fragment } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { toast } from "react-toastify"
import axios from "axios"
import * as R from "ramda"
import { MetaractShelfContext } from "../../pages/MetaractShelf/context"
import SCREENS from "../../pages/MetaractShelf/config/SCREENS"
import { VersusContext } from "../../pages/Versus/context"
import { GunRunnerContext } from "../../pages/GunRunner/context"

const MetaractShelfDropdown = ({ id }) => {
  const metaractShelfContext = useContext(MetaractShelfContext)
  const gunRunnerContext = useContext(GunRunnerContext)
  const versusContext = useContext(VersusContext)
  //const { id } = useParams()

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const checkActiveDare = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${id}`
      )
      const metaracts = R.pathOr([], ["data", "data", "metaracts"], res)
      const dareStatus = metaracts.filter(
        (metaract) => R.pathOr(false, ["status"], metaract) === "ACTIVE"
      )

      if (dareStatus.length > 0) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddDare = async () => {
    const hasActive = await checkActiveDare()
    if (hasActive) {
      toast.error("You already have an active dare, please complete it first.")
    } else {
      metaractShelfContext.setCurrentDrawerScreen(SCREENS.DRAWERS.LAUNCHDARE)
      metaractShelfContext.setDrawerIsOpen(true)
    }
  }

  const handleAddGunRunner = async () => {
    console.log("Gun Runner Added")
    gunRunnerContext.setCurrentDrawerOpen(true)
    metaractShelfContext.setCurrentDrawerScreen(SCREENS.DRAWERS.LAUNCHGUNRUNNER)
    metaractShelfContext.setDrawerIsOpen(true)
  }

  const handleRemoveDare = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${id}`
      )
      const metaracts = R.pathOr([], ["data", "data", "metaracts"], res)
      const activeDares = metaracts.filter(
        (metaract) => R.pathOr(false, ["status"], metaract) === "ACTIVE"
      )

      const activeDare = activeDares?.[0]
      if (!activeDare) {
        toast.error("No active dare found.")
        return
      }

      const update = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${activeDare.id}`,
        { status: "COMPLETED" }
      )
      console.log(
        "Metaract ",
        activeDare?.id,
        " has been set ACTIVE => COMPLETED"
      )
      if (update?.data?.message === "Success") {
        toast.success("Active dare has been set to completed")
      } else {
        toast.error("Something went wrong, please try again later.")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 font-hk shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          Create Metaract
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={handleAddDare}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Dare
                </a>
              )}
            </Menu.Item>
            {/*
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={handleRemoveDare}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Complete Dare
                  </a>
                )}
              </Menu.Item>*/}
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => {
                    versusContext.setNewVersusDrawerOpenState(true)
                    metaractShelfContext.setDrawerIsOpen(true)
                    metaractShelfContext.setCurrentDrawerScreen(
                      SCREENS.DRAWERS.LAUNCHVERSUS
                    )
                  }}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Versus
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={handleAddGunRunner}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Gun Runner
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default MetaractShelfDropdown
