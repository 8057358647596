import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-left: 85px;
  margin-top: 32px;
  width: 1064px;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  display: flex;
`;

export const Tab = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #101010;
  margin-right: 40px;
  border-bottom: ${({ active }) => (active ? "2px solid #101010" : "none")};
  cursor: pointer;
`;
