import { createContext, useEffect, useState } from "react"
import axios from "axios"
import supabase from "../config/firebase/setup"

export const UsersContext = createContext()

const UsersProvider = ({ children }) => {
  const [firebaseUserObjectState, setFirebaseUserObjectState] = useState(null)
  const [userIsSignedInState, setUserIsSignedInState] = useState(false)
  const [userDBObject, setUserDBObject] = useState(null)

  const checkAuthState = async () => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event) {
        let user = session?.user
        console.log(event, session)
        try {
          if(user){
            const userdb = await axios.get(
              `${process.env.REACT_APP_SERVER_API}user/getuserfirestore/${user?.id}`
            )
            setUserIsSignedInState(true)
            setUserDBObject(userdb?.data?.data)
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  useEffect(() => {
    checkAuthState()
  }, [])

  return (
    <UsersContext.Provider
      value={{
        firebaseUserObjectState,
        setFirebaseUserObjectState,
        userIsSignedInState,
        setUserIsSignedInState,
        userDBObject,
        setUserDBObject,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

export default UsersProvider
