import React, { useState, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import supabase from "../../../config/firebase/setup"
import axios from "axios"
import * as R from "ramda"
import { toast } from "react-toastify"

import { UsersContext } from "../../../context/users"

import { Divider } from "../../Common/atoms/Divider"
import { Button } from "../../Common/atoms/Button"
import { DrawerInput } from "../../Metaracts/Common"
import ForgotPasswordLink from "../ForgotPasswordLink"
import { useScreen } from "../../../hooks/useScreen"
import AlternativeText from "../AlternativeText"

const SignInForm = () => {
  const usersContext = useContext(UsersContext)
  const navigate = useNavigate()
  const { isMobile } = useScreen()
  const { id } = useParams()
  const [isLoadingState, setIsLoadingState] = useState(false)
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    emailMe: true,
  })

  const handleLogIn = async () => {
    try {
      setIsLoadingState(true)
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formState.email,
        password: formState.password,
      })

      console.log("data", data)

      console.log("error", error)

      const signInUserResponse = data

      const backenddb = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuserfirestore/${signInUserResponse.user.id}`
      )

      if (backenddb) {
        usersContext.setUserDBObject(backenddb?.data?.data)
      } else {
        toast.error("Oops! Something went wrong, not in DB")
      }

      const userToken = R.pathOr(
        "",
        ["session", "access_token"],
        signInUserResponse
      )
      usersContext.setUserIsSignedInState(!!userToken)
      if (id) {
        navigate(`/community/${id}/true`)
      } else {
        navigate("/")
      }
    } catch (error) {
      toast.error("Oops! Something went wrong")
    } finally {
      setIsLoadingState(false)
    }
  }

  return (
    <>
      {DrawerInput({
        type: "email",
        placeholder: "Email address",
        onChange: (event) =>
          setFormState({ ...formState, email: event.target.value }),
        title: "Email",
        titleStyle: "sm:text-xl",
      })}
      {DrawerInput({
        type: "password",
        placeholder: "Password",
        onChange: (event) =>
          setFormState({ ...formState, password: event.target.value }),
        title: "Password",
        titleStyle: "sm:text-xl",
      })}

      <Button
        onClick={handleLogIn}
        text="SIGN IN"
        size={isMobile ? "medium" : "big"}
        styles="mx-8 mt-10 mb-4 sm:mt-5 sm:mb-2 font-proxima"
      />
      <ForgotPasswordLink text-center/>
      <Divider />
      <AlternativeText
        text="Need an account?"
        buttonText="SIGN UP"
        onClick={() => navigate("/fan-or-creator")}
        isMobile
        styles={"font-proxima"}
      />
    </>
  )
}

export default SignInForm

// IMPLEMENTATION

// 1) After the token and expiry is returned from firebase, store that using
//    window.localStorage.setItem('TOKEN', JSON.stringify(TOKEN_FROM_FIREBASE));
// 2) Create a context that wraps around the entire application, and call it whenever the app loads
//    for the first time (regardless of their page), and check if the token exists AND is not expired:
//    window.localStorage.getItem('TOKEN'); You will likely need to JSON.parse this
// 3) If the token exists AND is not expired, pass it to firebase auth, to authenticate the user
//    and let them use the app however they want
// 4) If the token does not exist OR is expired, redirect them to the sign in page
