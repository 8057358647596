import React, { useContext, useEffect, useState } from "react"
import { Flex } from "rebass"
import io from "socket.io-client"
import * as R from "ramda"
import axios from "axios"
import { toast } from "react-toastify"
import useSound from "use-sound"
import boopsfx from "../../assets/sounds/boop.mp3"

import TopBar from "../../components/Navigation/TopBar"
import BattleFeed from "../../components/VersusBattle/BattleFeed"
import BattleInformation from "../../components/VersusBattle/BattleInformation"
import Header from "../../components/VersusBattle/Header"
import PowerUpCards from "../../components/VersusBattle/PowerUpCards"
import StreamContainer from "../../components/VersusBattle/StreamContainer"
import { VersusContext } from "../Versus/context"
import { CommunityContext } from "../../context/community"
import { UsersContext } from "../../context/users"
import { SocketContext } from "../../context/socket"

const VersusBattle = () => {
  const versusContext = useContext(VersusContext)
  const communityContext = useContext(CommunityContext)
  const usersContext = useContext(UsersContext)
  const socket = useContext(SocketContext)

  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext)
  const userID = R.pathOr("", ["userDBObject", "id"], usersContext)
  const metaracts = R.pathOr(0, ["metaracts"], communityContext)
  const metaract = metaracts.find(
    (metaract) => metaract.type === "VERSUS" && metaract.status === "ACTIVE"
  )
  const [sfx] = useSound(boopsfx)
  const NotifyPowerUp = (data) => {
    try {
      toast.success(
        `${data.username} has used a power up! Power Up: ${data.description}`
      )
      sfx()
      console.log("called sfx")
    } catch (error) {
      console.log(error)
    }
  }
  const getVersus = async () => {
    try {
      versusContext.setIsLoading(true)
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${metaract.id}`
      )
      console.log(res.data, "=> Got active versus on Versus Battle")
      versusContext.setVersus(res.data.data)
      const rescreator = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${res.data.data.description.creator}`
      )
      const resselected = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${res.data.data.description.selectedcreator}`
      )
      versusContext.setSelectedCreator(resselected.data.data)
      versusContext.setCreator(rescreator.data.data)
      await getVersusItems()
      if (socket) {
        if (metaract !== undefined) {
          if (metaract.id !== undefined) {
            if (versusContext.hasntJoinedRoom) {
              socket.emit("JOIN_ROOM", `versus_${metaract?.id}`)
              console.log("Joined Room => ", `versus_${metaract?.id}`)
              versusContext.setHasntJoinedRoom(false)
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      versusContext.setIsLoading(false)
    }
  }
  const getVersusItems = async () => {
    try {
      versusContext.setIsLoading(true)
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${metaract.id}`
      )
      console.log(
        res.data,
        "=> Got active versus on Versus Battle Versus Items"
      )
      versusContext.setVersusItems(res?.data?.data?.versusitems)
    } catch (error) {
      console.log(error)
    } finally {
      versusContext.setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!communityContext.isLoading) {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "VERSUS" && metaract.status === "ACTIVE"
      )
      if (metaract !== undefined) {
        getVersus()
      }
    }
  }, [communityContext.isLoading])

  useEffect(() => {
    if (socket) {
      socket.on("RECEIVE_VERSUS_HAS_CHANGED", (data) => {
        getVersusItems()
        console.log("Event => RECEIVE_VERSUS_HAS_CHANGED")
      })
      socket.on("RECEIVE_FAN_DONATED_MONEY", (data) => {
        console.log(data, "=> RECEIVE_FAN_DONATED_MONEY data")
        versusContext.setBattleFeed((battleFeed) => [...battleFeed, data])
        console.log("Event => RECEIVE_FAN_DONATED_MONEY")
      })
      socket.on("RECEIVE_USED_POWER_UP", (data) => {
        versusContext.setBattleFeed((battleFeed) => [...battleFeed, data])
        console.log(data, "=> RECEIVE_USED_POWER_UP data")
        NotifyPowerUp(data)
      })

      return () => {
        socket.off("RECEIVE_VERSUS_HAS_CHANGED")
        socket.off("RECEIVE_FAN_DONATED_MONEY")
        socket.off("RECEIVE_USED_POWER_UP")
      }
    }
  }, [socket])

  return (
    <div className="flex w-full bg-dashboardbackground">
      <div className="flex px-10 py-6 pt-9 w-9/12 xl:w-10/12 flex-col">
        <Flex mb="15px">
          <Header />
        </Flex>
        <Flex mb="20px">
          <StreamContainer />
        </Flex>
        <div className="mt-6">
          <PowerUpCards />
        </div>
      </div>
      <div className="flex flex-col w-80 pb-1 bg-dashboardboxbackground rounded-l-xl">
        <BattleFeed />
      </div>
    </div>
  )
}

export default VersusBattle
