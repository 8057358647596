import { useState } from "react"
// import { sendPasswordResetEmail, getAuth } from "firebase/auth"
// import { toast } from "react-toastify"
// import SCREENS from "../../config/screens"
import { DrawerInput } from "../../../../Metaracts/Common"
import { Button } from "../../../../Common/atoms/Button"
import { useScreen } from "../../../../../hooks/useScreen"

const Form = ({ setCurrentScreenState }) => {
  const { isMobile } = useScreen()
  // const auth = getAuth()
  const [formState, setFormState] = useState({
    email: "",
  })
  const [isLoadingState, setIsLoadingState] = useState(false)

  const handleSendResetPasswordEmail = async () => {
    // TODO: fix reset password function
    // try {
    //   setIsLoadingState(true)
    //   await sendPasswordResetEmail(auth, formState.email)
    // setCurrentScreenState(SCREENS.SUCCESS)
    // } catch (error) {
    //   toast.error("Oops! Something went wrong")
    // } finally {
    //   setIsLoadingState(false)
    // }
  }

  return (
    <>
      <p>
        Enter the email associated with your account and we'll send an email
        with instructions to reset your password.
      </p>
      {DrawerInput({
        type: "email",
        placeholder: "Email address",
        onChange: (event) =>
          setFormState({ ...formState, email: event.target.value }),
        title: "Email",
        titleStyle: "sm:text-xl",
      })}
      <Button
        onClick={handleSendResetPasswordEmail}
        text="Send"
        size={isMobile ? "medium" : "big"}
        styles="mt-6 font-proxima"
      />
    </>
  )
}

export default Form
