import React, { useState, useContext } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";

import { AccountSettingsContext } from "../../../context/accountSettings";
import ACCOUNT_SETTINGS_SCREENS from "../../../config/screens/AccountSettings";

import * as Styled from "./styles";

const Menu = () => {
  const [accountDropdownOpenState, setAccountDropdownOpenState] = useState(
    true
  );
  const accountSettingsContext = useContext(AccountSettingsContext);

  return (
    <Styled.Container>
      <Styled.Title>Settings</Styled.Title>
      <List>
        <ListItemButton
          onClick={() => setAccountDropdownOpenState(!accountDropdownOpenState)}
        >
          <ListItemText>Account</ListItemText>
          <ExpandMore />
        </ListItemButton>
        <Collapse in={accountDropdownOpenState}>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() =>
                accountSettingsContext.setCurrentScreenState(
                  ACCOUNT_SETTINGS_SCREENS.PERSONAL_INFO
                )
              }
            >
              <ListItemText primary="Personal Info" />
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                accountSettingsContext.setCurrentScreenState(
                  ACCOUNT_SETTINGS_SCREENS.PASSWORD
                )
              }
            >
              <ListItemText primary="Password" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Styled.Container>
  );
};

export default Menu;
