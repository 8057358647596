import styled from "styled-components";

export const Container = styled.div`
  margin-left: 85px;
  margin-top: 40px;
`;

export const NameText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #101010;
  margin-bottom: 8px;
`;

export const UsernameText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #536471;
`;
