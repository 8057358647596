import styled from "styled-components";

export const Label = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #E6E6EB;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  border: 2px solid #8D8D8D;
  background-color: rgba(6, 17, 23, 0.75);
  border-radius: 12px;
  width: ${({ width = 540 }) => `${width}px`};
  height: 62px;
  padding-left: 20px;
  padding-right: 20px;
  color: blue;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
`;
