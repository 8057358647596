import React, { useContext, useState, useEffect } from "react"
import * as R from "ramda"
import axios from "axios"
import io from "socket.io-client"
import { Flex } from "rebass"
import { VersusContext } from "../../../pages/Versus/context"
import { CommunityContext } from "../../../context/community"

import * as Styled from "./styles"

const BattleInformation = () => {
  const versusContext = useContext(VersusContext)
  const communityContext = useContext(CommunityContext)
  const [selectedTotal, setSelectedTotal] = useState(0)
  const [creatorTotal, setCreatorTotal] = useState(0)

  const [isSelectedCreator, setIsSelectedCreator] = useState()

  const [creatorProfilePictureURL, setCreatorProfilePictureURL] = useState("")
  const [creatorName, setCreatorName] = useState("")
  const [creatorSupporters, setCreatorSupporters] = useState(0)
  const [
    selectedCreatorProfilePictureURL,
    setSelectedCreatorProfilePictureURL,
  ] = useState()
  const [selectedCreatorName, setSelectedCreatorName] = useState("")
  const [selectedCreatorSupporters, setSelectedCreatorSupporters] = useState(0)

  const checkIfSelectedCreator = () => {
    if (!versusContext.isLoading) {
      setCreatorName(versusContext?.creator?.creator?.name)
      setCreatorProfilePictureURL(
        versusContext?.creator?.creator?.profilepictureurl
      )
      setCreatorSupporters(versusContext?.creator?.supporters)
      setSelectedCreatorName(versusContext?.selectedCreator?.creator?.name)
      setSelectedCreatorProfilePictureURL(
        versusContext?.selectedCreator?.creator?.profilepictureurl
      )
      setSelectedCreatorSupporters(versusContext?.selectedCreator?.supporters)
      if (versusContext?.creator?.id === communityContext?.id) {
        setIsSelectedCreator(false)
      } else {
        setIsSelectedCreator(true)
      }
    }
  }

  const getTotalContributions = () => {
    if (!versusContext.isLoading) {
      const items = versusContext?.versusItems
      if (items) {
        const creatortotal = items
          .map((item) => {
            return item.contributionscreator
          })
          .reduce((a, b) => a + b, 0)

        const selectedtotal = items
          .map((item) => {
            return item.contributionsselected
          })
          .reduce((a, b) => a + b, 0)

        setCreatorTotal(creatortotal)
        setSelectedTotal(selectedtotal)
      }
    }
  }

  useEffect(() => {
    if (!versusContext.isLoading) {
      checkIfSelectedCreator()
      getTotalContributions()
    }
  }, [versusContext.isLoading])
  return (
    <div className="w-full">
      <div className="flex px-4 justify-between pt-3 pb-4">
        <div className="flex justify-center">
          <div
            style={{
              backgroundImage: `url(${
                !isSelectedCreator
                  ? creatorProfilePictureURL
                  : selectedCreatorProfilePictureURL
              })`,
            }}
            className="flex bg-cover bg-center bg-no-repeat w-12 h-12 border-2 border-blue-600 rounded-lg"
          ></div>
          <div className="flex flex-col justify-center items-begin pl-4">
            <h2 className="flex text-proxima text-base text-dashboardtext">
              {!isSelectedCreator ? creatorName : selectedCreatorName}
            </h2>
            <p className="font-proxima text-dashboardalttext whitespace-nowrap text-sm">
              <Styled.GroupIcon />
              {!isSelectedCreator
                ? creatorSupporters
                : selectedCreatorSupporters}
              k
            </p>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="flex flex-col justify-center items-end pr-4">
            <h2 className="flex text-proxima text-base text-dashboardtext">
              {isSelectedCreator ? creatorName : selectedCreatorName}
            </h2>
            <p className="font-proxima text-dashboardalttext whitespace-nowrap text-sm">
              <Styled.GroupIcon />
              {!isSelectedCreator
                ? creatorSupporters
                : selectedCreatorSupporters}
              k
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(${
                isSelectedCreator
                  ? creatorProfilePictureURL
                  : selectedCreatorProfilePictureURL
              })`,
            }}
            className="flex bg-center bg-cover bg-no-repeat w-12 h-12 border-2 border-red-600 rounded-lg"
          ></div>
        </div>
      </div>
      <div className="flex w-full px-4">
        <div className="flex w-full justify-between items-center">
          <div className="pt-0.5 h-10 w-20 flex items-center justify-center rounded border-2 border-blue-600 text-blue-600 font-ocr">
            {isSelectedCreator ? `$${selectedTotal}` : `$${creatorTotal}`}
          </div>
          <div className="flex flex-col justify-center items-center text-dashboardtext">
            <p className="font-proxima text-2xl font-bold">
              {`$${Number(selectedTotal) + Number(creatorTotal)}`}
            </p>
            <h1 className="font-proxima text-sm">total in pot</h1>
          </div>
          <div className="pt-0.5 h-10 w-20 flex items-center justify-center rounded border-2 border-red-600 text-red-600 font-ocr">
            {!isSelectedCreator ? `$${selectedTotal}` : `$${creatorTotal}`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BattleInformation
