import React, { useState, useContext, useEffect } from "react"
// import io from "socket.io-client"
import axios from "axios"
import * as R from "ramda"
import { DareContext } from "../../../../../pages/Dare/context"
import { UsersContext } from "../../../../../context/users"
import { CommunityContext } from "../../../../../context/community"
import {
  ClashIsLiveBanner,
  DareItemsList,
  DrawerButton,
  DrawerContainer,
} from "../../../Common"

import SCREENS from "../../../../../pages/Dare/config/SCREENS"
import DareVotingTimeLeft from "../../DareVotingTimeLeft"
import { SocketContext } from "../../../../../context/socket"
import { MetaractShelfContext } from "../../../../../pages/MetaractShelf/context"

const DareCreatorCommunity = () => {
  const communityContext = useContext(CommunityContext)
  const dareContext = useContext(DareContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const [isLive, setIsLive] = useState(false)
  const [dareItems, setDareItems] = useState([])
  const [Timeend, setTimeEnd] = useState(0)
  const socket = useContext(SocketContext)
  const userContext = useContext(UsersContext)
  const userID = R.pathOr("", ["userDBObject", "id"], userContext)
  const [topTwo, SetTopTwo] = useState({})

  useEffect(() => {
    if (!dareContext.isLoading) {
      setIsLive(R.pathOr("", ["dare", "description", "islive"], dareContext))
      const items = R.pathOr([], ["dare", "dareitems"], dareContext)
      const sorted = items
        .filter((item) => item.status === "ACTIVE")
        .sort((a, b) => b.price - a.price)
      setDareItems(sorted)
      SetTopTwo({ dareone: sorted[0], daretwo: sorted[1] })
      setTimeEnd(R.pathOr(0, ["dare", "description", "timeend"], dareContext))
    }
  }, [dareContext.isLoading])

  const handleSettings = () => {
    dareContext.setCurrentScreen(SCREENS.SCREENS.CREATOR_DARE_SETTINGS)
  }

  const handleReady = () => {
    dareContext.setCurrentScreen(SCREENS.SCREENS.CREATOR_LIVE_DARE)
  }

  const handleGoLive = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${dareContext.metaractid}`
      )
      const description = R.pathOr("", ["data", "description"], res.data)

      res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${dareContext.metaractid}`,
        { description: { ...description, islive: true } }
      )
      console.log("Dare Go Live.", res.data)
      dareContext.setIsLive(true)
      if (socket !== undefined) {
        socket.emit("SEND_DARE_HAS_CHANGED", `dare_${dareContext.metaractid}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEndLive = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${dareContext.metaractid}`
      )
      const description = R.pathOr("", ["data", "description"], res.data)

      res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${dareContext.metaractid}`,
        { description: { ...description, islive: false } }
      )
      console.log("Dare End Live.")
      dareContext.setIsLive(false)
      if (socket !== undefined) {
        socket.emit("SEND_DARE_HAS_CHANGED", `dare_${dareContext.metaractid}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DrawerContainer
      type="dare"
      back
      onClose={() => {
        dareContext.setCurrentDrawerOpen(false)
        dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.INIT)
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
        communityContext.setDrawerOpen(false)
        metaractShelfContext.setDrawerIsOpen(false)
      }}
      footer={
        <div className="flex h-28 min-h-28 shrink-0 justify-between mt-auto mx-6 sm:mx-10">
          <DrawerButton
            text={!isLive ? "GO LIVE" : "End Live"}
            onClick={!isLive ? handleGoLive : handleEndLive}
            styles="mx-0"
          />
          <DrawerButton
            text={!isLive ? "settings" : "Ready"}
            onClick={!isLive ? handleSettings : handleReady}
            styles="mx-0"
          />
        </div>
      }
      header={
        !isLive ? (
          <DareVotingTimeLeft timeend={Timeend} />
        ) : (
          <ClashIsLiveBanner
            title1={topTwo?.dareone?.title}
            title2={topTwo?.daretwo?.title}
          />
        )
      }
    >
      <DareItemsList
        title={"READY FOR VOTING"}
        dareItems={dareItems.filter((dareitem) => dareitem.status === "ACTIVE")}
        userID={userID}
        type="CREATOR"
      />
    </DrawerContainer>
  )
}

export default DareCreatorCommunity
