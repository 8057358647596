import React from "react";
import { Flex } from "rebass";

import SideTextPanel from "../../components/Common/SideTextPanel";
import ContactForm from "../../components/Contact/ContactForm";

import * as Styled from "./styles";

// ADD SLACK NOTIF FOR NEW MESSAGE

const Contact = () => {
  return (
    <Styled.Container>
      <Flex mr="153px">
        <SideTextPanel description="Autonomy in the hands of the" />
      </Flex>
      <ContactForm />
    </Styled.Container>
  );
};

export default Contact;
