import React, { useContext, useState } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import InfoIcon from "@mui/icons-material/Info"

import { VersusContext } from "../../../../../../pages/Versus/context"
import { MetaractShelfContext } from "../../../../../../pages/MetaractShelf/context"
import { DrawerButton } from "../../../../Common"

const Setup = () => {
  const versusContext = useContext(VersusContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const [battleTitle, setBattleTitle] = useState("")
  const [minPrice, setMinPrice] = useState(10)

  return (
    <div className="flex flex-col h-screen justify-between bg-daresidepanelbg">
      <div className="flex flex-col">
        <div className="flex justify-between  mx-8 mt-8 mb-10 ">
          <div className="bg-dashboardhighlight hover:cursor-pointer rounded-sm drop-shadow items-center py-1 px-4 flex justify-center">
            <p className="font-ocr text-sm">VERSUS</p>
          </div>
          <div
            onClick={() => {
              versusContext.setNewVersusDrawerOpenState(false)
              metaractShelfContext.setDrawerIsOpen(false)
            }}
            className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>
        <div className="mx-4">
          <div className="mx-4 flex flex-col">
            <h1 className="font-ocr text-dashboardtext text-2xl">
              LIVE PK BATTLE
            </h1>
            <p className="font-proxima mt-8 text-dashboardtext text-md">
              Invite a creator to co-host a stream. Create Power-Ups for the
              battle and get fans from both sides to pool in to unlock them for
              both challengers throughout the match.
            </p>
          </div>
          <div className="bg-gray-500 mx-8 mt-4 px-2 py-2 text-dashboardtext rounded-lg flex items-center justify-center">
            <InfoIcon />
            <p className="font-proxima ml-2 text-xs">
              The creator you challenge needs to review and approve the
              power-ups before battle can begin.
            </p>
          </div>
          <div className=" mt-10 text-dashboardtext mx-4 text-xl font-proxima font-bold">
            Settings
          </div>
          <div className="flex flex-col mt-4 w-full px-4 items-start justify-center ">
            <h2 className="font-proxima text-lg text-dashboardtext">
              Battle Title
            </h2>
            <input
              name="title"
              placeholder="BEAST vs POFF"
              onChange={(event) => {
                setBattleTitle(event.target.value)
              }}
              className="mt-2 border border-dashboardframes outline-none text-dashboardtext bg-transparent font-proxima rounded-lg py-2 w-full px-2 items-center justify-center flex"
            ></input>
          </div>
          <div className="flex flex-col w-full mt-4 px-4 items-start justify-center ">
            <h2 className="font-proxima text-lg text-dashboardtext">
              Min Fan Contribution
            </h2>
            <input
              name="title"
              placeholder="USD $2.00 / power-up"
              onChange={(event) => {
                setMinPrice(event.target.value)
              }}
              type="number"
              className="mt-2 border border-dashboardframes outline-none text-dashboardtext bg-transparent font-proxima rounded-lg py-2 w-full px-2 items-center justify-center flex"
            ></input>
          </div>
        </div>
      </div>
      <DrawerButton
        text="CONFIRM SETUP"
        onClick={() => {
          versusContext.setCurrentNewVersusScreensState(
            versusContext.newVersusScreens.CHOOSE_CREATOR
          )
          console.log(minPrice, battleTitle, "=> setup states")
          versusContext.setSetupState({
            ...versusContext.setupState,
            minprice: minPrice,
            battletitle: battleTitle,
          })
        }}
      />
    </div>
  )
}

export default Setup
