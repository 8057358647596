import React, { useContext } from "react"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { DareContext } from "../../../../../../../pages/Dare/context"
import DareFanCommunity from "../FanCommunity"
import DareFanAddDare from "../FanAddDare"
import DareFanDareDetails from "../FanDareDetails"
import DareFanLiveDare from "../FanLiveDare"

const FanDrawer = () => {
  const dareContext = useContext(DareContext)
  if (dareContext.currentScreen === SCREENS.SCREENS.INIT) {
    return <DareFanCommunity />
  }
  if (dareContext.currentScreen === SCREENS.SCREENS.FAN_ADD_DARE) {
    return <DareFanAddDare />
  }
  if (dareContext.currentScreen === SCREENS.SCREENS.FAN_DARE_DETAILS) {
    return <DareFanDareDetails />
  }
  if (dareContext.currentScreen === SCREENS.SCREENS.FAN_LIVE_DARE) {
    return <DareFanLiveDare />
  }
}

export default FanDrawer
