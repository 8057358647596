import React, { useState, useContext, useEffect } from "react"
import { toast } from "react-toastify"
// import DareCountdownCard from "../DareCountdownCard"
import { DareContext } from "../../../../pages/Dare/context"

const CountdownTimer = ({ delta }) => {
  const start = new Date(Date.now() + delta)
  const dareContext = useContext(DareContext)
  const current = start - Date.now()
  const [seconds, setseconds] = useState(Math.floor(current / 1000) % 60)
  const [minutes, setminutes] = useState(Math.floor(current / (1000 * 60)) % 60)
  const [raw, setraw] = useState(1)
  const [hours, sethours] = useState(
    Math.floor(current / (1000 * 60 * 60)) % 24
  )
  const [days, setdays] = useState(Math.floor(current / (1000 * 60 * 60 * 24)))

  useEffect(() => {
    const interval = setInterval(() => {
      const current = start - Date.now()
      if (current > 1) {
        const s = Math.floor(current / 1000) % 60
        const m = Math.floor(current / (1000 * 60)) % 60
        const h = Math.floor(current / (1000 * 60 * 60)) % 24
        const d = Math.floor(current / (1000 * 60 * 60 * 24))
        setseconds(s)
        setminutes(m)
        sethours(h)
        setdays(d)
        if (dareContext.OutOfTime === false) {
          setraw(current)
        }
      } else {
        setseconds(0)
        setminutes(0)
        sethours(0)
        setdays(0)
        setraw(0)
        clearInterval(interval)
        toast.success(
          "Time has run out! Dare(s) have been removed, pick a Dare faster!"
        )
        dareContext.SetOutOfTime(true)
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [dareContext.OutOfTime])

  return (
    <div>
      {raw !== 0 ? (
        <div className="flex flex-col w-full justify-center mt-10 p-1 sm:p-4 border-2 rounded-md sm:rounded-0 border-dashboardhighlight items-center">
          <h1 className="font-ocr text-2xl text-dashboardhighlight">
            {`00h 00m ${
              seconds + 1 == 60
                ? "00"
                : seconds + 1 < 10
                ? `0${seconds + 1}`
                : `${seconds + 1}`
            }s LEFT`}
          </h1>
        </div>
      ) : (
        <div className="flex flex-col w-full justify-center mt-10 p-1 sm:p-4 border-2 rounded-md sm:rounded-0 border-dashboardhighlight items-center">
          <h1 className="font-ocr text-2xl text-dashboardhighlight">
            Waiting to start...
          </h1>
        </div>
      )}
    </div>
  )
}

export default CountdownTimer
