import React, { useContext } from "react"
import SCREENS from "../../../../../../../pages/GunRunner/config/SCREENS"
import { GunRunnerContext } from "../../../../../../../pages/GunRunner/context"
import GunRunnerFanCommunity from "../../../GunRunnerFanCommunity"
import GunRunnerFanBuy from "../GunRunnerFanBuy"
const GunRunnerFanDrawer = () => {
  const gunRunnerContext = useContext(GunRunnerContext)
  if (gunRunnerContext.currentScreen === SCREENS.SCREENS.INIT) {
    return <GunRunnerFanCommunity />
  } else if (gunRunnerContext.currentScreen === SCREENS.SCREENS.FAN_BUY_SESSION) {
    return <GunRunnerFanBuy />
  }
} 

export default GunRunnerFanDrawer
