import { createContext, useState } from "react"

export const MetaractShelfContext = createContext()

const MetaractShelfProvider = ({ children }) => {
  const [currentDrawerScreen, setCurrentDrawerScreen] = useState("")
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [dareitem, setDareItem] = useState("")
  const [metaractid, setMetaractID] = useState("")
  const [metaract, setMetaract] = useState("")
  const [metaractDrawer, setMetaractDrawer] = useState(false)

  return (
    <MetaractShelfContext.Provider
      value={{
        currentDrawerScreen,
        setCurrentDrawerScreen,
        drawerIsOpen,
        setDrawerIsOpen,
        dareitem,
        setDareItem,
        metaractid,
        setMetaractID,
        metaract,
        setMetaract,
        metaractDrawer,
        setMetaractDrawer,
      }}
    >
      {children}
    </MetaractShelfContext.Provider>
  )
}

export default MetaractShelfProvider
