import { Button } from "../../Common/atoms/Button"

const AlternativeText = ({ text, buttonText, onClick, isMobile, styles }) => {
  return (
    <div className="flex w-full justify-center items-center gap-x-5 mt-4">
      <h1 className="font-proxima text-sm sm:text-xl text-dashboardtext">
        {text}
      </h1>
      <Button
        onClick={onClick}
        text={buttonText}
        size={isMobile ? "small" : "medium"}
        styles={styles}
      />
    </div>
  )
}

export default AlternativeText
