import React from "react"

const BottomBorder = () => {
  return (
    <svg
      width="95vw"
      display="block"
      margin="auto auto"
      preserveAspectRatio="xMaxYMax meet"
      viewBox="0 0 1197 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12L31.2003 39.6429C31.3903 39.8693 31.6707 40 31.9662 40H789.551C789.837 40 790.108 39.8781 790.298 39.6651L818.202 8.33491C818.391 8.12186 818.663 8 818.948 8H1155.53C1155.83 8 1156.11 8.1324 1156.3 8.36124L1178 34.5"
        stroke="#E756FF"
        strokeWidth="5"
      />
      <path d="M1121 8H1153.5" stroke="#97FFB4" strokeWidth="3" />
      <path
        d="M1171.5 19L1167 19L1158 7.99998L1146.5 7.99998"
        stroke="#97FFB4"
        strokeWidth="3"
      />
      <path d="M1166 25L1175 36" stroke="#97FFB4" strokeWidth="3" />
      <path d="M16 17L36 41" stroke="#97FFB4" strokeWidth="3" />
      <path d="M33 38H95" stroke="#97FFB4" strokeWidth="3" />
      <path d="M399 42H470.5" stroke="#97FFB4" strokeWidth="3" />
      <path d="M155 42H206.5" stroke="#97FFB4" strokeWidth="3" />
      <path d="M1145 16L1157 16" stroke="#97FFB4" strokeWidth="3" />
      <path d="M1185 19L1197 19" stroke="#97FFB4" strokeWidth="3" />
      <path d="M1181 29L1193 29" stroke="#97FFB4" strokeWidth="3" />
      <path d="M1178 37L1186 46" stroke="#E756FF" strokeWidth="2" />
      <path d="M1169 21H1177" stroke="#E756FF" strokeWidth="2" />
      <path d="M1181 21H1189" stroke="#E756FF" />
      <path d="M1159 25H1170" stroke="#97FFB4" strokeWidth="3" />
      <path d="M1170 21H1177" stroke="#49F4FF" strokeWidth="3" />
      <path d="M1151 18H1158" stroke="#49F4FF" />
      <path d="M1168 25L1177 36" stroke="#49F4FF" strokeWidth="5" />
      <path d="M1159 25H1170" stroke="#49F4FF" strokeWidth="3" />
      <path d="M1169 25L1186 44" stroke="#49F4FF" strokeWidth="3" />
      <path d="M814 8H1070" stroke="#97FFB4" strokeWidth="3" />
      <path
        d="M2 8.00012L28.2001 39.6379C28.39 39.8673 28.6724 40.0001 28.9703 40.0001H784.533C784.829 40.0001 785.11 39.8692 785.3 39.6425L813.2 6.3576C813.39 6.13093 813.671 6 813.967 6H1152.53C1152.83 6 1153.11 6.13182 1153.3 6.35981L1163 18"
        stroke="#49F4FF"
        strokeWidth="5"
      />
    </svg>
  )
}

export default BottomBorder
