import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { UsersContext } from "../../../context/users"

import supabase from "../../../config/firebase/setup"

const SignOutButton = () => {
  const navigate = useNavigate()
  const usersContext = useContext(UsersContext)

  const handleClick = async () => {
    try {
      const { error } = await supabase.auth.signOut()
    } catch (error) {
      console.log(error)
    } finally {
      usersContext.setUserIsSignedInState(false)
      navigate("/")
    }
  }

  return (
    <div className="flex justify-center  py-1">
      <button
        onClick={handleClick}
        className="bg-transparent hover:bg-dashboardhighlight text-dashboardtext hover:text-black font-ocr pt-2 pb-1 px-4 text-sm border-dashboardhighlight border-2 rounded"
      >
        SIGN OUT
      </button>
    </div>
  )
}

export default SignOutButton
