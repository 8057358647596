import React, { useState, useContext, useEffect } from "react"
import { Flex, Box } from "rebass"
import axios from "axios"
import { toast } from "react-toastify"

import { CommunityContext } from "../../../../context/community"
import { MetaractShelfContext } from "../../../../pages/MetaractShelf/context"
import { GunRunnerContext } from "../../../../pages/GunRunner/context"
import SCREENS from "../../../../pages/GunRunner/config/SCREENS"

const MetaractShelfLaunchGunRunner = () => {
  const communityContext = useContext(CommunityContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const gunRunnerContext = useContext(GunRunnerContext)
  const [price, setPrice] = useState(5)
  const [duration, setDuration] = useState(1)
  const [game, setGame] = useState("")
  const [platform, setPlatform] = useState("")
  const [avaiableSpots, setAvailableSpots] = useState(5)
  const [title, setTitle] = useState("")

  const handleChangePrice = (eventvalue) => {
    setPrice(Number(eventvalue.target.value))
  }

  const handleChangeGame = (eventvalue) => {
    setGame(eventvalue.target.value)
  }

  const handleChangePlatform = (eventvalue) => {
    setPlatform(eventvalue.target.value)
  }

  const handleChangeSpots = (eventvalue) => {
    setAvailableSpots(eventvalue.target.value)
  }

  const handleChangeTitle = (eventvalue) => {
    setTitle(eventvalue.target.value)
  }

  const handleSubmit = (event) => {
    const description = {
      price: Number(price),
      game: game,
      platform: platform,
      num_spots: avaiableSpots,
      title: title,
    }
    //might need to fix later
    console.log(description)
    const addNewGunRunner = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/createmetaract`,
          {
            type: "GUNRUNNER",
            description,
            status: "CREATED",
            communities: [{ communityid: communityContext.id }],
          }
        )
        if (res.data?.message === "Success") {
          console.log("Added new Gun-Runner from Creator: ", res.data)
          toast("Gun Runner Created. Time to queue up with your fans!")
        }
      } catch (error) {
        console.log(error)
        toast("Something went wrong. Please try again.")
      }
    }

    addNewGunRunner()
    gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.INIT)

    metaractShelfContext.setDrawerIsOpen(false)
  }

  return (
    <div className="flex rounded-lg flex-col w-[var(--drawer-width)] h-screen  bg-daresidepanelbg">
      <div className="flex ml-8 mt-8">
        <div className="flex py-1 px-4  bg-dashboardhighlight">
          <p className="text-sm font-ocr">GUNRUNNER</p>
        </div>
      </div>
      <div className="flex mt-24">
        <p className="text-4xl text-dashboardtext font-ocr text-center">
          PLAY with fans
        </p>
      </div>
      <div className="flex mx-8 mt-10">
        <p className="text-base text-dashboardtext font-hk">
          Queue up with your fans and dominate lobbies together. Select the game
        </p>
      </div>
      <div className="flex mx-8 mt-10">
        <p className="text-base text-dashboardtext font-hk">Settings</p>
      </div>
      <input
        type="string"
        placeholder="set game"
        className=" font-ocr mt-8 mx-8 px-2 bg-daresidepanelbg border-dashboardframes border outline-none rounded-lg h-10 text-dashboardtext"
        onChange={handleChangeGame}
      />
      <input
        type="string"
        placeholder="set server"
        className=" font-ocr mt-8 mx-8 px-2 bg-daresidepanelbg border-dashboardframes border outline-none rounded-lg h-10 text-dashboardtext"
        onChange={handleChangePlatform}
      />
      <input
        type="number"
        placeholder="set available spots"
        className=" font-ocr mt-4 mx-8 px-2 bg-daresidepanelbg border-dashboardframes border outline-none rounded-lg h-10 text-dashboardtext"
        onChange={handleChangeSpots}
      />
      <input
        type="number"
        placeholder="set session price"
        className=" font-ocr mt-4 mx-8 px-2 bg-daresidepanelbg border-dashboardframes border outline-none rounded-lg h-10 text-dashboardtext"
        onChange={handleChangePrice}
      />
      <input
        type="string"
        placeholder="set title"
        className=" font-ocr mt-8 mx-8 px-2 bg-daresidepanelbg border-dashboardframes border outline-none rounded-lg h-10 text-dashboardtext"
        onChange={handleChangeTitle}
      />

      <div className="flex justify-center mt-36">
        <button
          onClick={handleSubmit}
          className="bg-buttonblack  hover:bg-dashboardhighlight text-dashboardtext font-ocr py-4 px-6 border-dashboardhighlight border-2 rounded-full"
        >
          LAUNCH GUNRUNNER
        </button>
      </div>
    </div>
  )
}

export default MetaractShelfLaunchGunRunner
