import React, { useContext } from "react"

import { GunRunnerContext } from "../../../../pages/GunRunner/context"    
import SCREENS from "../../../../pages/GunRunner/config/SCREENS"
import darecreatorcontainer from    "./styles/darecontainersvg/containerborder.svg"

const GunRunnerQueueCard = ({ username, platform, price, status, userid, metaractid, gameid, serverid, type }) => {
  const gunRunnerContext = useContext(GunRunnerContext)
  console.log({username, platform, price, status, userid, metaractid, gameid, serverid, type})
  const handleClick = () => {
    if (type === "CREATOR") {
      gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.CREATOR_FAN_DETAILS)
    } else {
      gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.FAN_UPDATE_DETAILS)
    }

    const gunRunnerItem = {
        price: Number(price),
        status: status,
        userid: userid,
        metaractid: metaractid,
        gameid: gameid,
        serverid: serverid,
        userign: username,
        platform: platform
    }
    gunRunnerContext.setGunRunnerItem(gunRunnerItem)
  }

  return (
    <div
      style={{ backgroundImage: `url(${darecreatorcontainer})` }}

      className="flex hover:cursor-pointer justify-start items-center bg-contain bg-no-repeat mt-3 px-5 py-4 bg-center"

    >
      <div className="flex w-8 h-8 border-2 rounded-2xl ml-1 mr-4">

      </div>
      <div>
        <h1 className="font-proxima text-sm text-dashboardtext ">@{username}</h1>
        <p className="font-proxima text-xs text-dashboardalttext ">
          {gameid}
        </p>
      </div>
    </div>
  )
}

export default GunRunnerQueueCard
