import React from "react";
import { Flex } from "rebass";

import LogoMoblabs from "../../../assets/Images/Logo/newlogo.png";

import * as Styled from "./styles";

const BalanceSide = () => {
  return (
    <Styled.Container>
      <Flex alignItems="center" mb="30px">
        <Styled.BackArrow />
        <Styled.Logo src={LogoMoblabs} alt="Moblabs Logo" />
      </Flex>
      <Flex ml="32px" flexDirection="column">
        <Styled.DescriptonText>Pay to start session:</Styled.DescriptonText>
        <Styled.PriceText>$6.00</Styled.PriceText>
        <Styled.DescriptonText>
          Your balance lets you play in a team with some of the best players in
          the world
        </Styled.DescriptonText>
      </Flex>
    </Styled.Container>
  );
};

export default BalanceSide;
