import React, { useContext, useState, useEffect } from "react"
import axios from "axios"
import * as R from "ramda"
import { UsersContext } from "../../../context/users"
import { toast } from "react-toastify"

import { XMarkIcon } from "@heroicons/react/20/solid"
import * as Styled from "./styles"
import { Drawer } from "@mui/material"

const NotificationDropdown = () => {
  const usersContext = useContext(UsersContext)
  const userID = R.pathOr("NA", ["userDBObject", "id"], usersContext)
  const [messages, setMessages] = useState([])
  const [versusDrawerState, setVersusDrawerState] = useState(false)
  const [powerUps, setPowerUps] = useState([])
  const [versusID, setVersusID] = useState("")
  const [selectedMessage, setSelectedMessage] = useState()

  const getMessages = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getusermessages/${userID}`
      )
      setMessages(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const getPowerUps = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuser/${userID}`
      )
      const metaracts = R.pathOr(
        [],
        ["data", "data", "createdcommunity", "metaracts"],
        res
      )
      let mappedmetaracts
      if (metaracts) {
        mappedmetaracts = metaracts
          .map((metaract) => metaract.metaract)
          .filter(
            (metaract) =>
              metaract.type === "VERSUS" && metaract.status === "CREATED"
          )
      }
      let powerups
      let versusid
      if (mappedmetaracts) {
        versusid = mappedmetaracts[0]?.id
        powerups = mappedmetaracts[0]?.versusitems
      }
      setPowerUps(powerups)
      setVersusID(versusid)
    } catch (error) {
      console.log(error)
    }
  }
  const acceptVersus = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${versusID}`,
        { status: "ACTIVE" }
      )
      console.log(res.data, acceptVersus)
      const res2 = await axios.post(
        `${process.env.REACT_APP_SERVER_API}user/updateusermessage/${selectedMessage.id}`,
        { read: true }
      )
      toast.success("Accepted Versus Battle")
    } catch (error) {
      console.log(error)
    }
  }

  const handleVersusInvite = async (message) => {
    if (!message.read) {
      setSelectedMessage(message)
      setVersusDrawerState(true)
      getPowerUps()
    }
  }

  useEffect(() => {
    getMessages()
  }, [])

  return (
    <div>
      <div className="panel top-[50px] right-[100px] z-auto w-[360px] h-[80vh] overflow-scroll">
        <div className="flex justify-between">
          <h1 className="text-dashboardtext text-xl font-proxima">
            Notificatons
          </h1>
          <Styled.MoreIcon className="text-dashboardtext" />
        </div>
        <Styled.Line />
        <div className="flex flex-col items-center bg-cardDetailsBg p-2 rounded-xl ">
          {messages.length &&
            messages.map((message) => {
              return (
                <div
                  onClick={() => {
                    handleVersusInvite(message)
                  }}
                  className="flex flex-col w-full"
                  key={message.id}
                >
                  {message?.read && (
                    <div>
                      <div className="flex justify-between">
                        <h1 className="font-proxima text-md font-bold text-dashboardalttext">
                          {message?.subject}
                        </h1>
                        <p className="font-proxima text-sm text-dashboardalttext">
                          {message?.sendername}
                        </p>
                      </div>
                      <p className="font-proxima text-sm  text-dashboardalttext">
                        {message?.message}
                      </p>
                    </div>
                  )}
                  {!message?.read && (
                    <div>
                      <div className="flex justify-between">
                        <h1 className="font-proxima text-md font-bold text-dashboardtext">
                          {message?.subject}
                        </h1>
                        <p className="font-proxima text-sm text-dashboardtext">
                          {message?.sendername}
                        </p>
                      </div>
                      <p className="font-proxima text-sm  text-dashboardtext">
                        {message?.message}
                      </p>
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      </div>
      <Drawer
        anchor="right"
        open={versusDrawerState}
        onClose={() => {
          setVersusDrawerState(false)
        }}
      >
        <div className="h-screen w-[var(--drawer-width)] bg-daresidepanelbg">
          <div className="flex flex-col justify-between">
            <div className="flex justify-between  mx-8 mt-8 mb-10 ">
              <div className="bg-dashboardhighlight hover:cursor-pointer rounded-sm drop-shadow items-center py-1 px-4 flex justify-center">
                <p className="font-ocr text-sm">VERSUS</p>
              </div>
              <div
                onClick={() => {
                  setVersusDrawerState(false)
                }}
                className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
              >
                <XMarkIcon />
              </div>
            </div>
            {powerUps.map((powerup) => {
              return (
                <div
                  key={powerup.id}
                  className="flex flex-col mt-2 mx-8 py-4 px-4  bg-dashboardbackground rounded-lg"
                >
                  <div className="flex ">
                    <h1 className="font-ocr text-dashboardhighlight">
                      {powerup.rarity}
                    </h1>
                  </div>
                  <div className="flex w-full justify-between mt-2">
                    <h1 className="font-proxima text-dashboardtext">
                      {powerup.description}
                    </h1>
                    <p className="font-proxima text-dashboardhighlight">
                      {powerup.price}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex flex-col bg-daresidepanelbg pb-10 px-16">
          <button
            onClick={() => {
              setVersusDrawerState(false)
              acceptVersus()
            }}
            className="group flex mt-10 py-3 px-4 font-ocr text-xl border border-dashboardhighlight bg-black items-center
            justify-center text-dashboardtext rounded-full
             hover:bg-dashboardhighlight hover:text-black transition ease-in-out"
          >
            <h1>ACCEPT BATTLE</h1>
            <svg
              viewBox="0 0 24 24"
              className="w-6 h-6 stroke stroke-dashboardtext group-hover:stroke-black ml-1"
            >
              <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
            </svg>
          </button>
        </div>
      </Drawer>
    </div>
  )
}

export default NotificationDropdown
