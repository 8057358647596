import React from "react";

import * as Styled from "./styles";

const EditProfileButton = () => {
  return (
    <Styled.Container>
      <Styled.Button>Edit Profile</Styled.Button>
    </Styled.Container>
  );
};

export default EditProfileButton;
