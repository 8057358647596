import styled from "styled-components";

export const Container = styled.div`
  padding: 32px;
  width: 604px;
  height: 776px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;
