import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import * as R from "ramda"
import { toast } from "react-toastify"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { UsersContext } from "../../../../../../../context/users"
import { CommunityContext } from "../../../../../../../context/community"
import { DareContext } from "../../../../../../../pages/Dare/context"

import { Modal } from "@mui/material"
import Wallet from "../../../../../../../components/Wallet"
import { useNavigate } from "react-router-dom"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { SocketContext } from "../../../../../../../context/socket"
import {
  DrawerButton,
  PaymentMethod,
  DrawerInput,
  DrawerDareBox,
  DrawerDareMainInfo,
  DrawerDareDetailsInfo,
  DrawerContainer,
} from "../../../../../Common"

const DareFanDareDetails = () => {
  const usersContext = useContext(UsersContext)
  const communityContext = useContext(CommunityContext)
  const dareContext = useContext(DareContext)

  const [showWalletModal, setShowWalletModal] = useState(false)
  const userID = R.pathOr("NA", ["userDBObject", "id"], usersContext)

  const socket = useContext(SocketContext)

  const navigate = useNavigate()
  const { title, description, price, id, users } = dareContext.dareDetailsDare

  const [newAddPrice, setNewAddPrice] = useState(5.0)
  const [dareBy, setDareBy] = useState("")

  const handleChangePrice = (event) => {
    setNewAddPrice(Number(event.target.value))
  }

  const getDareBy = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuser/${users[0]}`
      )

      const dareBy = R.pathOr("", ["data", "data", "name"], response)

      setDareBy(dareBy)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDareBy()
  }, [])

  const handleSubmit = async () => {
    if (userID === "NA") {
      toast.error("Please login to add to a dare")
      navigate(`/fan-signup/${communityContext.id}`)
      return
    }
    if (Number(newAddPrice) <= 0) {
      toast.error("Minimum Contribution is USD $1.00")
      return
    }
    if (
      usersContext.userDBObject.wallet.total -
        usersContext.userDBObject.wallet.committed -
        Number(newAddPrice) <
      0
    ) {
      toast.error("Not enough balance.")
      setShowWalletModal(true)
      return
    }
    try {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatedare/${id}`,
        {
          price: newAddPrice,
          userid: userID,
          metaractid: metaract.id,
          communityid: communityContext.id,
        }
      )
      toast(
        "Added contribution to dare! Wait to see if the creator picks your dare!"
      )
      console.log(
        `Updated dare Price to ${price} for `,
        id,
        "=>",
        response.data
      )
      if (socket) {
        socket.emit("SEND_DARE_HAS_CHANGED", `dare_${metaract.id}`)
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.error === "InsufficientBalance") {
        toast.error("Not enough balance.")
      }
    } finally {
      dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
    }
  }

  return (
    <DrawerContainer
      back
      onClose={() => {
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
      }}
      title="DARE DETAILS"
      footer={<DrawerButton text="VOTE DARE" onClick={handleSubmit} />}
    >
      {showWalletModal && (
        <Modal
          open={showWalletModal}
          onClose={() => {
            setShowWalletModal(false)
          }}
        >
          <div className="flex h-screen w-screen items-center justify-center">
            <div className="flex flex-col w-[700px] h-[600px] bg-daresidepanelbg rounded-xl ">
              <div className="flex w-full justify-between pt-4 items-center px-4 ">
                <h1 className="font-ocr text-dashboardtext text-xl">Wallet</h1>
                <div
                  onClick={() => {
                    setShowWalletModal(false)
                  }}
                  className="w-10 h-10 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
                >
                  <XMarkIcon />
                </div>
              </div>
              <div className="flex w-full flex-grow">
                <Wallet />
              </div>
            </div>
          </div>
        </Modal>
      )}
      <DrawerDareBox boxTitle="Dare Details">
        <DrawerDareMainInfo title={title} description={description} />
        <DrawerDareDetailsInfo
          dareBy={dareBy}
          price={price}
          usersLength={users?.length}
        />
        {DrawerInput({
          title: "My contribution",
          placeholder: "USD $5.00",
          onChange: handleChangePrice,
          leftLabel: "Min: USD $1.00",
        })}
      </DrawerDareBox>

      <PaymentMethod
        darePrice={Number(newAddPrice)}
        label="Total Due right now"
        hint="This contribution allows you to post the dare. It will be refunded if
        the dare fails to win votes from community members and is not included
        in the clash"
        styles="px-4 sm:px-0"
      />
    </DrawerContainer>
  )
}

export default DareFanDareDetails
