import React, { useContext, useEffect, useRef, useState } from "react"
import * as R from "ramda"
import { CommunityContext } from "../../../context/community"
import { VersusContext } from "../../../pages/Versus/context"
import StreamWindow from "../../StreamWindow"
import BattleInformation from "../BattleInformation"

const StreamContainer = () => {
  const communityContext = useContext(CommunityContext)
  const versusContext = useContext(VersusContext)
  const [selectedcreatorsrc, setSelectedCreatorSrc] = useState("")
  const [creatorsrc, setCreatorSrc] = useState("")
  const [isSelectedCreator, setIsSelectedCreator] = useState()
  const checkIfSelectedCreator = () => {
    if (!versusContext.isLoading) {
      setCreatorSrc(versusContext?.creator?.streamsrc)
      setSelectedCreatorSrc(versusContext?.selectedCreator?.streamsrc)
      if (versusContext?.creator?.id === communityContext?.id) {
        setIsSelectedCreator(false)
      } else {
        setIsSelectedCreator(true)
      }
    }
  }
  useEffect(() => {
    if (versusContext.isLoading) {
    } else {
      checkIfSelectedCreator()
    }
  }, [versusContext.isLoading])
  return (
    <div className="flex justify-center w-full h-5/12 rounded-md">
      <div className="flex px-3 w-3/5">
        <StreamWindow
          src={isSelectedCreator ? selectedcreatorsrc : creatorsrc}
          height="100%"
          width="100%"
          id={"1"}
        />
      </div>
      <div className="px-3 w-2/5 flex flex-start flex-col">
        <StreamWindow
          src={!isSelectedCreator ? selectedcreatorsrc : creatorsrc}
          height="100%"
          width="100%"
          id={"2"}
        />
        <br></br>
        <BattleInformation />
      </div>
    </div>
  )
}

export default StreamContainer
