import React, { useContext } from "react"
import SCREENS from "../../../../pages/Dare/config/SCREENS"
import { DareContext } from "../../../../pages/Dare/context"
import { DrawerButton, DrawerContainer } from "../../Common"
import { ReactComponent as GlowingClashImg } from "../../../../assets/svg/glowing-clash-img.svg"

const CreatorDareLoad = () => {
  const dareContext = useContext(DareContext)
  const handleSubmit = () => {
    dareContext.setClashStarted(true)
    dareContext.setCurrentDrawerOpen(true)
  }

  return (
    <DrawerContainer
      type="dare"
      back
      title="COMPLETE DARES COLLECT THE POT"
      onClose={() => {
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
      }}
      noScroll
      footer={
        <DrawerButton
          text="LAUNCH DARE CLASH"
          onClick={handleSubmit}
          styles="mx-2 z-50"
        />
      }
    >
      <div className="flex mt-6">
        <p className="text-base text-dashboardtext font-hk">
          It's about time! Once you start Clash, you will have 30 seconds to
          select the dare you want to do on stream and get the pot.
        </p>
      </div>

      <div className=" flex justify-center h-[8vh] w-[8vh] mx-auto mt-4 mb-16 ">
        <div className="absolute w-full mt-28 mb-12 mx-auto h-[22vh]">
          <GlowingClashImg />
        </div>
      </div>
    </DrawerContainer>
  )
}

export default CreatorDareLoad
