import { DrawerHeader } from "../Common/drawerHeader"
import { DrawerTitle } from "../Common/drawerTitle"
import { useScreen } from "../../../hooks/useScreen"

export const DrawerContainer = ({
  children,
  onClose,
  type,
  title,
  header,
  footer,
  back,
  noScroll,
  childrenContainerStyle,
}) => {
  const { height, isMobile } = useScreen()
  const heightString = `${height - 56}`
  const Container = { height: `${heightString}px` }

  return (
    <div className="flex flex-col sm:rounded-lg w-screen fixed sm:w-[var(--drawer-width)] h-screen bg-dashboardbackground sm:bg-daresidepanelbg">
      <DrawerHeader type={type || "dare"} onClick={onClose} back={back} />
      <div className="flex flex-col sm:h-[calc(100vh-64px)]" style={Container}>
        {header && <div className="px-6 pt-6 sm:px-10 sm:pt-10">{header}</div>}
        {!isMobile && title && <DrawerTitle title={title} />}
        <div
          className={`flex flex-col px-6 sm:px-10 pb-4 sm:pb-12 gap-y-2 sm:gap-y-4 h-full ${
            !noScroll && " overflow-scroll no-scrollbar"
          } ${childrenContainerStyle && childrenContainerStyle}`}
        >
          {children}
        </div>
        {footer && footer}
      </div>
    </div>
  )
}
