import React from "react"
import * as R from "ramda"
import axios from "axios"
import { useState, useContext } from "react"
import { v4 as uuidv4 } from "uuid"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { MdAddBox } from "react-icons/md"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { Dot } from "../../../../../Common/atoms/Dot"
import { VersusContext } from "../../../../../../pages/Versus/context"
import { CommunityContext } from "../../../../../../context/community"
import { UsersContext } from "../../../../.././../context/users"
import { MetaractShelfContext } from "../../../../../../pages/MetaractShelf/context"
import { bgRarityColors } from "../../../../../../utils/colors"
import { ReactComponent as Edit } from "../../../../../../assets/svg/edit.svg"
import { DrawerButton } from "../../../../Common"
import { capitalizeFirst } from "../../../../../../utils/capitalizeFirst"

const PowerUpItem = ({ powerUp, onEdit }) => (
  <div className="flex mx-4 my-2 py-4 bg-gray-700 bg-opacity-75 rounded-xl drop-shadow-md px-6  justify-between">
    <div className="flex flex-col items-start justify-betwen font-hk">
      <p
        className={`text-dashboardtext text-md font-semibold py-1 px-2 rounded-lg ${
          bgRarityColors[powerUp.rarity]
        }`}
      >
        {powerUp.rarity?.toUpperCase()}
      </p>
      <p className="mt-2 text-dashboardtext text-sm">{powerUp.description}</p>
    </div>
    <div className="flex flex-col">
      <p className="text-xl text-dashboardtext">{`$ ${powerUp.price}`}</p>
      <Edit onClick={onEdit} />
    </div>
  </div>
)

const AddPowerUpForm = ({
  selectedRarity,
  powerUp,
  setPowerUp,
  handleRarityChange,
}) => (
  <div className="flex flex-col p-8 rounded-t-lg shadow-[0_-13px_21px_0_rgba(0,0,0,0.3)] ">
    <h1 className="font-proxima text-lg text-dashboardtext">New Power-Up</h1>
    <textarea
      name="powerup"
      placeholder="Describe your Power-Up"
      value={powerUp.description}
      onChange={(event) => {
        setPowerUp({ ...powerUp, description: event.target.value })
      }}
      className="border-dashboardtext text-dashboardtext font-hk bg-daresidepanelbg border h-20 mt-6 rounded-lg"
    ></textarea>

    <div className="mt-4 flex justify-between">
      <div className="flex font-proxima text-dashboardtext items-center">
        <p>Rarity</p>
        <div className="ml-4 font-proxima text-dashboardtext">
          <Select
            id="cardrarityselect"
            value={selectedRarity}
            label="rarity"
            onChange={(event) => handleRarityChange(event)}
            sx={{ height: 42, borderRadius: 2 }}
            inputProps={{
              MenuProps: {
                MenuListProps: { sx: { backgroundColor: "#202528" } },
              },
            }}
            className="border border-dashboardtext font-hk outline-none hover:outline-none focus:outline-none"
          >
            {["common", "rare", "epic"].map((rar) => (
              <MenuItem className="bg-daresidepanelbg " value={rar}>
                <p className="text-dashboardtext">{capitalizeFirst(rar)}</p>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex font-proxima text-dashboardtext items-center">
        <p>Price</p>
        <div className="flex ml-4">
          <input
            name="title"
            placeholder="USD $2.00"
            value={powerUp.price}
            onChange={(event) => {
              setPowerUp({ ...powerUp, price: event.target.value })
            }}
            className="border border-dashboardframes outline-none text-dashboardtext bg-transparent font-proxima rounded-lg py-2 px-2 items-center justify-center"
          ></input>
        </div>
      </div>
    </div>
  </div>
)

const PowerUps = () => {
  const [showAddFormState, setShowAddFormState] = useState(false)
  const [powerUps, setPowerUps] = useState([])
  const versusContext = useContext(VersusContext)
  const communityContext = useContext(CommunityContext)
  const usersContext = useContext(UsersContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const [selectedRarity, setSelectedRarity] = useState("")
  const [powerUp, setPowerUp] = useState({})

  const userID = R.pathOr("NA", ["userDBObject", "id"], usersContext)

  const handleChange = (event) => {
    setSelectedRarity(event.target.value)
    setPowerUp({ ...powerUp, rarity: event.target.value })
  }
  const handleAddPowerUp = () => {
    if (powerUps.find((x) => x.id === powerUp.id)) {
      const newPowerUps = powerUps.map((item, i) => {
        if (item.id === powerUp.id) {
          return powerUp
        } else {
          return item
        }
      })
      setPowerUps(newPowerUps)
    } else {
      setPowerUps([...powerUps, powerUp])
    }
    setShowAddFormState(false)
  }
  const handleAddVersus = async () => {
    try {
      const resmetaract = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/createmetaract`,
        {
          type: "VERSUS",
          description: {
            title: versusContext.setupState.battletitle,
            minprice: versusContext.setupState.minprice,
            selectedcreator: versusContext.setupState.selectedCreator.id,
            creator: communityContext.id,
          },
          status: "CREATED",
          communities: [
            { communityid: communityContext.id },
            { communityid: versusContext.setupState.selectedCreator.id },
          ],
        }
      )
      console.log(resmetaract.data)
      const metaractid = resmetaract.data.data.id
      if (metaractid) {
        powerUps.map(async (powerUp) => {
          let respowerup = await axios.post(
            `${process.env.REACT_APP_SERVER_API}versus/createversusitem`,
            {
              title: "title",
              status: "ACTIVE",
              userid: userID,
              metaractid: metaractid,
              description: powerUp.description,
              rarity: powerUp.rarity,
              price: powerUp.price,
            }
          )
          console.log(respowerup.data, "respowerup")
        })
      }

      const messageres = await axios.post(
        `${process.env.REACT_APP_SERVER_API}user/createusermessage`,
        {
          subject: `Versus Invite from ${usersContext.userDBObject.name}`,
          message: `Please click this message to start the Versus Battle with the creator`,
          receiverid: versusContext.setupState.selectedCreator.creator.id,
          sender: usersContext.userDBObject.id,
          sendername: usersContext.userDBObject.name,
        }
      )
      console.log(messageres.data, "added message")
    } catch (error) {
      console.log(error)
    } finally {
      versusContext.setNewVersusDrawerOpenState(false)
      metaractShelfContext.setDrawerIsOpen(false)
    }
  }
  return (
    <div className="flex flex-col justify-between h-screen bg-daresidepanelbg">
      <div className="flex flex-col">
        <div className="flex justify-between  mx-8 mt-8 mb-10 ">
          <div className="bg-dashboardhighlight hover:cursor-pointer rounded-sm drop-shadow items-center py-1 px-4 flex justify-center">
            <p className="font-ocr text-sm">VERSUS</p>
          </div>
          <div
            onClick={() => {
              versusContext.setNewVersusDrawerOpenState(false)
              metaractShelfContext.setDrawerIsOpen(false)
            }}
            className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>
        <div className="flex justify-between items-center mx-8">
          <div className="flex flex-col">
            <h1 className="font-ocr text-dashboardtext text-2xl">
              BATTLE POWER-UPS
            </h1>
            <div className="flex items-center my-2">
              <p className="font-hk text-teal-300 text-sm">0 COMMON</p>
              <Dot />
              <p className="font-hk text-orange-400 text-sm">0 RARE</p>
              <Dot />
              <p className="font-hk text-purple-600 text-sm">0 EPIC</p>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                setPowerUp({ id: uuidv4() })
                setShowAddFormState(true)
              }}
              className="flex gap-1.5 px-3 py-2 border font-proxima text-dashboardtext text-sm rounded-xl border-dashboardtext items-center"
            >
              <MdAddBox className="w-7 h-7" />
              Add Power-Up
            </button>
          </div>
        </div>
        <div className="flex flex-col mx-2">
          {powerUps.map((powerUp) => {
            return (
              <PowerUpItem
                powerUp={powerUp}
                key={powerUp.id}
                onEdit={() => {
                  setPowerUp(powerUp)
                  setShowAddFormState(true)
                }}
              />
            )
          })}
        </div>
      </div>
      <div className="flex flex-col">
        {showAddFormState && (
          <AddPowerUpForm
            selectedRarity={selectedRarity}
            powerUp={powerUp}
            setPowerUp={setPowerUp}
            handleRarityChange={handleChange}
          />
        )}
        {showAddFormState ? (
          <div className="flex justify-between">
            <DrawerButton
              text="CANCEL"
              onClick={() => setShowAddFormState(false)}
              styles="mx-8"
            />
            <DrawerButton
              text="ADD POWER UP"
              onClick={handleAddPowerUp}
              disabled={
                !(!!powerUp.price && !!powerUp.description && !!powerUp.price)
              }
              styles="mx-8"
            />
          </div>
        ) : (
          <DrawerButton
            text="CONFIRM SETUP"
            onClick={() => {
              handleAddVersus()
              versusContext.setCurrentNewVersusScreensState(
                versusContext.newVersusScreens.LOADING
              )
            }}
            disabled={!powerUps.length}
          />
        )}
      </div>
    </div>
  )
}

export default PowerUps
