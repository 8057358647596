import React, { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Menu, Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import MetaractShelfDropdown from "../MetaractShelfDropdown"
import MetaractShelfGridCard from "./MetaractShelfGridCard"

const sortOptions = [
  { name: "Most Popular", href: "#" },
  { name: "Best Rating", href: "#" },
  { name: "Newest", href: "#" },
  { name: "Price: Low to High", href: "#" },
  { name: "Price: High to Low", href: "#" },
]
const filters = [
  {
    id: "status",
    name: "Status",
    options: [
      { value: "created", label: "Created" },
      { value: "completed", label: "Completed" },
      { value: "Active", label: "Active" },
    ],
  },
  {
    id: "type",
    name: "Type",
    options: [
      { value: "dare", label: "Dare" },
      { value: "versus", label: "Versus Battle" },
      { value: "gun-runner", label: "Gun Runner" },
    ],
  },
  {
    id: "category",
    name: "Category",
    options: [
      { value: "gaming", label: "Gaming" },
      { value: "black", label: "Black" },
      { value: "grey", label: "Grey" },
      { value: "blue", label: "Blue" },
      { value: "olive", label: "Olive" },
      { value: "tan", label: "Tan" },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function MetaractShelfGrid({ metaracts, id, earnings }) {
  const navigate = useNavigate()
  const handlenavigate = () => {
    navigate(`/metaractshelfanalytics/${id}`)
  }
  return (
    <div className="bg-dashboardbackground w-full ">
      <div>
        <main>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="py-10 text-center">
              <h1 className="text-4xl font-bold tracking-tight font-proxima text-dashboardtext">
                Metaract Shelf
              </h1>
              <div className="flex justify-center items-center">
                <button
                  className="text-md font-bold bg-dashboardhighlight font-proxima text-black py-2 px-4 rounded-md"
                  onClick={handlenavigate}
                >
                  Analytics
                </button>
                <p className="text-lg ml-5 font-bold font-proxima text-dashboardhighlight">{`Earnings: $${earnings}`}</p>
              </div>
              <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
                Browse the collection of your metaracts.
              </p>
            </div>
            <div className="my-5">
              <MetaractShelfDropdown id={id} />
            </div>
            {/* Filters */}

            {/* Product grid */}
            <section aria-labelledby="products-heading" className="mt-8">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 ">
                {metaracts &&
                  metaracts.map((metaract, index) => (
                    <MetaractShelfGridCard
                      metaract={metaract}
                      id={id}
                      key={index}
                    />
                  ))}
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  )
}
