import React, { useState } from "react";
import { Flex } from "rebass";

import Title from "../../Common/SideTextPanel/Title";
import Input from "../../Common/Input";
import Checkbox from "../../Common/Checkbox";
import SubmitButton from "../../Authentication/SubmitButton";
import TextArea from "../../Common/TextArea";
import useContactUs from "../../../hooks/useContactUs";

import * as Styled from "./styles";

const ContactForm = () => {
  const contactUsHook = useContactUs();
  const [formState, setFormState] = useState({
    email: "",
    message: "",
    emailMe: false,
  });

  return (
    <Styled.Container>
      <Title>Contact Moblabs</Title>
      <Flex mt="64px" flexDirection="column" alignItems="flex-start">
        <Flex mb="32px">
          <Input
            label="Email"
            placeholder="your@email.com"
            value={formState.email}
            onChange={(value) =>
              setFormState({
                ...formState,
                email: value,
              })
            }
          />
        </Flex>
        <Flex mb="32px">
          <TextArea
            label="Message"
            placeholder="Type your message here"
            value={formState.message}
            onChange={(value) =>
              setFormState({
                ...formState,
                message: value,
              })
            }
          />
        </Flex>
        <Flex mb="32px">
          <Checkbox
            label="Email me about Moblabs product updates"
            value={formState.emailMe}
            onChange={(value) =>
              setFormState({
                ...formState,
                emailMe: value,
              })
            }
          />
        </Flex>
        <SubmitButton
          onClick={() => contactUsHook.submitForm(formState)}
          text="Send Message"
        />
      </Flex>
    </Styled.Container>
  );
};

export default ContactForm;
