import React, { useContext, useEffect, useState } from "react"
import { Flex } from "rebass"
import { SocketContext } from "../../../context/socket"

import { VersusContext } from "../../../pages/Versus/context"
import { ImgAvatar } from "../../Common/atoms/ImgAvatar"
import lightning from "../../../assets/Images/lightning.png"

import * as Styled from "./styles"

const BattleFeed = () => {
  const versusContext = useContext(VersusContext)

  const getDescriptionByType = (battleFeedItem) => {
    switch (battleFeedItem.type) {
      case "FAN_DONATED_MONEY":
        return `contributed ${battleFeedItem.amount} to unlock a new Power-Up`
      case "USED_POWER_UP":
        return `${battleFeedItem.description}`
      default:
        return ""
    }
  }

  const getTitleByType = (battleFeedItem) => {
    switch (battleFeedItem.type) {
      case "FAN_DONATED_MONEY":
        return `@${battleFeedItem.username}`
      case "USED_POWER_UP":
        return `${battleFeedItem.username} used a Power-Up.`
      default:
        return ""
    }
  }

  return (
    <div className="flex flex-col w-[300px] h-screen bg-dashboardboxbackground opacity-80 p-5 rounded-xl">
      <h1 className="font-proxima text-dashboardtext font-semibold text-lg">
        Battle Feed
      </h1>
      <Styled.Divider />
      <div className="flex flex-col overflow-y-scroll ">
        {versusContext.battleFeed.map((battleFeedItem, index) => {
          return (
            <div key={battleFeedItem.id} className="flex">
              <div className="flex my-2 w-full items-begin bg-black p-4 rounded-md flex-col text-dashboardtext">
                <div className="flex items-center ">
                  {battleFeedItem.imgurl ? (
                    <ImgAvatar
                      alt="creator profile"
                      src={battleFeedItem.imgurl || lightning}
                      styles="w-6 h-6 mr-2.5"
                    />
                  ) : (
                    <Styled.SmallAvatar />
                  )}
                  <p className="font-hk text-base font-bold ">
                    {getTitleByType(battleFeedItem)}
                  </p>
                </div>
                <p className="font-hk mt-2 text-sm ">
                  {getDescriptionByType(battleFeedItem)}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BattleFeed
