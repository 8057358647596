import React from "react"
import { CardTitle } from "../../Common/atoms/CardTitle"
import DiscoverChips from "../../Dashboard/DiscoverChips"

const AboutCommunity = ({ description }) => {
  return (
    <div className="bg-dashboardboxbackground shadow-2xl w-fit lg:w-[600px] xl:w-[680px] px-6 py-4 rounded-lg bg-opacity-60 flex flex-col">
      <CardTitle text="About" />
      <div className="flex mt-4 flex-col gap-4 sm:flex-row">
        <DiscoverChips type="Twitch"></DiscoverChips>
        <DiscoverChips type="Just Chatting"></DiscoverChips>
        <DiscoverChips type="Gaming"></DiscoverChips>
      </div>
      <p className="font-proxima text-lg text-dashboardtext mt-6">
        {description}
      </p>
    </div>
  )
}

export default AboutCommunity
