import React from "react"
import StripeContainer from "../../../StripeContainer"

const WalletPayment = ({ amountID }) => {
  return (
    <div className="flex flex-col w-full h-full items-center justify-center ">
      <div className="flex text-dashboardtext pb-5 text-lg">
        Test Credit Card Number : 4242424242424242, other cards will not work.
      </div>

      <StripeContainer itemid={amountID} />
    </div>
  )
}

export default WalletPayment
