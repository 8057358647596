import React, { useContext, useEffect, useState } from "react"
import * as R from "ramda"
import { DareContext } from "../../../../../../../pages/Dare/context"

import FanLiveDarePickedDare from "../../../../FanLiveDarePickedDare"
import FanDareLive from "../DareLive"

const DareFanLiveDare = () => {
  const dareContext = useContext(DareContext)
  const [pickdareid, setPickDareId] = useState("")
  const getPickDare = () => {
    const id = R.pathOr("", ["dare", "description", "pickdare"], dareContext)
    console.log(id, "pickdareid", pickdareid !== "")
    setPickDareId(id)
  }

  useEffect(() => {
    getPickDare()
  }, [dareContext.dare])

  const renderScreen = () => {
    if (pickdareid !== "") {
      return <FanLiveDarePickedDare dareitemid={pickdareid} />
    } else {
      return <FanDareLive />
    }
  }
  return <div date-testid="DareFanLiveDare">{renderScreen()}</div>
}

export default DareFanLiveDare
