import { ReactComponent as DareIcon } from "../../../assets/svg/dare-icon.svg"
import { useScreen } from "../../../hooks/useScreen"

export const ClashIsLiveBanner = ({ title1, title2 }) => {
  const { isMobile } = useScreen()

  const DareItem = ({ title }) => (
    <div className="flex h-14 w-24 justify-center items-center ">
      <p className="px-2 line-clamp-1 sm:line-clamp-2">{title || ""}</p>
    </div>
  )
  return (
    <div className="flex flex-col p-1 sm:p-4 border-2 sm:border-4 rounded-md sm:rounded-lg border-dashboardhighlight justify-center items-center sm:font-ocr text-dashboardhighlight ">
      {!isMobile && <h1 className="text-xl mb-2">CLASH IS LIVE</h1>}
      <div className="flex items-center justify-between  px-4 w-full">
        <DareItem title={title1} />
        <DareIcon className="text-sm" width={isMobile ? 40 : null} />
        <DareItem title={title2} />
      </div>
      {/* {isMobile && <p>View Countdown</p>} */}
    </div>
  )
}
