import { useScreen } from "../../../hooks/useScreen"
import { InfoLine } from "./infoLine"

export const DrawerDareDetailsInfo = ({ dareBy, price, usersLength }) => {
  const { isMobile } = useScreen()
  return (
    <div className="flex flex-col bg-cardDetailsBg py-2 px-4 rounded-lg my-3 sm:my-4 ">
      <InfoLine infoKey="Dare By" value={dareBy || "-"} />
      <InfoLine
        infoKey={
          isMobile
            ? "Community contributions"
            : "Contributed to pot by community"
        }
        value={`USD $${Math.round(price).toLocaleString("en-US")}`}
      />
      <InfoLine infoKey="Contributors" value={usersLength || "-"} />
    </div>
  )
}
