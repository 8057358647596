import React from "react"

import Jumbotron from "../../components/Dashboard/Jumbotron"
import TrendingCommunities from "../../components/Dashboard/TrendingCommunities"
// import DiscoverMetaracts from "../../components/Dashboard/DiscoverMetaracts"

import SideBar from "../../components/Navigation/SideBar"

const Dashboard = () => {
  return (
    <div className="flex bg-dashboardbackground  w-screen min-h-[calc(100vh+50px)]">
      <div className="w-1/12 flex">
        <SideBar />
      </div>
      <div className="w-11/12 pt-10 pl-12 pb-16 flex-col justify-start items-start bg-dashboardbackground text-dashboardtext">
        <Jumbotron />
        <TrendingCommunities />
        {/* <DiscoverMetaracts /> */}
      </div>
    </div>
  )
}

export default Dashboard
