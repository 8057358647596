import React from "react";

import * as Styled from "./styles";

const Input = ({label, placeholder, value, onChange, type, width }) => {

  return (
    <div>
      {label && <Styled.Label>{label}</Styled.Label>}
      <Styled.Input
        width={width}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        type={type || "text"}
      />
    </div>
  );
};

export default Input;
