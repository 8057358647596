import styled from "styled-components";

export const Checkbox = styled.input`
  width: 24px;
  height: 24px;
  border: 2px solid #E6E6EB;
  border-radius: 6px;
  margin-right: 16px;
`; // need to change the color of the checkbox 

export const Label = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #E6E6EB;
`;
