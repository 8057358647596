import React, { useContext } from "react"
import Modal from "@mui/material/Modal"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { DareContext } from "../../../../pages/Dare/context"
const DareTutorialModal = () => {
  const dareContext = useContext(DareContext)
  const handleClose = () => {
    dareContext.setDareTutorialModal(false)
  }

  return (
    <Modal
      open={dareContext.dareTutorialModal}
      onClose={handleClose}
      aria-labelledby="daretutorialmodal"
      aria-describedby="daretutorialmodal"
    >
      <div className="flex w-screen h-screen justify-center items-center bg-transparent ">
        <div className="flex flex-col justify-center px-10 bg-dashboardbackground">
          <div className="flex w-full justify-end mt-4 mr-4">
            <div
              onClick={handleClose}
              className="w-10 h-10 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
            >
              <XMarkIcon />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex flex-col h-full py-5 mx-10 w-56 ">
              <p className="font-proxima text-dashboardtext text-md mb-2">
                Step one.
              </p>
              <div
                style={{
                  backgroundImage: `url(https://moblabs-s3-1.s3.us-west-1.amazonaws.com/tut2.png)`,
                }}
                className=" h-96 bg-cover bg-no-repeat bg-center"
              ></div>
              <div className="justify-center items-center mt-2">
                <p className=" py-2 text-ocr text-md items-center justify-center text-dashboardtext">
                  Contribute to Dare or add a new Dare!
                </p>
              </div>
            </div>
            <div className="flex flex-col h-full py-5 mx-10 w-56 ">
              <p className="font-proxima text-md text-dashboardtext mb-2">
                Step two.
              </p>
              <div
                style={{
                  backgroundImage: `url(https://moblabs-s3-1.s3.us-west-1.amazonaws.com/tut1.png)`,
                }}
                className=" h-96 bg-cover bg-no-repeat bg-center"
              ></div>
              <div className="justify-center items-center mt-2">
                <p className=" py-2 text-ocr text-md items-center justify-center text-dashboardtext">
                  See if the creator picks your Dare!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DareTutorialModal
