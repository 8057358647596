import React from "react";

import * as Styled from "./styles";

const CoverImage = () => {
  return (
    <Styled.Container>
      <Styled.ChangeImageButton>Change cover</Styled.ChangeImageButton>
      <Styled.CustomAvatar />
    </Styled.Container>
  );
};

export default CoverImage;
