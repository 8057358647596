import React, {useContext, useState, useEffect} from "react";
import axios from "axios";

import { MetaractShelfContext } from "../../../../pages/MetaractShelf/context";
import SCREENS from "../../../../pages/MetaractShelf/config/SCREENS";
import energy_icon from "../../../../assets/Images/GunRunner/gunrunnercard_energy.svg";

const GunRunnerCard = ({gunrunneritem}) => {

  const metaractShelfContext = useContext(MetaractShelfContext);
  const [cardUser, setCardUser] = useState([])
  const getUser = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuser/${gunrunneritem.userid}`
      )
      console.log(res, "res")

      setCardUser(res.data.data)
      console.log(cardUser, "cardUser")
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  console.log(gunrunneritem, "gunrunneritem2")
  return (
    <div className="flex flex-col w-fit bg-dashboardboxbackground border-4 border-gunrunnercardborder rounded-xl shadow z-50">
      <div
              style={{
                backgroundImage: `url(${cardUser.profilepictureurl})`,
              }}t
              className="w-32 h-32 bg-transparent bg-cover bg-center bg-no-repeat flex z-0 "
            >
      </div>
      <div className="flex flex-col justify-center items-center text-dashboardtext bg-dashboardboxbackground">
        <h2 className = "font-ocr text-sm pt-1 capitalize">{gunrunneritem.userign}</h2>
        <hr class="w-11/12 h-1 bg-gunrunnercardborder rounded-lg "/>
      </div>
      <div className="flex flex-col my-1 justify-end text-dashboardtext bg-dashboardboxbackground">
        <img src={energy_icon}  className="h-3"/>
      </div>
      
    </div>
  );
};

export default GunRunnerCard;
