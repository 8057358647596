import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import * as R from "ramda"
import io from "socket.io-client"
import { toast } from "react-toastify"
import { XMarkIcon } from "@heroicons/react/20/solid"

import { VersusContext } from "../../../pages/Versus/context"

import { CommunityContext } from "../../../context/community"
import { Drawer, Modal } from "@mui/material"
import { UsersContext } from "../../../context/users"
import { SocketContext } from "../../../context/socket"
import Wallet from "../../Wallet"
import { PowerUpCard } from "./PowerUpCard"

const Line = () => (
  <div className="mt-5  w-full px-10 border-dashboardalttext border text-dashboardhighlight flex"></div>
)

const PowerUpCards = () => {
  const communityContext = useContext(CommunityContext)
  const versusContext = useContext(VersusContext)
  const usersContext = useContext(UsersContext)
  const socket = useContext(SocketContext)
  const [isSelectedCreator, setIsSelectedCreator] = useState()
  const [powerUpCards, setPowerUpCards] = useState([])
  const [selectedPowerUp, setSelectedPowerUp] = useState()
  const [contribution, setContribution] = useState(2)

  const [showWalletModal, setShowWalletModal] = useState(false)
  const navigate = useNavigate()
  const userID = R.pathOr("NA", ["userDBObject", "id"], usersContext)
  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext)
  const handleChangePrice = (e) => {
    setContribution(e.target.value)
  }
  const handleUsePowerUp = async (powerUp) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}versus/updateversusitem/${powerUp.id}`,
        {
          selected: isSelectedCreator,
          used: true,
        }
      )
      console.log(res.data.data, "=> Used Power Up Fired")
      if (socket !== undefined) {
        socket.emit("SEND_USED_POWER_UP", {
          username: usersContext.userDBObject.name,
          type: "USED_POWER_UP",
          description: powerUp.description,
          room: `versus_${versusContext.versus.id}`,
          imgurl: usersContext.userDBObject.profilepictureurl,
        })
        socket.emit(
          "SEND_VERSUS_HAS_CHANGED",
          `versus_${versusContext.versus.id}`
        )
      }
      toast.success("Power Up Used!")
    } catch (error) {
      console.log(error)
    }
  }
  const handleSubmitAdd = async () => {
    if (
      isSelectedCreator
        ? selectedPowerUp.contributionsselected + Number(contribution) >
          selectedPowerUp.price
        : selectedPowerUp.contributionscreator + Number(contribution) >
          selectedPowerUp.price
    ) {
      toast.error("You can't contribute more than the price of the power up!!")
      return
    }
    if (userID === "NA") {
      toast.error("Please login to add to a dare")
      navigate("/fan-signup")
      return
    }
    if (
      usersContext.userDBObject.wallet.total -
        usersContext.userDBObject.wallet.committed -
        Number(contribution) <
      0
    ) {
      toast.error("Not enough balance.")
      setShowWalletModal(true)
      return
    }
    try {
      const unlocked = isSelectedCreator
        ? selectedPowerUp.contributionsselected + Number(contribution) >=
          selectedPowerUp.price
        : selectedPowerUp.contributionscreator + Number(contribution) >=
          selectedPowerUp.price

      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/addcontribution/${selectedPowerUp.id}`,
        {
          userid: userID,
          contribution: contribution,
          selected: isSelectedCreator,
          unlocked: unlocked,
          communityid: communityContext.id,
        }
      )
      console.log(res.data.data, "=> Added Contribution Fired")
      if (socket !== undefined) {
        socket.emit("SEND_FAN_DONATED_MONEY", {
          username: usersContext.userDBObject.name,
          type: "FAN_DONATED_MONEY",
          amount: contribution,
          room: `versus_${versusContext.versus.id}`,
          imgurl: usersContext.userDBObject.profilepictureurl,
        })
        console.log(
          "EVENT=>SEND_FAN_DONATED_MONEY",
          `versus_${versusContext.versus.id}`
        )
        socket.emit(
          "SEND_VERSUS_HAS_CHANGED",
          `versus_${versusContext.versus.id}`
        )
        console.log(
          "EVENT=>SEND_VERSUS_HAS_CHANGED",
          `versus_${versusContext.versus.id}`
        )
        toast.success("Contribution Added!")
      }
    } catch (error) {
      console.log(error)

      if (error?.response?.data?.error === "InsufficientBalance") {
        toast.error("Not enough balance.")
      }
    } finally {
      versusContext.setBattleDrawerOpen(false)
    }
  }

  // TODO: use useMemo to read selected creator
  const checkIfSelectedCreator = () => {
    if (!versusContext.isLoading) {
      if (versusContext?.creator?.id === communityContext?.id) {
        setIsSelectedCreator(false)
      } else {
        setIsSelectedCreator(true)
      }
    }
  }
  const getPowerUps = () => {
    if (!versusContext.isLoading) {
      const powerups = R.pathOr([], ["versusItems"], versusContext)
      const sorted = powerups.sort((a, b) => {
        if (isSelectedCreator) {
          return b.contributionsselected - a.contributionsselected
        }
        return b.contributionscreator - a.contributionscreator
      })
      setPowerUpCards(sorted)
    }
  }

  useEffect(() => {
    if (!versusContext.isLoading) {
      checkIfSelectedCreator()
      getPowerUps()
    }
  }, [versusContext.isLoading])

  return (
    <div>
      {showWalletModal && (
        <Modal
          open={showWalletModal}
          onClose={() => {
            setShowWalletModal(false)
          }}
        >
          <div className="flex h-screen w-screen items-center justify-center">
            <div className="flex flex-col w-[700px] h-[600px] bg-daresidepanelbg rounded-xl ">
              <div className="flex w-full justify-between pt-4 items-center px-4 ">
                <h1 className="font-ocr text-dashboardtext text-xl">Wallet</h1>
                <div
                  onClick={() => {
                    setShowWalletModal(false)
                  }}
                  className="w-10 h-10 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
                >
                  <XMarkIcon />
                </div>
              </div>
              <div className="flex w-full flex-grow">
                <Wallet />
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="flex w-full justify-start gap-10">
        {powerUpCards.map((powerUp) => {
          return (
            <PowerUpCard
              key={powerUp.id}
              powerUp={powerUp}
              isSelectedCreator={isSelectedCreator}
              isUserCreator={
                userType === "CREATOR" &&
                usersContext.userDBObject.id === communityContext.creator.id
              }
              handleUsePowerUp={handleUsePowerUp}
              setSelectedPowerUp={setSelectedPowerUp}
              setBattleDrawerOpen={versusContext.setBattleDrawerOpen}
            />
          )
        })}
      </div>
      <Drawer
        anchor="right"
        open={versusContext.battleDrawerOpen}
        onClose={() => versusContext.setBattleDrawerOpen(false)}
        PaperProps={{ sx: { backgroundColor: "transparent" } }}
      >
        <div className="flex rounded-lg flex-col w-[380px] h-screen  bg-daresidepanelbg px-7">
          <div className="flex justify-between mt-8 mb-10 ">
            <div className="flex justify-center bg-dashboardhighlight hover:cursor-pointer rounded-sm drop-shadow items-center pb-1 pt-1.5 px-4 ">
              <p className="font-ocr text-sm">VERSUS</p>
            </div>
            <div
              onClick={() => {
                versusContext.setBattleDrawerOpen(false)
              }}
              className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
            >
              <XMarkIcon />
            </div>
          </div>
          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex justify-center items-center mt-5 mb-10">
              <h1 className="font-ocr text-2xl text-dashboardtext">
                ADD A CONTRIBUTION
              </h1>
            </div>
            <div className="flex flex-col w-full items-start justify-center ">
              <p className="font-proxima text-sm text-dashboardtext">
                {selectedPowerUp?.description}
              </p>
              <Line />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-col px-2 rounded-xl mt-6 py-2 bg-gray-700">
                <div className="flex justify-between px-2 ">
                  <p className="font-proxima text-dashboardtext text-sm">
                    {isSelectedCreator
                      ? `${versusContext?.selectedCreator?.name}'s Contributions`
                      : `${versusContext?.creator?.name}'s Contributions`}
                  </p>
                  <h1 className="font-ocr text-md text-dashboardhighlight">{`$${
                    Number(contribution) +
                    Number(
                      isSelectedCreator
                        ? selectedPowerUp?.contributionsselected
                        : selectedPowerUp?.contributionscreator
                    )
                  }/$${selectedPowerUp?.price}`}</h1>
                </div>
                <div className="flex justify-between px-2">
                  <p className="font-proxima text-dashboardtext text-sm">
                    {!isSelectedCreator
                      ? `${versusContext?.selectedCreator?.name}'s Contributions`
                      : `${versusContext?.creator?.name}'s Contributions`}
                  </p>
                  <h1 className="font-ocr text-md text-dashboardhighlight">{`$${
                    !isSelectedCreator
                      ? selectedPowerUp?.contributionsselected
                      : selectedPowerUp?.contributionscreator
                  }/$${selectedPowerUp?.price}`}</h1>
                </div>
              </div>
            </div>
            <div className="flex flex-col  w-full justify-center  mt-2">
              <div className="flex justify-between items-center mt-6 mb-3 text-base">
                <h2 className="font-proxima  text-dashboardtext font-semibold">
                  My contribution
                </h2>
                <h3 className="font-proxima text-dashboardalttext">
                  Min: USD $2.00
                </h3>
              </div>
              <input
                name="title"
                type="number"
                placeholder="USD $0.00"
                min={selectedPowerUp?.min}
                max={selectedPowerUp?.max}
                onChange={handleChangePrice}
                className="border border-dashboardframes focus:border-dashboardhighlight outline-none text-dashboardframes  bg-transparent font-proxima rounded-lg py-2 w-full px-2 items-center justify-center flex"
              ></input>
              <Line />

              <div className="flex w-full mt-6 mb-3 justify-between items-center text-base text-dashboardtext ">
                <h2 className="font-proxima font-semibold">Payment method</h2>
                <h2 className="font-proxima">Wallet</h2>
              </div>
              <div className="flex w-full justify-between items-center border-dashboardframes rounded-lg border py-2 px-2">
                <h2 className="font-proxima text-md text-dashboardalttext">
                  Total due right now
                </h2>
                <h2 className="font-proxima text-md text-dashboardhighlight">
                  {`USD ${Math.round(contribution).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
                </h2>
              </div>
              <p className=" mt-2 text-dashboardalttext text-xs">
                This contribution allows you to post the dare. It will be
                refunded if the dare fails to win votes from community members
                and is not included in the clash
              </p>
            </div>

            <button
              onClick={handleSubmitAdd}
              className="group flex mt-10 pb-2 pt-3 px-5 font-ocr text-base border-2 border-dashboardhighlight bg-black items-center
            justify-center text-dashboardtext rounded-full
             hover:bg-dashboardhighlight hover:text-black transition ease-in-out"
            >
              <h1>ADD CONTRIBUTION</h1>
              <svg
                viewBox="0 0 24 24"
                className="w-6 h-6 stroke stroke-dashboardtext group-hover:stroke-black ml-1"
              >
                <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
              </svg>
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default PowerUpCards
