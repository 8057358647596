import styled from "styled-components";

export const HelperText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #536471;
`;
