import React from "react"

const SideTextPanel = () => {
  return (
    <div className="bg-dashboardboxbackground h-full w-80 px-8 py-20 rounded-lg drop-shadow flex flex-col ">
      <h1 className="font-ocr text-4xl text-dashboardhighlight">Patch.</h1>
      <h1 className="font-ocr text-xl text-dashboardhighlight">
        Shopify for creators.{" "}
      </h1>
    </div>
  )
}

export default SideTextPanel
