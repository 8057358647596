import React from "react"
import { IoLogoGameControllerB } from "react-icons/io"
import { IoMdMicrophone } from "react-icons/io"
import { VscSymbolEvent } from "react-icons/vsc"

const discoverIcons = {
  Gaming: <IoLogoGameControllerB className="fill-dashboardhighlight text-xl" />,
  "Just Chatting": (
    <IoMdMicrophone className="fill-dashboardhighlight text-xl" />
  ),
  Twitch: <VscSymbolEvent className="fill-dashboardhighlight text-xl" />,
}

const DiscoverChips = ({ type, onClick }) => {
  return (
    <div className="flex items-center">
      <button
        onClick={onClick}
        className="border flex justify-center items-center border-dashboardhighlight rounded-full py-2.5 px-5 gap-1.5 text-xs text-dashboardhighlight "
      >
        <p className="fill-dashboardhighlight">{discoverIcons[type]}</p>
        <p className="text-base leading-5">{type}</p>
      </button>
    </div>
  )
}

export default DiscoverChips
