import React from "react";
import { Flex } from "rebass";

import Card from "../../Common/Card";
import Input from "../../Common/Input";
import SubmitButton from "../../Authentication/SubmitButton";

const PasswordChange = () => {
  return (
    <div>
      <Card>
        <Flex mb="32px">
          <Input label="Old password" />
        </Flex>
        <Flex mb="32px">
          <Input label="New password" />
        </Flex>
        <Flex mb="32px">
          <Input label="Confirm password" />
        </Flex>
        <Flex>
          <SubmitButton text="Update Password" />
        </Flex>
      </Card>
    </div>
  );
};

export default PasswordChange;
