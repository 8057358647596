import { AuthContainer } from "../../components/Authentication/AuthContainer"
import SignInForm from "../../components/Authentication/SignInForm"
import { AuthHeader } from "../../components/Authentication/AuthHeader"

const SignIn = () => {
  return (
    <AuthContainer
      header={
        <AuthHeader
          text="Sign in to Patch"
          // TODO: put this properties back to show arrow
          // onClick={() => ""}
          // back
        />
      }
    >
      <SignInForm />
    </AuthContainer>
  )
}

export default SignIn
