import styled from "styled-components";

export const Button = styled.button`
  margin: 10px;
  padding: 2px;
  width: 8vw;
  height: 2.2vw;
  background-color: #49F4FF95;
  box-shadow: 2px 2px 4px #49F4FF60;
  border-radius: 4px;
  color: #141D2C;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transtion: 300ms;

  &:hover {
    margin: 10px;
    padding: 2px;
    width: 8vw;
    height: 2.2vw;
    background-color: #FC73FF90;
    box-shadow: 2px 2px 4px #FC73FF40;
    border-radius: 4px;
    color: #49F4FF;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    text-decoration: underline;
  }
`;
