import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import { ReactComponent as CollapseIcon } from "../../../assets/svg/collapseIcon.svg"
// import { CommunityContext } from "../../../context/community"

const StreamChat = () => {
  // TODO use community context for collapse value if community page content needs to adjust the margin based on it
  // const communityContext = useContext(CommunityContext)
  const [collapse, setCollapse] = useState(false)
  const [showIcon, setShowIcon] = useState(true)

  const handleCollapse = () => {
    if (collapse) {
      setCollapse(!collapse)
      setTimeout(() => setShowIcon(collapse), 100)
    }
    if (!collapse) {
      setShowIcon(collapse)
      setTimeout(() => setCollapse(!collapse), 100)
    }
  }

  return (
    // <div className="relative right-8 ml-4">
    <div className="fixed right-8 ml-4">
      <Transition
        show={showIcon}
        enter="transition ease duration-0 transform delay-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease duration-50 transform "
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <CollapseIcon
          data-collapse={collapse}
          className="text-white absolute top-4 "
          onClick={handleCollapse}
        />
      </Transition>

      <Transition
        show={collapse}
        enter="transition ease duration-50 transform delay-50"
        enterFrom="opacity-0 translate-x-56"
        enterTo="opacity-100 translate-x-8 -ml-8"
        leave="transition ease duration-50 transform"
        leaveFrom="opacity-100 translate-x-8 -ml-8"
        leaveTo="opacity-0 translate-x-56"
      >
        <iframe
          title="streamchat"
          src="https://www.twitch.tv/embed/ninja/chat?darkpopout&parent=localhost&parent=moblabs-mvp.vercel.app&parent=www.moblabs-mvp.vercel.app"
          height={"100%"}
          width={275}
          className="h-[calc(100vh-50px)]"
        />
        <CollapseIcon
          data-collapse={collapse}
          className="text-white absolute rotate-180 top-4"
          onClick={handleCollapse}
        />
      </Transition>
    </div>
  )
}

export default StreamChat
