import React, { useState, useContext } from "react";
import { Flex } from "rebass";

import Input from "../../../Common/Input";
import TextArea from "../../../Common/TextArea";
import SubmitButton from "../../../Authentication/SubmitButton";
import { CommunitySetupContext } from "../../../../context/communitySetup";
import COMMUNITY_SETUP_SCREENS from "../../../../config/screens/CommunitySetup";

import * as Styled from "./styles";

const InfoScreen = () => {
  const communitySetupContext = useContext(CommunitySetupContext);
  const [formState, setFormState] = useState({
    name: "",
    description: "",
  });

  const handleSave = () => {
    communitySetupContext.setCurrentScreenState(
      COMMUNITY_SETUP_SCREENS.BRANDING.key
    );
  };

  return (
    <div>
      <Flex flexDirection="column" mb="32px">
        <Input
          label="Name"
          placeholder="What’s your community’s name?"
          value={formState.name}
          onChange={(value) =>
            setFormState({
              ...formState,
              name: value,
            })
          }
        />
        <Styled.HelperText>
          This name will represent your community’s mission and members.
        </Styled.HelperText>
      </Flex>
      <Flex flexDirection="column" mb="32px">
        <TextArea
          value={formState.description}
          onChange={(value) =>
            setFormState({
              ...formState,
              description: value,
            })
          }
          characterCounter
          maxLength={256}
          label="Description"
          placeholder="Add a short description about your community"
        />
        <Styled.HelperText>Tell us about your community.</Styled.HelperText>
      </Flex>
      <Flex mb="32px" flexDirection="column">
        <Styled.TitleText>What’s your community about?</Styled.TitleText>
        <Styled.HelperText>
          Select 3 tags that best match up with what your community does
        </Styled.HelperText>
      </Flex>
      <SubmitButton onClick={handleSave} text="Save & Continue" />
    </div>
  );
};

export default InfoScreen;
