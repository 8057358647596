import React, { useState, useContext, useEffect } from "react"
import io from "socket.io-client"
import axios from "axios"
import * as R from "ramda"

import { XMarkIcon } from "@heroicons/react/20/solid"
import { CommunityContext } from "../../../../../context/community"
import { DareContext } from "../../../../../pages/Dare/context"
import { GunRunnerContext } from "../../../../../pages/GunRunner/context"
import SCREENS from "../../../../../pages/GunRunner/config/SCREENS"
import GunRunnerQueueCard from "../../GunRunnerQueueCard"
import GunRunnerCard from "../../GunRunnerCard"
import { SocketContext } from "../../../../../context/socket"

const GunRunnerFanCommunity = (gunrunneritems) => {
  const communityContext = useContext(CommunityContext)
  const gunRunnerContext = useContext(GunRunnerContext)
  const dareContext = useContext(DareContext)
  const [isLive, setIsLive] = useState(false)
  const queue = gunRunnerContext.gunRunnerQueue
  // const [Timeend, setTimeEnd] = useState(0)
  console.log(queue)

  const handleJoinLobby = () => {
    gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.FAN_BUY_SESSION)
  }

  return (
    <div className="h-screen">
      {
        <div className="flex bg-daresidepanelbg flex-col rounded-lg w-[var(--drawer-width)] h-fit">
          <div className="flex justify-between mx-8 mt-8 ">
            <div className="bg-dashboardhighlight rounded-sm drop-shadow items-center py-1 px-4 flex justify-center">
              <p className="font-ocr text-sm">GUNRUNNER</p>
            </div>
            <div
              onClick={() => {
                gunRunnerContext.setCurrentDrawerOpen(false)
                communityContext.setDrawerOpen(false)
              }}
              className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
            >
              <XMarkIcon />
            </div>
          </div>
          <div className="block ml-8 mt-10">
            <h1 className=" font-ocr text-dashboardtext text-lg">
              CURRENT PLAYER
            </h1>
            <div className="flex justify-center py-5">
              {queue &&
                queue
                  .filter((gunrunneritem) => gunrunneritem.status === "CURRENT")
                  .map((gunrunneritem) => (
                    <div className="flex items-center">
                      <GunRunnerCard
                        gunrunneritem={gunrunneritem}
                      ></GunRunnerCard>
                    </div>
                  ))}
            </div>
          </div>
          <div className="flex flex-col justify-between h-fit">
            <div className="flex ml-8 mt-10">
              <h1 className=" font-ocr text-dashboardtext text-lg">IN QUEUE</h1>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex justify-center border rounded-md my-10 flex-col w-5/6 max-h-52 px-12 overflow-auto md:overflow-scroll">
                {queue &&
                  queue
                    .filter(
                      (gunrunneritem) => gunrunneritem.status === "ACTIVE"
                    )
                    .map((gunrunneritem) => (
                      <div>
                        <GunRunnerQueueCard
                          price={gunrunneritem.price}
                          platform={gunrunneritem.platform}
                          status={gunrunneritem.status}
                          userid={gunrunneritem.userid}
                          username={gunrunneritem.userign}
                          serverid={gunrunneritem.serverid}
                          metaractid={gunrunneritem.metaractid}
                          gameid={gunrunneritem.gameid}
                        ></GunRunnerQueueCard>
                      </div>
                    ))}
              </div>
            </div>
            <div className="mb-10 mr-8">
              {
                <div className="flex justify-end">
                  <button
                    onClick={handleJoinLobby}
                    className="group flex py-3 px-4 font-ocr text-xl border border-dashboardhighlight bg-black items-center
          justify-center text-dashboardtext rounded-full
           hover:bg-dashboardhighlight hover:text-black transition ease-in-out"
                  >
                    <h1 className="font-ocr text-sm">JOIN LOBBY</h1>
                    <svg
                      viewBox="0 0 24 24"
                      className="w-6 h-6 stroke stroke-dashboardtext group-hover:stroke-black ml-1"
                    >
                      <path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                    </svg>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default GunRunnerFanCommunity
