import React, { useContext } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"

import PowerUpCard from "../../../../../Versus/PowerUpCard"
import { VersusContext } from "../../../../../../pages/Versus/context"
import { DrawerButton } from "../../../../Common"

const AcceptBattle = () => {
  const versusContext = useContext(VersusContext)
  return (
    <div className="flex flex-col bg-daresidepanelbg justify-between h-screen">
      <div className="flex flex-col">
        <div className="flex justify-between  mx-8 mt-8 mb-10 ">
          <div className="bg-dashboardhighlight hover:cursor-pointer rounded-sm drop-shadow items-center py-1 px-4 flex justify-center">
            <p className="font-ocr text-sm">VERSUS</p>
          </div>
          <div
            onClick={() => {
              versusContext.setNewVersusDrawerOpenState(false)
            }}
            className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>
        <div className="flex justify-center">
          <PowerUpCard
            title="Opposing player plays with cake in their eyes."
            price={20}
          ></PowerUpCard>
        </div>
      </div>
      <div className="flex flex-col">
        <DrawerButton
          text="ACCEPT BATTLE"
          onClick={() => {
            versusContext.setNewVersusDrawerOpenState(false)
            versusContext.setCurrentNewVersusScreensState(
              versusContext.newVersusScreens.SETUP
            )
          }}
        />
      </div>
    </div>
  )
}

export default AcceptBattle
