import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Flex, Box } from "rebass"
import firebase from "firebase/compat/app"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import * as R from "ramda"
import { toast } from "react-toastify"
import axios from "axios"

import { UsersContext } from "../../context/users"

import * as Styled from "./styles"

import BottomBorder from "../../components/SignIn/BottomBorder/BottomBorder"
import CardFrame from "../../components/SignIn/CardFrame"
import Logo from "../../components/SignIn/Logo"
import LogInInput from "../../components/SignIn/LogInInput"
import LogInSubText from "../../components/SignIn/LogInSubText"
import LogInButton from "../../components/SignIn/LogInButton"
import SignUpButton from "../../components/SignIn/SignUpButton"
import ForgotPasswordLink from "../../components/Authentication/ForgotPasswordLink"

const SignInTest = () => {
  const usersContext = useContext(UsersContext)

  const navigate = useNavigate()
  const auth = getAuth()

  const [formState, setFormState] = useState({
    email: "",
    password: "",
    emailMe: true,
  })

  const handleEmailChange = (event) => {
    setFormState({ ...formState, email: event.target.value })
  }

  const handlePasswordChange = (event) => {
    setFormState({ ...formState, password: event.target.value })
  }

  const handleLogIn = async () => {
    try {
      const signInUserResponse = await signInWithEmailAndPassword(
        auth,
        formState.email,
        formState.password
      )

      const backenddb = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuserfirestore/${signInUserResponse.user.uid}`
      )

      if (backenddb) {
        usersContext.setUserDBObject(backenddb?.data?.data)
      } else {
        toast.error("Oops! Something went wrong, not in DB")
      }

      const userToken = R.pathOr(
        "",
        ["user", "stsTokenManager", "accessToken"],
        signInUserResponse
      )
      usersContext.setUserIsSignedInState(!!userToken)

      navigate("/")
    } catch (error) {
      toast.error("Oops! Something went wrong")
    }
  }

  const handleSignUp = (event) => {
    navigate("/fan-or-creator")
  }

  return (
    <Styled.Container>
      <Flex
        height="100vh"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex mt="18vh">
          <CardFrame>
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Flex mt="3vh">
                <Logo />
              </Flex>
              <Flex>
                <LogInSubText>
                  Please enter your email and password
                </LogInSubText>
              </Flex>
              <Flex m="1vh">
                <LogInInput
                  width="18vw"
                  placeholder="Email address"
                  onChange={handleEmailChange}
                />
              </Flex>
              <Flex>
                <LogInInput
                  width="18vw"
                  placeholder="Password"
                  type="password"
                  onChange={handlePasswordChange}
                />
              </Flex>
              <Flex>
                <ForgotPasswordLink />
              </Flex>
              <Flex justifyContent="space-between" mt="2vh">
                <Box>
                  <SignUpButton text="Sign Up" onClick={handleSignUp} />
                </Box>
                <Box>
                  <LogInButton text="Log In" onClick={handleLogIn} />
                </Box>
              </Flex>
            </Flex>
          </CardFrame>
        </Flex>
        <Flex mb="20px">
          <BottomBorder />
        </Flex>
      </Flex>
    </Styled.Container>
  )
}

export default SignInTest
