import React from "react"
import TrendingCommunitiesContainer from "../TrendingCommunitiesContainer"
import { IconButton } from "@mui/material"
import { MdChevronLeft } from "react-icons/md"
import { MdChevronRight } from "react-icons/md"
import { white } from "tailwindcss/colors"

const TrendingCommunities = () => {
  //Chevron for scrolling
  const slideLeft = () => {
    var slider = document.getElementById("slider")
    slider.scrollLeft = slider.scrollLeft - 1600
  }
  const slideRight = () => {
    var slider = document.getElementById("slider")
    slider.scrollLeft = slider.scrollLeft + 1600
  }

  return (
    <div className="w-full pr-8">
      <div className="flex justify-between items-center pr-14 mt-12 ">
        <h1 className="font-proxima font-bold text-3xl">
          Trending Communities
        </h1>
        {/* <div className="flex text-xl items-center justify-between pr-5">
          <button className="">
            <p className="text-dashboardtext font-proxima underline ">
              See All
            </p>
          </button>
        </div> */}
      </div>
      <div className="flex -ml-10 mt-3 pr-8 align-middle">
        <IconButton onClick={slideLeft}>
          {/*style the opacity for netflix vibes*/}
          <MdChevronLeft size={40} color={white} />
        </IconButton>
        <div className="flex mt-6 ml-5 mr-2 pr-0 overflow-x-hidden">
          <TrendingCommunitiesContainer />
        </div>
        <IconButton onClick={slideRight}>
          {/*style the opacity for netflix vibes*/}
          <MdChevronRight size={40} color={white} />
        </IconButton>
      </div>
    </div>
  )
}

export default TrendingCommunities
