import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { useDebounce } from "@uidotdev/usehooks"

import { XMarkIcon } from "@heroicons/react/20/solid"
import { CreatorRow } from "./CreatorRow"

import { VersusContext } from "../../../../../../pages/Versus/context"
import { MetaractShelfContext } from "../../../../../../pages/MetaractShelf/context"
import { DrawerButton } from "../../../../Common"

const ChooseCreator = () => {
  const versusContext = useContext(VersusContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const [selectedCreators, setSelectedCreators] = useState()
  const [selectedCreator, setSelectedCreator] = useState()
  const [searchString, setSearchString] = useState("")
  // const [isSearching, setIsSearching] = useState(false)
  const debouncedSearchTerm = useDebounce(searchString, 300)

  const handleSearch = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunities?searchString=${searchString}&take=5&skip=0&orderBy=asc`
      )
      console.log(res.data)
      setSelectedCreators(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChange = (event) => {
    setSearchString(event.target.value)
  }

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const searchCreators = async () => {
      if (debouncedSearchTerm) {
        handleSearch()
      }
    }
    searchCreators()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  return (
    <div className="flex flex-col h-screen bg-daresidepanelbg mb-8">
      <div className="flex flex-col">
        <div className="flex justify-between  mx-8 mt-8 mb-10 ">
          <div className="bg-dashboardhighlight hover:cursor-pointer rounded-sm drop-shadow items-center py-1 px-4 flex justify-center">
            <p className="font-ocr text-sm">VERSUS</p>
          </div>
          <div
            onClick={() => {
              versusContext.setNewVersusDrawerOpenState(false)
              metaractShelfContext.setDrawerIsOpen(false)
            }}
            className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>
        <div className="flex flex-col mx-8">
          <h1 className="font-ocr text-dashboardtext text-2xl">
            CHALLENGE A CREATOR
          </h1>
          <div className="flex mt-8 mx-2 w-full items-center">
            <input
              name="title"
              placeholder="Search for a creator"
              onChange={handleOnChange}
              className=" border border-dashboardframes outline-none text-dashboardtext bg-transparent font-proxima rounded-lg py-2 w-full px-2 items-center justify-center flex"
            ></input>
            <button
              onClick={handleSearch}
              className="mx-2 py-2 text-dashboardtext px-4 font-ocr text-md border border-dashboardtext rounded-full hover:text-dashboardhighlight"
            >
              Search
            </button>
          </div>
          <p className="mt-8 text-dashboardalttext text-sm">
            Suggested creators
          </p>
        </div>
      </div>
      <div className="flex flex-col mx-8 py-8 overflow-scroll no-scrollbar">
        {selectedCreators &&
          selectedCreators.map((community) => (
            <CreatorRow
              key={community.id}
              community={community}
              isInvited={community.id === selectedCreator?.id}
              onClick={() => {
                console.log("inviting")
                setSelectedCreator(community)
              }}
            />
          ))}
      </div>
      {selectedCreator !== undefined && (
        <DrawerButton
          text="CREATE POWER-UPS"
          onClick={() => {
            versusContext.setCurrentNewVersusScreensState(
              versusContext.newVersusScreens.POWER_UPS
            )
            versusContext.setSetupState({
              ...versusContext.setupState,
              selectedCreator: selectedCreator,
            })
          }}
        />
      )}
      {selectedCreator === undefined && (
        <DrawerButton text="CREATE POWER-UPS" onClick={() => {}} />
      )}
    </div>
  )
}

export default ChooseCreator
