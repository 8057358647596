import React, { useState, useEffect } from "react"
import axios from "axios"

import TrendingCommunity from "../TrendingCommunity"

const TrendingCommunitiesContainer = () => {
  const [trendingCommunities, SetTrendingCommunities] = useState([])

  const getTrendingCommunities = async () => {
    try {
      const communities = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/trendingcommunities`
      )
      SetTrendingCommunities(communities.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTrendingCommunities()
  }, [])

  return (
    <div
      className="flex flex-no-wrap overflow-x-auto items-start w-full scroll-smooth scrollbar-hide"
      id="slider"
    >
      {trendingCommunities.map((community) => (
        <div className="flex-none w-72 pr-8" key={community.id}>
          <TrendingCommunity community={community} key={community.id} />
        </div>
      ))}
    </div>
  )
}

export default TrendingCommunitiesContainer
