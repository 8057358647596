import React from "react"
import { useNavigate } from "react-router-dom"
import ROUTES from "../../../config/routes"
import { Button } from "../../Common/atoms/Button"
import { useScreen } from "../../../hooks/useScreen"

const SelectFanOrCreator = () => {
  const navigate = useNavigate()
  const { isMobile } = useScreen()

  return (
    <>
      <div className="flex flex-col gap-y-6 sm:gap-y-10 font-proxima text-dashboardtext">
        <p>
          Thank you for helping us out with this test. This prototype includes
          flows for <b>Fan-Users</b> & <b>Creator-Users</b>.
        </p>
        <p className="font-bold">Choose an option below when prompted:</p>
      </div>
      <div className="flex flex-col my-auto py-8 gap-y-5">
        <Button
          onClick={() => navigate(ROUTES.FAN_SIGNUP.path)}
          text="I'M A FAN"
          size={isMobile ? "medium" : "big"}
          styles="w-60 sm:w-72 font-proxima"
        />
        <Button
          onClick={() => navigate(ROUTES.CREATOR_SIGNUP.path)}
          text="I'M A CREATOR"
          size={isMobile ? "medium" : "big"}
          styles="w-60 sm:w-72 font-proxima"
        />
      </div>
    </>
  )
}

export default SelectFanOrCreator
