import styled from "styled-components";

export const Container = styled.div`
  width: 948px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SingleGridItem = styled.div`
  width: 213px;
  height: 213px;
  border: 4px dashed #d6dadd;
  border-radius: 16px;
  margin-bottom: 20px;
`;
