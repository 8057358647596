import { ReactComponent as DecoDiagonals } from "../../assets/svg/deco-diagonals.svg"

export const WithDiagonalsTitle = ({ title }) => (
  <div className="flex my-4 justify-between">
    <h1 className=" font-ocr text-dashboardtext text-base sm:text-lg">
      {title}
    </h1>
    <DecoDiagonals />
  </div>
)
