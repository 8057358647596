const SCREENS = {
  DRAWERS: {
    LAUNCHDARE: "LAUNCHDARE",
    DAREDETAILS: "DAREDETAILS",
    LAUNCHGUNRUNNER: "GUNRUNNER",
    LAUNCHVERSUS: "LAUNCHVERSUS",
    CREATORDARE: "CREATORDARE",
    CREATORVERSUS: "CREATORVERSUS",
    CREATORGUNRUNNER: "CREATORGUNRUNNER",
    DAREDETAILS: "DAREDETAILS",
    VERSUSDETAILS: "VERSUSDETAILS",
    GUNRUNNERDETAILS: "GUNRUNNERDETAILS",
  },
}
export default SCREENS
