import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const Container = styled.div`
  padding-left: 30px;
  padding-right: 100px;
`;

export const BackArrow = styled(ArrowBackIcon)`
  && {
    color: #d1d1d1;
    margin-right: 10px;
  }
`;

export const LogoContainer = styled.div`
  width: 220;
  height: 42px;
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const DescriptonText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  margin: 0;
  padding: 0;
`;

export const PriceText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #000000;
  margin: 0;
  padding: 0;
  margin: 8px 0;
`;
