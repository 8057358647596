import React, { useState, useContext } from "react";
import * as R from "ramda";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Flex } from "rebass";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import thousandsFormatter from "../../../utils/thousandsFormatter";
import Popup from "../../Common/Popup";
import CardTitleText from "../../Common/CardTitleText";
import CardContentText from "../../Common/CardContentText";
import CardLinkText from "../../Common/CardLinkText";

import * as Styled from "./styles";
import { UsersContext } from "../../../context/users";

const TrendingCommunity = ({ community }) => {
  const [showLeavingPopupState, setShowLeavingPopupState] = useState(false);
  const navigate = useNavigate();
  const usersContext = useContext(UsersContext);

  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext);
  const userID = R.pathOr("", ["userDBObject", "id"], usersContext);
  const userJoinedCommunities = R.pathOr(
    "",
    ["userDBObject", "communities"],
    usersContext
  );

  let userHasJoined;
  if (userJoinedCommunities !== undefined && userJoinedCommunities.length > 0) {
    userHasJoined =
      userJoinedCommunities.filter((comm) => {
        return comm.communityid === community.id;
      }).length >= 1
        ? true
        : false;
  } else {
    userHasJoined = false;
  }

  const handleClick = () => {
    navigate(`/community/${community.id}`);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col pt-2 pb-2 ml-2 w-full cursor-pointer hover:scale-105 duration-300 ease-in-out" onClick={handleClick}>
        <div
          style={{ backgroundImage: `url(${community.communitypictureurl})` }}
          className="bg-black bg-cover bg-center bg-no-repeat w-full h-56 mb-2 rounded-md"
        ></div>
        <div className="items-center justify-between flex mt-2">
          <div className="flex items-center justify-center">
            <div
              style={{
                backgroundImage: `url(${community.creator.profilepictureurl})`,
              }}
              className="w-16 h-16 bg-black bg-cover bg-center bg-no-repeat rounded-md flex items-end justify-end"
            >
              {true && (
                <div className="bg-dashboardaccent flex rounded-md p-1 justify-center items-center">
                  <p className="font-hk font-bold text-xs">LIVE</p>
                </div>
              )}
            </div>
            <div className="ml-2">
              <p className="font-proxima text-dashboardtext">
                {community.name}
              </p>
              <div className="flex items-center">
                <svg
                  viewBox="0 0 24 24"
                  className="w-5 h-5 mr-1 stroke-dashboardtext fill-dashboardtext"
                >
                  <path d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>

                <p className="font-proxima text-dashboardtext text-sm">
                  {`${thousandsFormatter(community.supporters)} supporters`}
                </p>
              </div>
              <div className="flex items-center">
                <p className="font-proxima text-xs text-dashboardalttext">by</p>
                <p className="font-proxima text-xs text-dashboardaccent  underline ml-1">{`@${community.handle}`}</p>
              </div>
            </div>
          </div>
          <div className="mr-4">
            {userHasJoined ? (
              <button>
                <p className=" hover:cursor-default text-dashboardalttext text-sm font-ocr  ">
                  JOINED
                </p>
              </button>
            ) : (
              <button className="flex border hover:cursor-default items-center border-dashboardhighlight rounded-full py-1 px-3">
                <p className="text-dashboardtext text-sm font-ocr  ">JOIN</p>
              </button>
            )}
          </div>
        </div>
      </div>
      <Popup
        open={showLeavingPopupState}
        onClose={() => setShowLeavingPopupState(false)}
        title="Are You Sure You Want To Leave This Community?"
      >
        <DialogActions>
          <Button onClick={() => setShowLeavingPopupState(false)}>Close</Button>
          <Button onClick={() => {}}>Leave</Button>
        </DialogActions>
      </Popup>
    </React.Fragment>
  );
};

export default TrendingCommunity;
