import { Fragment, useState, useContext, useEffect } from "react"
import { Disclosure, Listbox, Menu, Transition } from "@headlessui/react"

import axios from "axios"
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BriefcaseIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  LinkIcon,
  PencilIcon,
} from "@heroicons/react/20/solid"
import TopBar from "../../Navigation/TopBar"
import { useParams, useLocation } from "react-router-dom"
import DareDrawer from "../../Metaracts/Dare/Screens/DareDrawers"
import SCREENS from "../../../pages/MetaractShelf/config/SCREENS"
import { MetaractShelfContext } from "../../../pages/MetaractShelf/context"

const tabs = [
  { name: "Active", href: "#", count: "2", current: true },
  { name: "Completed", href: "#", count: "4", current: false },
  { name: "Removed", href: "#", count: "6", current: false },
]
const publishingOptions = [
  {
    name: "Published",
    description:
      "This metaract is currently published and live, fans will see the metaract on your community page.",
    current: true,
  },
  {
    name: "Inactive",
    description:
      "This metaract posting will no longer be publicly accessible. You can still access it from your metaract shelf.",
    current: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function MetaractShelfDareDetails() {
  const [selected, setSelected] = useState(publishingOptions[0])
  const [DareItems, SetDareItems] = useState([])
  const { metaractid } = useParams()
  const location = useLocation()
  console.log("location")
  const community_id = location.state.community_id
  const metaractShelfContext = useContext(MetaractShelfContext)
  metaractShelfContext.setMetaractID(metaractid)

  const getDareItems = async (metaractid) => {
    if (metaractid) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_API}dareitem/getdareitems/?metaractid=${metaractid}&take=100&skip=0&orderBy=desc`
        )
        console.log("Queried => getDareItems fired on Creator Community Page")
        const DareItems = response.data.data
        SetDareItems(DareItems)
        console.log(DareItems)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    getDareItems(metaractid)
  }, [])

  let sum = DareItems.reduce(function (prev, current) {
    return prev + +current.price
  }, 0)

  return (
    <>
      <DareDrawer />

      <div className="w-screen min-h-screen overflow-auto">
        {/* Navbar */}
        <TopBar />
        {/* Page heading */}
        <header className="bg-dashboardbackground py-8">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="min-w-0 flex-1">
              <nav className="flex" aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-4">
                  <li>
                    <div>
                      <a
                        href={"/metaractshelf/" + community_id}
                        className="text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Metaract Shelf
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <a
                        href="#"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Dare Clash
                      </a>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 className="mt-2 text-2xl font-ocr leading-7 text-dashboardtext sm:truncate sm:text-3xl sm:tracking-tight">
                Dare Clash Dashboard
              </h1>
              <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <BriefcaseIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Active
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CurrencyDollarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  {sum} - Total Contributions
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Closing on January 9, 2020
                </div>
              </div>
            </div>
            <div className="mt-5 flex xl:mt-0 xl:ml-4">
              <span className="hidden sm:block">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md border-dashboardhighlight border bg-dashboardboxbackground px-3 py-2 text-base font-proxima text-dashboardtext shadow-sm ring-1 ring-inset hover:text-dashboardbackground  hover:bg-dashboardhighlight"
                >
                  <PencilIcon
                    className="-ml-0.5 h-5 w-5 text-dashboardtext hover-group:text-dashboardboxbackground"
                    aria-hidden="true"
                  />
                  Edit
                </button>
              </span>

              <span className="ml-3 hidden sm:block">
                <button
                  type="button"
                  className="inline-flex items-center hover-group gap-x-1.5 rounded-md border-dashboardhighlight border bg-dashboardboxbackground px-3 py-2 text-base font-proxima text-dashboardtext shadow-sm ring-1 ring-inset hover:text-dashboardbackground  hover:bg-dashboardhighlight"
                >
                  <LinkIcon
                    className="-ml-0.5 h-5 w-5 text-dashboardtext hover-group:text-dashboardboxbackground"
                    aria-hidden="true"
                  />
                  View
                </button>
              </span>

              <Listbox
                as="div"
                value={selected}
                onChange={setSelected}
                className="sm:ml-3"
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">
                      Change published status
                    </Listbox.Label>
                    <div className="relative">
                      <div className="inline-flex divide-x divide-dashboardhighlight rounded-md shadow-sm">
                        <div className="inline-flex divide-x divide-dashboardhighlight rounded-md shadow-sm">
                          <div className="inline-flex items-center gap-x-1.5 rounded-l-md border-dashboardhighlight border bg-dashboardboxbackground py-2 px-3 text-dashboardtext shadow-sm">
                            <CheckIcon
                              className="-ml-0.5 h-5 w-5"
                              aria-hidden="true"
                            />
                            <p className="text-base font-proxima">
                              {selected.name}
                            </p>
                          </div>
                          <Listbox.Button className="inline-flex hover-group items-center rounded-l-none rounded-r-md bg-dashboardhighlight p-2 hover:bg-black focus-visible:outline-none focus-visible:ring-2focus-visible:ring-offset-2 ">
                            <span className="sr-only font-ocr">
                              Change published status
                            </span>
                            <ChevronDownIcon
                              className="h-5 w-5 text-black hover-group:dashboardtext"
                              aria-hidden="true"
                            />
                          </Listbox.Button>
                        </div>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute left-0 z-10 mt-2 -mr-1 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                          {publishingOptions.map((option) => (
                            <Listbox.Option
                              key={option.name}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-purple-500 text-white"
                                    : "text-gray-900",
                                  "cursor-default select-none p-4 text-sm"
                                )
                              }
                              value={option}
                            >
                              {({ selected, active }) => (
                                <div className="flex flex-col">
                                  <div className="flex justify-between">
                                    <p
                                      className={
                                        selected
                                          ? "font-semibold"
                                          : "font-normal"
                                      }
                                    >
                                      {option.name}
                                    </p>
                                    {selected ? (
                                      <span
                                        className={
                                          active
                                            ? "text-white"
                                            : "text-purple-500"
                                        }
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </div>
                                  <p
                                    className={classNames(
                                      active
                                        ? "text-purple-200"
                                        : "text-gray-500",
                                      "mt-2"
                                    )}
                                  >
                                    {option.description}
                                  </p>
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </header>

        <main className="pt-8 pb-16">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="px-4 sm:px-0">
              <h2 className="text-lg font-medium text-dashboardtext">
                Metaracts - Dare Clash
              </h2>

              {/* Tabs */}
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500"
                  defaultValue={tabs.find((tab) => tab.current).name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                          tab.current
                            ? "border-dashboardhighlight text-dashboardtext"
                            : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                        )}
                      >
                        {tab.name}
                        {tab.count ? (
                          <span
                            className={classNames(
                              tab.current
                                ? "bg-purple-100 text-dashboardhighlight"
                                : "bg-gray-100 text-dashboardhighlight",
                              "ml-2 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                            )}
                          >
                            {tab.count}
                          </span>
                        ) : null}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            {/* Stacked list */}
            <ul
              role="list"
              className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
            >
              {DareItems.map((dareItem) => (
                <li key={dareItem.id}>
                  <a className="group block">
                    <div
                      className="flex items-center py-5 px-4 sm:py-6 sm:px-0"
                      onClick={() => {
                        console.log(dareItem.title)
                        const selectedDare = {
                          title: dareItem.title,
                          description: dareItem.description,
                          price: dareItem.price,
                          id: dareItem.id,
                          users: dareItem.userids,
                        }
                        metaractShelfContext.setDareItem(selectedDare)
                        metaractShelfContext.setDrawerIsOpen(true)
                        metaractShelfContext.setCurrentDrawerScreen(
                          SCREENS.DRAWERS.DAREDETAILS
                        )
                      }}
                    >
                      <div className="flex min-w-0 flex-1 items-center">
                        {/*  if we want to add profile pic back in
                        <div className="flex-shrink-0">
                          <img
                            className="h-12 w-12 rounded-full group-hover:opacity-75"
                            src=""
                            alt=""
                          />
                        </div>
                        */}
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="truncate text-sm  font-proxima font-medium text-dashboardtext">
                              {dareItem.title}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <CurrencyDollarIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate font-hk">
                                {dareItem.price}
                              </span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="text-sm text-dashboardalttext font-hk">
                                Created by fan on{" "}
                                <time dateTime={dareItem.createdat}>
                                  {new Date(dareItem.createdat).toDateString()}
                                </time>
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500 font-hk">
                                <CheckCircleIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                  aria-hidden="true"
                                />
                                {dareItem.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>

            {/* Pagination */}
            <nav
              className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
              aria-label="Pagination"
            >
              <div className="-mt-px flex w-0 flex-1">
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                >
                  <ArrowLongLeftIcon
                    className="mr-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Previous
                </a>
              </div>
              <div className="hidden md:-mt-px md:flex">
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                >
                  1
                </a>
                {/* Current: "border-purple-500 text-purple-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200" */}
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-purple-500 px-4 pt-4 text-sm font-medium text-purple-600"
                  aria-current="page"
                >
                  2
                </a>
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                >
                  3
                </a>
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                >
                  4
                </a>
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                >
                  5
                </a>
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                >
                  6
                </a>
              </div>
              <div className="-mt-px flex w-0 flex-1 justify-end">
                <a
                  href="#"
                  className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                >
                  Next
                  <ArrowLongRightIcon
                    className="ml-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </nav>
          </div>
        </main>
      </div>
    </>
  )
}
