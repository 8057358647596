import styled from "styled-components";

export const Container = styled.div`
  margin-top: 28px;
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  width: 140px;
  height: 62px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #101010;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-right: 144px;
`;
