const SCREENS = {
  SCREENS: {
    INIT: "INIT",
    FAN_ADD_DARE: "FAN_ADD_DARE",
    FAN_DARE_DETAILS: "FAN_DARE_DETAILS",
    FAN_LIVE_DARE: "FAN_LIVE_DARE",
    CREATOR_DARE_SETTINGS: "CREATOR_DARE_SETTINGS",
    CREATOR_DARE_DETAILS: "CREATOR_DARE_DETAILS",
    CREATOR_LIVE_DARE: "CREATOR_LIVE_DARE",
  },
  DRAWERS: {
    INIT: "INIT",
    CREATOR_COMMUNITY: "CREATOR_COMMUNITY",
    FAN_COMMUNITY: "FAN_COMMUNITY",
  },
}

export default SCREENS
