import { XMarkIcon } from "@heroicons/react/20/solid"
import { FiArrowLeft } from "react-icons/fi"
import { useScreen } from "../../hooks/useScreen"

export const AuthHeader = ({ onClick, back, text }) => {
  const { isMobile } = useScreen()
  return (
    <div className="flex justify-start mb-3 sm:mb-0 md:mt-8 items-center font-semibold text-xl sm:text-4xl text-dashboardtext gap-x-2">
      {isMobile && onClick && (
        <div onClick={onClick} className="w-8 h-8 hover:cursor-pointer">
          {back ? <FiArrowLeft className="text-[32px]" /> : <XMarkIcon />}
        </div>
      )}
      <h1>{text}</h1>
    </div>
  )
}
