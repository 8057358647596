import React, { useContext, useState, useEffect } from "react"
import { VersusContext } from "../../../pages/Versus/context"
import * as R from "ramda"

import { XMarkIcon } from "@heroicons/react/20/solid"
import { CommunityContext } from "../../../context/community"
import { MetaractShelfContext } from "../../../pages/MetaractShelf/context"

const Header = () => {
  const [battletitle, setBattleTitle] = useState("")
  const versusContext = useContext(VersusContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  const communityContext = useContext(CommunityContext)

  useEffect(() => {
    if (versusContext.isLoading) {
      setBattleTitle("Loading")
    } else {
      setBattleTitle(
        R.pathOr("Loading", ["versus", "description", "title"], versusContext)
      )
    }
  }, [versusContext.isLoading])
  return (
    <div className="flex w-full">
      <div className="flex justify-between w-full items-center">
        <h1 className="font-ocr text-4xl text-dashboardtext">{battletitle}</h1>
        <div className="flex ">
          <div
            onClick={() => {
              communityContext.setDrawerOpen(false)
              metaractShelfContext.setDrawerIsOpen(false)
              versusContext.setCurrentDrawerOpen(false)
            }}
            className="w-8 h-8 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
