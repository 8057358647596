import { useScreen } from "../../hooks/useScreen"
import SideTextPanel from "../Common/SideTextPanel"

export const AuthContainer = ({ children, header }) => {
  const { isMobile } = useScreen()
  return (
    <div className="flex bg-dashboardbackground py-12 sm:py-20 px-5 sm:px-16 gap-x-10 h-screen w-screen justify-center">
      {/*!isMobile && <SideTextPanel />*/}
      <div className="flex flex-col w-full sm:w-fit">
        {header && (
          <div className="flex flex-col w-full mb-4 sm:mb-8 sm:ml-4">
            {header}
          </div>
        )}
        <div className="flex flex-col h-fit sm:w-full md:w-[500px] justify-between gap-y-4 sm:gap-y-5 lg:w-[660px] sm:max-w-[500px] bg-dashboardboxbackground sm:mb-8 p-5 sm:p-8 rounded-xl font-proxima text-dashboardtext">
          {children}
        </div>
      </div>
    </div>
  )
}
