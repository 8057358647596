import React, { useState, useContext } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import * as R from "ramda"
import FormData from "form-data"
import { FileUploader } from "react-drag-drop-files"

import { useNavigate } from "react-router-dom"
import { UsersContext } from "../../../../../context/users"
import { DrawerInput } from "../../../../Metaracts/Common"
import { Button } from "../../../../Common/atoms/Button"
import { useScreen } from "../../../../../hooks/useScreen"

window.Buffer = window.Buffer || require("buffer").Buffer

const CreateCommunity = () => {
  const usersContext = useContext(UsersContext)
  const navigate = useNavigate()
  const { isMobile } = useScreen()
  const [isLoadingState, setIsLoadingState] = useState(false)
  const [formState, setFormState] = useState({
    name: "",
    description: "",
    supporters: 0,
    islive: false,
    handle: "",
    streamsrc: "",
    communitypictureurl: "",
    creatorid: "",
  })

  const handleUpload = async (file) => {
    try {
      setIsLoadingState(true)
      let files = new FormData()
      files.append("file", file, usersContext.userDBObject?.id + "community")
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}upload`,
        files,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${files._boundary}`,
          },
        }
      )
      const url = R.pathOr("", ["data", "data", "Location"], res)
      console.log("URL", "=>", url)
      setFormState({ ...formState, communitypictureurl: url })
    } catch (error) {
      toast.error("Oops! Something went wrong")
    } finally {
      setIsLoadingState(false)
    }
  }

  const handleCommunitySubmit = async () => {
    if (!isLoadingState) {
      try {
        const backenddb = await axios.post(
          `${process.env.REACT_APP_SERVER_API}community/createcommunity`,
          {
            name: formState.name,
            description: formState.description,
            supporters: formState.supporters,
            islive: formState.islive,
            handle: formState.handle,
            streamsrc: formState.streamsrc,
            communitypictureurl: formState.communitypictureurl,
            creatorid: usersContext.userDBObject.id,
          }
        )
        console.log(backenddb, "=>", "create_community response")
      } catch (error) {
        console.log(error)
      } finally {
        navigate("/")
      }
    } else {
      toast.error("Oops! Please wait for file to finish uploading!")
    }
  }

  return (
    <>
      {DrawerInput({
        type: "text",
        placeholder: "Enter a name for your community...",
        onChange: (event) =>
          setFormState({ ...formState, name: event.target.value }),
        title: "Community Name",
        titleStyle: "sm:text-xl",
      })}
      {DrawerInput({
        type: "text",
        placeholder: "Enter a description for your community...",
        onChange: (event) =>
          setFormState({ ...formState, description: event.target.value }),
        title: "Community Description",
        titleStyle: "sm:text-xl",
      })}
      {DrawerInput({
        type: "text",
        placeholder: "Add a handle...",
        onChange: (event) =>
          setFormState({ ...formState, handle: event.target.value }),
        title: "Handle",
        titleStyle: "sm:text-xl",
      })}
      {DrawerInput({
        type: "text",
        placeholder: "Add your Twitch Channel...",
        onChange: (event) =>
          setFormState({ ...formState, streamsrc: event.target.value }),
        title: "Twitch Channel",
        titleStyle: "sm:text-xl",
      })}
      <div className="flex flex-col justify-between my-2">
        <h3 className="font-proxima text-base font-semibold mb-2 sm:text-xl text-dashboardtext ">
          Upload Banner
        </h3>
        <FileUploader
          handleChange={handleUpload}
          name="file"
          types={["JPG", "PNG"]}
          classes="min-w-min"
        />
      </div>
      <Button
        onClick={handleCommunitySubmit}
        text="Create Community"
        size={isMobile ? "medium" : "big"}
        styles="mx-8 mt-10 mb-4 sm:mt-5 sm:mb-5 font-proxima"
      />
    </>
  )
}

export default CreateCommunity
