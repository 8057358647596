import React, { useState, useContext } from "react"
import axios from "axios"
import * as R from "ramda"
import { toast } from "react-toastify"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { useNavigate } from "react-router-dom"
import { Checkbox } from "@mui/material"

import { CommunityContext } from "../../../../../../../context/community"
import { DareContext } from "../../../../../../../pages/Dare/context"
import { UsersContext } from "../../../../../../../context/users"
import SCREENS from "../../../../../../../pages/Dare/config/SCREENS"
import { SocketContext } from "../../../../../../../context/socket"
import { Modal } from "@mui/material"
import Wallet from "../../../../../../Wallet"
import {
  DrawerButton,
  DrawerInput,
  PaymentMethod,
  DrawerContainer,
  DrawerDareBox,
} from "../../../../../Common"

const DareFanAddDare = () => {
  const communityContext = useContext(CommunityContext)
  const usersContext = useContext(UsersContext)
  const dareContext = useContext(DareContext)

  const navigate = useNavigate()
  const socket = useContext(SocketContext)

  const userID = R.pathOr("NA", ["userDBObject", "id"], usersContext)

  const [showWalletModal, setShowWalletModal] = useState(false)
  const [dareTitle, SetDareTitle] = useState("")
  const [dareDetails, SetDareDetails] = useState("")
  const [darePrice, SetDarePrice] = useState(0)
  const [enableContribution, SetEnableContribution] = useState(false)

  const handleChangeTitle = (event) => {
    SetDareTitle(event.target.value)
  }

  const handleChangeDetails = (event) => {
    SetDareDetails(event.target.value)
  }

  const handleChangePrice = (event) => {
    SetDarePrice(Number(event.target.value))
  }

  const handleSubmit = (event) => {
    if (userID === "NA") {
      toast.error("Please login to add to a dare")
      navigate(`/fan-signup/${communityContext.id}`)
      return
    }
    if (
      usersContext.userDBObject.wallet.total -
        usersContext.userDBObject.wallet.committed -
        Number(darePrice) <
      0
    ) {
      toast.error("Not enough balance.")
      setShowWalletModal(true)
      return
    }

    const metaract = communityContext.metaracts.find(
      (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
    )

    const newdareitem = {
      metaractid: metaract.id,
      userid: userID,
      title: dareTitle,
      price: darePrice,
      description: dareDetails,
      status: "ACTIVE",
      communityid: communityContext.id,
    }
    console.log(newdareitem)

    const addNewDare = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/adddare`,
          newdareitem
        )

        console.log("Added new Dare with ID: ", res.data)
        toast("Add dare success! Wait to see if the creator does your dare!")
        if (socket) {
          socket.emit("SEND_DARE_HAS_CHANGED", `dare_${metaract.id}`)
        }
      } catch (error) {
        console.log(error)
        if (error?.response?.data?.error === "InsufficientBalance") {
          toast.error("Not enough balance.")
        }
      }
    }

    addNewDare()

    dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
  }

  const WalletModal = ({ showModal }) => (
    <Modal
      open={showModal}
      onClose={() => {
        setShowWalletModal(false)
      }}
    >
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex flex-col w-[700px] h-[600px] bg-daresidepanelbg rounded-xl ">
          <div className="flex w-full justify-between pt-4 items-center px-4 ">
            <h1 className="font-ocr text-dashboardtext text-xl">Wallet</h1>
            <div
              onClick={() => {
                setShowWalletModal(false)
              }}
              className="w-10 h-10 text-dashboardtext hover:text-dashboardhighlight hover:cursor-pointer"
            >
              <XMarkIcon />
            </div>
          </div>
          <div className="flex w-full flex-grow">
            <Wallet />
          </div>
        </div>
      </div>
    </Modal>
  )

  return (
    <DrawerContainer
      back
      type="dare"
      onClose={() => {
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
      }}
      title="ADD A DARE"
      footer={<DrawerButton text="ADD DARE" onClick={handleSubmit} />}
    >
      <DrawerDareBox boxTitle="Add a Dare">
        <div className="flex flex-col gap-y-4">
          {DrawerInput({
            title: "Title",
            placeholder: "Name your Dare",
            onChange: handleChangeTitle,
          })}
          {DrawerInput({
            title: "Description",
            placeholder: "Describe the dare in detail",
            onChange: handleChangeDetails,
          })}
          <div className="flex items-center">
            <Checkbox
              checked={enableContribution}
              onChange={() => SetEnableContribution(!enableContribution)}
              sx={{ color: "#D4D4D4", "&.Mui-checked": { color: "#18E97F" } }}
            />
            <h1 className="text-dashboardframes font-proxima text-base text-md">
              Kick-off Dare with a Contribution!
            </h1>
          </div>

          {enableContribution &&
            DrawerInput({
              title: "My contribution",
              placeholder: "USD $0.00",
              onChange: handleChangePrice,
              leftLabel: "Min: USD $0.00",
            })}
        </div>
      </DrawerDareBox>
      {enableContribution && (
        <PaymentMethod
          darePrice={darePrice}
          label="Total due right now"
          hint="This contribution allows you to post the dare. It will be refunded if
        the dare fails to win votes from community members and is not included
        in the clash"
          styles="px-4 sm:px-0"
        />
      )}
    </DrawerContainer>
  )
}

export default DareFanAddDare
