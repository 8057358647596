import { useScreen } from "../../../hooks/useScreen"

export const DrawerDareBox = ({ boxTitle, children }) => {
  const { isMobile } = useScreen()
  return (
    <div className="text-dashboardtext p-4 pb-6 sm:p-0 sm:pb-0 bg-daresidepanelbg sm:bg-none rounded-xl">
      {isMobile && <h2 className="font-semibold text-xl pb-4">{boxTitle}</h2>}
      {children}
    </div>
  )
}
