import React from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flex } from "rebass"

import ROUTES from "./config/routes"
import UsersProvider from "./context/users"
import AccountSettingsProvider from "./context/accountSettings"
import CommunitySetupProvider from "./context/communitySetup"
import CommunityProvider from "./context/community"
import GunRunnerProvider from "./pages/GunRunner/context"
import VersusProvider from "./pages/Versus/context"
import DareProvider from "./pages/Dare/context"
import MetaractShelfProvider from "./pages/MetaractShelf/context"
import SocketProvider from "./context/socket"

const router = createBrowserRouter([
  {
    path: ROUTES.DASHBOARD.path,
    element: ROUTES.DASHBOARD.renderRoute(),
  },
  {
    path: ROUTES.FAN_SIGNUP.path,
    element: ROUTES.FAN_SIGNUP.renderRoute(),
  },
  {
    path: ROUTES.SIGN_IN.path,
    element: ROUTES.SIGN_IN.renderRoute(),
  },
  {
    path: ROUTES.RESET_PASSWORD.path,
    element: ROUTES.RESET_PASSWORD.renderRoute(),
  },
  {
    path: ROUTES.RESET_PASSWORD.path,
    element: ROUTES.RESET_PASSWORD.renderRoute(),
  },
  {
    path: ROUTES.CREATOR_SIGNUP.path,
    element: ROUTES.CREATOR_SIGNUP.renderRoute(),
  },
  {
    path: ROUTES.FAN_OR_CREATOR.path,
    element: ROUTES.FAN_OR_CREATOR.renderRoute(),
  },
  {
    path: ROUTES.CONTACT.path,
    element: ROUTES.CONTACT.renderRoute(),
  },
  {
    path: ROUTES.FAN_ACCOUNT_SETTINGS.path,
    element: ROUTES.FAN_ACCOUNT_SETTINGS.renderRoute(),
  },
  {
    path: ROUTES.CREATOR_ACCOUNT_SETTINGS.path,
    element: ROUTES.CREATOR_ACCOUNT_SETTINGS.renderRoute(),
  },
  {
    path: ROUTES.PROFILE.path,
    element: ROUTES.PROFILE.renderRoute(),
  },
  {
    path: ROUTES.COMMUNITY_SETUP.path,
    element: ROUTES.COMMUNITY_SETUP.renderRoute(),
  },
  {
    path: ROUTES.COMMUNITY.path,
    element: ROUTES.COMMUNITY.renderRoute(),
  },
  {
    path: ROUTES.PAYMENT.path,
    element: ROUTES.PAYMENT.renderRoute(),
  },
  {
    path: ROUTES.GUN_RUNNER.path,
    element: ROUTES.GUN_RUNNER.renderRoute(),
  },
  {
    path: ROUTES.VERSUS.path,
    element: ROUTES.VERSUS.renderRoute(),
  },
  {
    path: ROUTES.VERSUS_BATTLE.path,
    element: ROUTES.VERSUS_BATTLE.renderRoute(),
  },
  {
    path: ROUTES.DARE.path,
    element: ROUTES.DARE.renderRoute(),
  },
  {
    path: ROUTES.SIGNIN_TEST.path,
    element: ROUTES.SIGNIN_TEST.renderRoute(),
  },
  {
    path: ROUTES.METARACTSHELF.path,
    element: ROUTES.METARACTSHELF.renderRoute(),
  },
  {
    path: ROUTES.METARACTSHELFDETAILS.path,
    element: ROUTES.METARACTSHELFDETAILS.renderRoute(),
  },
  {
    path: ROUTES.METARACTSHELFANALYTICS.path,
    element: ROUTES.METARACTSHELFANALYTICS.renderRoute(),
  },
  {
    path: ROUTES.COMMUNITY_DARE_OPEN.path,
    element: ROUTES.COMMUNITY_DARE_OPEN.renderRoute(),
  },
  {
    path: ROUTES.FAN_SIGNUP_DARE.path,
    element: ROUTES.FAN_SIGNUP_DARE.renderRoute(),
  },
  {
    path: ROUTES.SIGN_IN_DARE.path,
    element: ROUTES.SIGN_IN_DARE.renderRoute(),
  },
])

function App() {
  return (
    <div style={{ maxWidth: "1980px" }} className="bg-dashboardbackground">
      <SocketProvider>
        <CommunityProvider>
          <UsersProvider>
            <CommunitySetupProvider>
              <AccountSettingsProvider>
                <GunRunnerProvider>
                  <VersusProvider>
                    <DareProvider>
                      <MetaractShelfProvider>
                        <Flex>
                          <RouterProvider router={router} />
                        </Flex>
                      </MetaractShelfProvider>
                    </DareProvider>
                  </VersusProvider>
                </GunRunnerProvider>
              </AccountSettingsProvider>
            </CommunitySetupProvider>
          </UsersProvider>
        </CommunityProvider>
      </SocketProvider>
      <ToastContainer position="bottom-center" />
    </div>
  )
}

export default App
