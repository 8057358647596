import styled from "styled-components";

export const Container = styled.div`
  margin-top: 88px;
  width: 1000px;
  display: flex;
  height: 100px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #101010;
  margin-bottom: 24px;
`;
