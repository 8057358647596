import styled from "styled-components";

const CardTitleText = styled.p`
  margin: 0;
  padding: 0;
  color: #101010;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`;

export default CardTitleText;
