import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import * as R from "ramda"
import { toast } from "react-toastify"
// import io from "socket.io-client"
import { CommunityContext } from "../../../../context/community"
import { DareContext } from "../../../../pages/Dare/context"
import { SocketContext } from "../../../../context/socket"

import CountdownTimer from "../DareCountdownTimer"
import DareCard from "../DareCard"
import { DrawerContainer } from "../../Common"
import SCREENS from "../../../../pages/Dare/config/SCREENS"
import { DrawerButton } from "../../Common/drawerButton"

const CreatorPickDare = () => {
  const [topTwoDares, SetTopTwoDares] = useState({
    dareone: { id: "", dareDetails: "", dareTitle: "", darePrice: "" },
    daretwo: { id: "", dareDetails: "", dareTitle: "", darePrice: "" },
  })

  const communityContext = useContext(CommunityContext)
  const dareContext = useContext(DareContext)
  const socket = useContext(SocketContext)
  const getTopTwo = async () => {
    try {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/${metaract.id}/toptwodareitems`
      )
      const dareitems = R.pathOr("", ["data", "data"], res)
      const toptwo = {
        dareone: dareitems[0],
        daretwo: dareitems[1],
      }
      if (!toptwo?.dareone && !toptwo?.daretwo) {
        toast.success("You've completed all the dares!")
        dareContext.setClashStarted(false)
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
      }
      SetTopTwoDares(toptwo)
      console.log(
        "getTopTwo Fired Successfully => server message: ",
        res.data.message
      )
    } catch (error) {
      console.log(error)
    }
  }

  const completeDaresOutOfTime = async () => {
    try {
      const metaract = communityContext.metaracts.find(
        (metaract) => metaract.type === "DARE" && metaract.status === "ACTIVE"
      )
      if (topTwoDares.dareone?.id) {
        const response1 = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/completedare/${topTwoDares?.dareone?.id}`,
          { status: "REMOVED" }
        )
        console.log("REMOVED Dare 1 => ", response1?.data?.data)
      }
      if (topTwoDares.daretwo?.id) {
        const response2 = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/completedare/${topTwoDares?.daretwo?.id}`,
          { status: "REMOVED" }
        )
        console.log("REMOVED Dare 2 => ", response2?.data?.data)
      }
      if (socket) {
        socket.emit("SEND_DARE_HAS_CHANGED", `dare_${metaract.id}`)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (dareContext.OutOfTime) {
      try {
        completeDaresOutOfTime()
      } catch (error) {
        console.log(error)
      }
    }
  }, [dareContext.OutOfTime])
  useEffect(() => {
    getTopTwo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dareContext?.dare?.dareitems])

  const setPickDare = async (id) => {
    try {
      const resget = await axios.get(
        `${process.env.REACT_APP_SERVER_API}metaract/getmetaract/${dareContext.metaractid}`
      )
      const olddescription = resget.data.data?.description

      const description = { ...olddescription, pickdare: id }

      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${dareContext.metaractid}`,
        { description: description }
      )
      if (socket) {
        socket.emit("SEND_DARE_HAS_CHANGED", `dare_${dareContext.metaractid}`)
      }
      console.log("result setPickDare=>", res.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleDareOne = () => {
    dareContext.setClashInProgress(true)
    const selectedDare = {
      title: topTwoDares.dareone.title,
      description: topTwoDares.dareone.description,
      price: topTwoDares.dareone.price,
      id: topTwoDares.dareone.id,
    }
    dareContext.setDareDetailsDare(selectedDare)
    dareContext.setPickDareID(topTwoDares.dareone.id)
    setPickDare(topTwoDares.dareone.id)
  }

  const handleDareTwo = () => {
    dareContext.setClashInProgress(true)
    const selectedDare = {
      title: topTwoDares.daretwo.title,
      description: topTwoDares.daretwo.description,
      price: topTwoDares.daretwo.price,
      id: topTwoDares.daretwo.id,
    }
    dareContext.setDareDetailsDare(selectedDare)
    dareContext.setPickDareID(topTwoDares.daretwo.id)

    setPickDare(topTwoDares.daretwo.id)
  }

  return (
    <DrawerContainer
      type="dare"
      back
      onClose={() => {
        dareContext.setClashStarted(false)
        dareContext.setCurrentScreen(SCREENS.SCREENS.INIT)
        dareContext.SetOutOfTime(false)
      }}
    >
      <div className="mt-4">
        <CountdownTimer delta={29999} />
      </div>
      <p className="text-base text-dashboardhighlight font-proxima font-semibold justify-center mt-4">
        Pick a dare to do on stream before the timer runs out.
      </p>

      <div className="flex flex-col mt-20">
        {!!topTwoDares?.dareone && (
          <div
            onClick={() => {
              if (!dareContext.OutOfTime) {
                handleDareOne()
              }
            }}
          >
            <DareCard
              description={topTwoDares.dareone?.description}
              title={topTwoDares.dareone?.title}
              price={topTwoDares.dareone?.price}
              id={topTwoDares.dareone?.id}
              users={topTwoDares.dareone?.userids}
              index={topTwoDares.dareone?.index}
              toPick
              picked={dareContext?.pickdareid === topTwoDares.dareone?.id}
            />
          </div>
        )}
        {!!topTwoDares?.dareone && !!topTwoDares?.daretwo && (
          <div className="flex justify-center">
            <h1 className="font-ocr my-5 text-dashboardhighlight">VS</h1>
          </div>
        )}
        {!!topTwoDares?.daretwo && (
          <div
            onClick={() => {
              if (!dareContext.OutOfTime) {
                handleDareTwo()
              }
            }}
          >
            <DareCard
              description={topTwoDares.daretwo?.description}
              title={topTwoDares.daretwo?.title}
              price={topTwoDares.daretwo?.price}
              id={topTwoDares.daretwo?.id}
              users={topTwoDares.daretwo?.userids}
              index={topTwoDares.daretwo?.index}
              toPick
              picked={dareContext?.pickdareid === topTwoDares.daretwo?.id}
            />
          </div>
        )}
      </div>
      {dareContext.OutOfTime ? (
        <div className="flex pt-20">
          <DrawerButton
            text="Next Cycle"
            onClick={() => {
              dareContext.SetOutOfTime(false)
              getTopTwo()
            }}
          />
        </div>
      ) : null}
    </DrawerContainer>
  )
}

export default CreatorPickDare
