import { useScreen } from "../../../hooks/useScreen"
import { Button } from "../../Common/atoms/Button"

export const DrawerButton = ({ text, onClick, styles, disabled }) => {
  const { isMobile } = useScreen()
  return (
    <div className="flex h-28 min-h-28 shrink-0 justify-center mt-auto">
      <Button
        text={text}
        onClick={onClick}
        styles={styles}
        disabled={disabled}
        size={isMobile ? "medium" : "big"}
      />
    </div>
  )
}
