import { useNavigate } from "react-router-dom"
import React, { Fragment, useContext, useEffect, useState } from "react"
import darecard from "../../../assets/Images/MetaractShelfCards/dare_card.svg"
import versuscard from "../../../assets/Images/MetaractShelfCards/vs_card.svg"
import gunrunnercard from "../../../assets/Images/MetaractShelfCards/gunrunner_card.svg"
import { Switch } from "@headlessui/react"
import { MetaractShelfContext } from "../../../pages/MetaractShelf/context/"
import SCREENS from "../../../pages/MetaractShelf/config/SCREENS"
import axios from "axios"
import QRCode from "qrcode.react"
import { BellSlashIcon } from "@heroicons/react/20/solid"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
export default function MetaractShelfGridCard({ metaract, id }) {
  console.log(metaract, "METARACT => ")
  const [enabled, setEnabled] = useState(false)
  const [status, setStatus] = useState("CREATED")
  const metaractShelfContext = useContext(MetaractShelfContext)
  const navigate = useNavigate()
  const cardurl =
    metaract.type === "DARE"
      ? darecard
      : metaract.type === "VERSUS"
      ? versuscard
      : gunrunnercard

  useEffect(() => {
    setEnabled(metaract.status === "ACTIVE")
    setStatus(metaract.status)
  }, [])
  const completeMetaract = async () => {
    try {
      let res
      if (metaract.type === "DARE") {
        const newdata = {
          status: "COMPLETED",
        }
        res = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/enddare/${metaract.id}`,
          newdata
        )
      } else if (metaract.type === "VERSUS") {
        const newdata = {
          status: "COMPLETED",
        }
        res = await axios.post(
          `${process.env.REACT_APP_SERVER_API}metaract/endversus/${metaract.id}`,
          newdata
        )
      }
      setEnabled(res.data.data.status === "ACTIVE" ? true : false)
      setStatus(res.data.data.status)
    } catch (error) {
      console.log(error)
    }
  }
  const downloadQR = () => {
    const canvas = document.getElementById(
      `${metaract?.communities[0].communityid}`
    )
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    let downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = "dare_qr.png"
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  const updateStatus = async () => {
    try {
      const newdata = {
        type: metaract.type,
        description: metaract.description,
        status: !enabled ? "ACTIVE" : "CREATED",
      }
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_API}metaract/updatemetaract/${metaract.id}`,
        newdata
      )
      setEnabled(res.data.data.status === "ACTIVE" ? true : false)
      setStatus(res.data.data.status)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <a key={metaract.id} className="group">
      <div className="bg-transparent cursor-pointer group perspective">
        <div className=" preserve-3d group-hover:card-flip w-full h-full duration-1000 aspect-w-2 aspect-h-3">
          <div className=" backface-hidden w-full h-full overflow-hidden rounded-lg">
            <img
              src={cardurl}
              className="h-full w-full object-fill object-center group-hover:opacity-75"
            />
          </div>
          <div className=" card-flip backface-hidden w-full bg-dashboardframes bg-opacity-5 overflow-hidden rounded-3xl border border-dashboardframes">
            <div className="flex mx-10 flex-row justify-between bg-202528 bg-opacity-75">
              <div className="flex flex-row justify-between my-10 border rounded-full border-dashboardhighlight">
                <div className="px-5 py-2 flex">
                  <h1 className="text-dashboardtext pr-2 text-lg font-proxima padding-2">
                    METARACT
                  </h1>
                  {status !== "COMPLETED" ? (
                    <Switch
                      checked={enabled}
                      onChange={updateStatus}
                      className={classNames(
                        enabled ? "bg-dashboardhighlight" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 pr-5"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="flex  my-10 ">
                {enabled ? (
                  <span className="inline-flex items-center rounded-md bg-dashboardboxbackground px-2.5 text-sm  text-dashboardaccent font-proxima">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-dashboardaccent"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    LIVE
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-md bg-dashboardboxbackground px-2.5 text-sm  text-gray-500 font-proxima">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    LIVE
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col  ">
              <div className="flex flex-col  p-6">
                <h1 className="text-dashboardtext text-xl text-justify  font-ocr ">
                  {metaract.type}
                </h1>
                <div className="flex flex-col  mx-auto">
                  <QRCode
                    id={`${metaract?.communities[0]?.communityid}`}
                    value={`https://moblabs-mvp.vercel.app/community/${metaract?.communities[0]?.communityid}/true`}
                    size={128}
                    imageSettings={{
                      src: "https://moblabs-s3-1.s3.us-west-1.amazonaws.com/qrlogo_sunset_nobord.png",
                      x: undefined,
                      y: undefined,
                      height: 25,
                      width: 25,
                      excavate: true,
                    }}
                  />
                  {/*<a
                    className="text-dashboardhighlight text-center"
                    onClick={downloadQR}
                  >
                    {" "}
                    Download QR{" "}
                  </a>*/}
                </div>
              </div>
              <p className="text-dashboardtext mx-8 text-justify">
                Complete dares to collect the pot. Get fans to post dares and
                vote on them. Once voting ends, accept the top voted dare on
                stream and do it to collect the pot.
              </p>
            </div>
            <div className="flex flex-row items-center justify-between mx-10 my-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                className="w-6 h-6 stroke-dashboardtext"
                onClick={() => {
                  navigate(`/metaractshelfdetails/${metaract.id}`, {
                    state: { community_id: id },
                  })
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                className="w-6 h-6 stroke-dashboardtext"
                onClick={() => {
                  metaractShelfContext.setDrawerIsOpen(true)
                  metaractShelfContext.setMetaract(metaract)
                  if (metaract.type === "DARE") {
                    metaractShelfContext.setCurrentDrawerScreen(
                      SCREENS.DRAWERS.CREATORDARE
                    )
                  } else if (metaract.type === "VERSUS") {
                    metaractShelfContext.setCurrentDrawerScreen(
                      SCREENS.DRAWERS.CREATORVERSUS
                    )
                  } else if (metaract.type === "GUNRUNNER") {
                    metaractShelfContext.setCurrentDrawerScreen(
                      SCREENS.DRAWERS.CREATORGUNRUNNER
                    )
                  }
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
              <div>
                {status !== "COMPLETED" ? (
                  <button
                    onClick={completeMetaract}
                    className="bg-dashboardhighlight text-black py-2 items-center px-4 rounded-md font-ocr text-md"
                  >
                    Complete Metaract
                  </button>
                ) : (
                  <button className="bg-gray-500 text-gray-50 py-2 items-center px-4 rounded-md font-ocr text-md">
                    Complete Metaract
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex items-center justify-between text-base font-ocr text-dashboardtext">
        <h3>{metaract.type}</h3>
      </div>
      <p className="mt-1 text-sm font-proxima text-dashboardtext">{status}</p>
    </a>
  )
}
