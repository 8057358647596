import styled from "styled-components";

const LogInSubText = styled.p`
  margin: 5px;
  padding: 5px;
  display: flex;
  color: #49F4FF40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export default LogInSubText;


