import React, { useState, useEffect, useRef } from "react"
import { Flex } from "rebass"
import { TwitchEmbed } from "react-twitch-embed"
import StreamWindow from "../../StreamWindow"

const TwitchStreamWindow = ({ src, drawerOpen }) => {
  const embed = useRef() // We use a ref instead of state to avoid rerenders.

  const handleReady = (e) => {
    embed.current = e
  }
  return (
    <div className="flex bg-dashboardbackground  min-h-[400px] min-w-[300px] w-full ">
      <div className="flex w-full">
        {!drawerOpen && (
          <StreamWindow
            src={src}
            onVideoReady={handleReady}
            height="100%"
            width="100%"
          />
        )}
        {/*<iframe
            title="left-streamer"
            src={`https://player.twitch.tv/?channel=${src}&parent=localhost&autoplay=true`}
            height={300}
            width={600}
            allowFullScreen={true}
  ></iframe>*/}
      </div>
    </div>
  )
}

export default TwitchStreamWindow
