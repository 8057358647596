import SCREENS from "../../config/screens"
import { Button } from "../../../../Common/atoms/Button"
import { useScreen } from "../../../../../hooks/useScreen"

const Success = ({ setCurrentScreenState }) => {
  const { isMobile } = useScreen()
  return (
    <>
      <p>
        We have sent your password recovery instructions to your email address.
        Did not receive any email? Check your spam folder, or
      </p>
      <Button
        onClick={() => setCurrentScreenState(SCREENS.FORM)}
        text="Send again"
        size={isMobile ? "medium" : "big"}
        styles="mt-6"
      />
    </>
  )
}

export default Success
