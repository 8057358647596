import React, { useContext } from "react";
import { Flex } from "rebass";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import COMMUNITY_SETUP_SCREENS from "../../../config/screens/CommunitySetup";
import { CommunitySetupContext } from "../../../context/communitySetup";

import * as Styled from "./styles";

const Stepper = () => {
  const communitySetupContext = useContext(CommunitySetupContext);

  return (
    <Styled.Container>
      <Styled.ActiveStepText>Info</Styled.ActiveStepText>
      <Styled.ArrowIcon />
      {communitySetupContext.currentScreenState !==
      COMMUNITY_SETUP_SCREENS.INFO.key ? (
        <Styled.ActiveStepText>Branding</Styled.ActiveStepText>
      ) : (
        <Styled.InactiveStepText>Branding</Styled.InactiveStepText>
      )}
      <Styled.ArrowIcon />
      {communitySetupContext.currentScreenState ===
      COMMUNITY_SETUP_SCREENS.YOUR_FIRST_METARACT.key ? (
        <Styled.ActiveStepText>Your first metaract</Styled.ActiveStepText>
      ) : (
        <Styled.InactiveStepText>Your first metaract</Styled.InactiveStepText>
      )}
    </Styled.Container>
  );
};

export default Stepper;
