import React, { useState } from "react"

import SCREENS from "./config/screens"
import Form from "./screens/Form"
import Success from "./screens/Success"

const ResetPasswordForm = () => {
  const [currentScreenState, setCurrentScreenState] = useState(SCREENS.FORM)

  const renderCurrentScreen = () => {
    if (currentScreenState === SCREENS.FORM) {
      return <Form setCurrentScreenState={setCurrentScreenState} />
    }
    if (currentScreenState === SCREENS.SUCCESS) {
      return <Success setCurrentScreenState={setCurrentScreenState} />
    }
  }

  return renderCurrentScreen()
}

export default ResetPasswordForm
