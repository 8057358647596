import React, { useContext, useRef, useEffect, useState } from "react"
import { Drawer } from "@mui/material"
import { GunRunnerContext } from "../../../../../pages/GunRunner/context"
import { CommunityContext } from "../../../../../context/community"
import io from "socket.io-client"
import SCREENS from "../../../../../pages/Dare/config/SCREENS"

import TopBar from "../../../../Navigation/TopBar"
import GunRunnerCreatorDrawer from "./screens/GunRunnerCreatorDrawer"
import GunRunnerFanDrawer from "./screens/GunRunnerFanDrawer"
import StreamWindow from "../../../../StreamWindow"
import { SocketContext } from "../../../../../context/socket"

const GunRunnerDrawer = () => {
  const embed = useRef() // We use a ref instead of state to avoid rerenders.
  const handleReady = (e) => {
    console.log("fired handleReady on GunRunnerDrawer")
    embed.current = e
  }

  const gunRunnerContext = useContext(GunRunnerContext)
  const communityContext = useContext(CommunityContext)
  const renderContent = () => {
    if (
      gunRunnerContext.currentDrawerScreen === SCREENS.DRAWERS.CREATOR_COMMUNITY
    ) {
      console.log("going to community drawer")
      return <GunRunnerCreatorDrawer />
    }
    if (
      gunRunnerContext.currentDrawerScreen === SCREENS.DRAWERS.FAN_COMMUNITY
    ) {
      console.log("going to fan drawer")
      return <GunRunnerFanDrawer />
    }
  }

  return (
    <Drawer
      anchor="right"
      open={gunRunnerContext.currentDrawerOpen}
      onClose={() => {
        gunRunnerContext.setCurrentDrawerOpen(false)
        gunRunnerContext.setCurrentDrawerScreen(SCREENS.DRAWERS.INIT)
        gunRunnerContext.setCurrentScreen(SCREENS.SCREENS.INIT)
        communityContext.setDrawerOpen(false)
      }}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <TopBar />
      <div className="GunRunnerDrawer flex w-screen min-w-[800px] min-h-[800px]">
        <div className="streamWindow">
          <StreamWindow
            src={communityContext.src}
            handleReady={handleReady}
            width="100%"
            height="100%"
          />
        </div>
        <div className="gunDrawersScreen flex w-1/4 min-w-[var(--drawer-width)] rounded-lg bg-daresidepanelbg">
          {renderContent()}
        </div>
      </div>
    </Drawer>
  )
}

export default GunRunnerDrawer
