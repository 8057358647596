import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axios from "axios"
import * as R from "ramda"
import { toast } from "react-toastify"

import { useScreen } from "../../hooks/useScreen"
import TwitchStreamWindow from "../../components/Community/TwitchStreamWindow"
import StreamChat from "../../components/Community/StreamChat"
import { CommunityContext } from "../../context/community"
import { UsersContext } from "../../context/users"
import { VersusContext } from "../Versus/context"
import { DareContext } from "../Dare/context"
import Dare from "../Dare"
import GunRunner from "../GunRunner"
import Versus from "../Versus"
import SideBar from "../../components/Navigation/SideBar"
import { GunRunnerContext } from "../GunRunner/context"
import AboutCommunity from "../../components/Community/AboutCommunity"
import Links from "../../components/Community/Links"
import { CommunityHeader } from "../../components/Common/CommunityHeader"
import SCREENS from "../Dare/config/SCREENS"

const Community = () => {
  const usersContext = useContext(UsersContext)

  const communityContext = useContext(CommunityContext)

  const versusContext = useContext(VersusContext)
  const dareContext = useContext(DareContext)
  const gunRunnerContext = useContext(GunRunnerContext)

  useEffect(() => {
    versusContext.setCurrentDrawerOpen(false)
    dareContext.setCurrentDrawerOpen(false)
    gunRunnerContext.setCurrentDrawerOpen(false)
  }, [])

  const [pageWidthState, setPageWidthState] = useState(1012)

  const [isValidCommunity, setIsValidCommunity] = useState(false)

  const [communityMetaracts, setCommunityMetaracts] = useState([])

  const { id, draweropen } = useParams()

  const navigate = useNavigate()
  const { isMobile } = useScreen()
  const userType = R.pathOr("FAN", ["userDBObject", "type"], usersContext)
  const userID = R.pathOr("", ["userDBObject", "id"], usersContext)
  const userJoinedCommunities = R.pathOr(
    "",
    ["userDBObject", "communities"],
    usersContext
  )

  useEffect(() => {
    if (draweropen) {
      if (userType === "CREATOR" && communityContext.creatorid === userID) {
        dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.CREATOR_COMMUNITY)
      } else {
        dareContext.setCurrentDrawerScreen(SCREENS.DRAWERS.FAN_COMMUNITY)
      }
      dareContext.setDareTutorialModal(true)
      dareContext.setCurrentDrawerOpen(true)
      communityContext.setDrawerOpen(true)
    }
  }, [userID])
  let userHasJoined
  if (userJoinedCommunities !== undefined && userJoinedCommunities.length > 0) {
    userHasJoined =
      userJoinedCommunities.filter((community) => {
        return community.communityid === id
      }).length >= 1
        ? true
        : false
  } else {
    userHasJoined = false
  }

  useEffect(() => {
    if (communityContext.sidebarVisibleState) {
      setPageWidthState(1012)
    } else {
      setPageWidthState(1352)
    }
  }, [communityContext.sidebarVisibleState])

  const navigateMetaractShelf = () => {
    navigate(`/metaractshelf/${id}`)
  }

  const getCommunity = async () => {
    try {
      communityContext.setIsLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${id}`
      )
      console.log(
        "Queried => getCommunity fired on Community Page",
        "=>",
        response.data.data
      )
      const community = response.data.data

      if (community) {
        communityContext.setName(community.name)
        communityContext.setHandle(community.handle)
        communityContext.setIsLive(community.islive)
        communityContext.setSupporters(community.supporters)
        communityContext.setCreatorID(community.creatorid)
        communityContext.setMetaracts(
          community.metaracts.map((metaract) => metaract.metaract)
        )
        communityContext.setDescription(community.description)
        communityContext.setSrc(community.streamsrc)
        communityContext.setCreator(community.creator)
        setIsValidCommunity(true)
      } else {
        setIsValidCommunity(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      communityContext.setIsLoading(false)
    }
  }

  useEffect(() => {
    communityContext.setID(id)
    getCommunity()
    checkActiveMetaracts()
  }, [id])

  const checkActiveMetaracts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/getcommunity/${id}`
      )
      const metaractsfromrequest = R.pathOr(
        [],
        ["data", "data", "metaracts"],
        res
      )
      const metaracts = metaractsfromrequest.map(
        (metaract) => metaract.metaract
      )
      const activeMetaracts = metaracts.filter(
        (metaract) => R.pathOr(false, ["status"], metaract) === "ACTIVE"
      )
      const activeMetaractTypes = activeMetaracts.map((metaract) =>
        R.pathOr(false, ["type"], metaract)
      )
      setCommunityMetaracts(activeMetaractTypes)
    } catch (error) {
      console.log(error)
    }
  }

  const getUser = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}user/getuser/${userID}`
      )
      usersContext.setUserDBObject(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleJoin = async () => {
    if (!usersContext.userIsSignedInState) {
      toast.error("Please log in to join a community")
      return
    }
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/joincommunity/${id}/${userID}`
      )
      console.log("handleJoin Fired", "=>", res.data)
      await getUser()
      await getCommunity()
      await checkActiveMetaracts()
    } catch (error) {
      console.log(error)
    }
  }

  const handleUnJoin = async () => {
    if (!usersContext.userIsSignedInState) {
      toast.error("Please log in to join a community")
      return
    }
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}community/leavecommunity/${id}/${userID}`
      )
      console.log("handleUnJoin Fired", "=>", res.data)
      await getUser()
      await getCommunity()
      await checkActiveMetaracts()
    } catch (error) {
      console.log(error)
    }
  }

  const renderDare = () => {
    if (communityMetaracts.find((metaract) => metaract === "DARE")) {
      return <Dare />
    }
    return null
  }

  const renderVersus = () => {
    if (communityMetaracts.find((metaract) => metaract === "VERSUS")) {
      return <Versus />
    }
    return null
  }

  const renderGunRunner = () => {
    if (communityMetaracts.find((metaract) => metaract === "GUNRUNNER")) {
      return <GunRunner />
    }
    return null
  }

  if (!isValidCommunity) {
    return <div className="bg-dashboardbackground w-screen h-screen"></div>
  }

  return (
    <div className=" w-screen min-h-screen bg-dashboardbackground flex min">
      <SideBar />
      <div className="flex w-screen justify-start pl-7 overflow-hidden">
        <div className=" bg-dashboardbackground w-full ml-16 mr-8">
          <CommunityHeader
            communityContext={communityContext}
            communityMetaracts={communityMetaracts}
            userHasJoined={userHasJoined}
            handleJoin={handleJoin}
            handleUnJoin={handleUnJoin}
          />
          <div className="flex justify-center">
            <TwitchStreamWindow
              src={communityContext.src}
              drawerOpen={communityContext.drawerOpen}
            />
          </div>

          <div className="flex flex-wrap my-6 w-full gap-10">
            {communityContext.creatorid === userID && (
              <div className="flex justify-center ">
                <button
                  onClick={navigateMetaractShelf}
                  className="bg-dashboardhighlight hover:bg-black text-black hover:text-dashboardhighlight font-ocr py-2 px-4 rounded-lg flex flex-col h-44 w-80 bg-communitydarebg "
                >
                  Metaract Shelf
                </button>
              </div>
            )}
            {renderDare()}
            {renderVersus()}
            {renderGunRunner()}
          </div>
          <div className="flex flex-col md:flex-row gap-10 mb-16">
            <AboutCommunity />
            <Links />
          </div>
        </div>
        {!isMobile && communityContext.sidebarVisibleState && <StreamChat />}
      </div>
    </div>
  )
}

export default Community
