import styled from "styled-components";

export const Input = styled.input`
  margin: 5px;
  padding: 5px;
  background: #141d2c;
  width: 18vw;
  height: 3vh;
  display: flex;
  border-radius: 4px;
  color: #49F4FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border:1px solid #49F4FF40;
box-shadow: 1px 1px 6px rgba(73, 244, 255, 0.1);
  display:flex;

  ::placeholder {
    color: #49F4FF40;
    padding-left:2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  &:focus {
    outline: none;
    border: 1px solid #49F4FF;
    box-shadow: 1px 1px 6px rgba(73, 244, 255, 1);
  }
`;



