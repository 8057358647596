import { createContext, useState } from "react";
import ACCOUNT_SETTINGS_SCREENS from "../config/screens/AccountSettings";

export const AccountSettingsContext = createContext();

const AccountSettingsProvider = ({ children }) => {
  const [currentScreenState, setCurrentScreenState] = useState(
    ACCOUNT_SETTINGS_SCREENS.PERSONAL_INFO
  );

  return (
    <AccountSettingsContext.Provider
      value={{ currentScreenState, setCurrentScreenState }}
    >
      {children}
    </AccountSettingsContext.Provider>
  );
};

export default AccountSettingsProvider;
