import React, { useContext, useState, useEffect } from "react"
import { Drawer } from "@mui/material"
import io from "socket.io-client"

import * as R from "ramda"
import { VersusContext } from "../../../pages/Versus/context"
import { CommunityContext } from "../../../context/community"
import VersusBattle from "../../../pages/VersusBattle"
import { MetaractShelfContext } from "../../../pages/MetaractShelf/context"
import TopBar from "../../Navigation/TopBar"

const VersusDrawer = () => {
  const versusContext = useContext(VersusContext)
  const communityContext = useContext(CommunityContext)
  const metaractShelfContext = useContext(MetaractShelfContext)
  return (
    <Drawer
      anchor="right"
      open={versusContext.currentDrawerOpen}
      onClose={() => {
        versusContext.setCurrentDrawerOpen(false)
        communityContext.setDrawerOpen(false)
        metaractShelfContext.setDrawerIsOpen(false)
      }}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <TopBar />
      <div className="flex w-screen bg-daresidepanelbg min-w-[800px] min-h-[600px] h-screen">
        <VersusBattle />
      </div>
    </Drawer>
  )
}

export default VersusDrawer
