import React from "react";

import * as Styled from "./styles";

const LogInInput = ({width, value, onChange, placeholder, type}) => {
    return (
        <Styled.Input
        width={width}
        value={value}
        onChange={(event) => onChange(event)}
        placeholder={placeholder}
        type={type || "text"}
      /> 
    )
}

export default LogInInput;