import { useEffect, useState } from "react"

export const useScreen = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const mobileBreak = 640
  const tabletBreak = 1024
  const isMobile = width <= mobileBreak
  const isTablet = width <= tabletBreak && width > mobileBreak
  const isDesktop = width > tabletBreak

  return { width, height, isMobile, isTablet, isDesktop }
}
