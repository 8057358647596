import React from "react";
import { Flex } from "rebass";

import CoverImage from "../../components/Profile/CoverImage";
import EditProfileButton from "../../components/Profile/EditProfileButton";
import UserInformation from "../../components/Profile/UserInformation";
import Tabs from "../../components/Profile/Tabs";

import * as Styled from "./styles";

const Profile = () => {
  return (
    <Styled.Container>
      <CoverImage />
      <EditProfileButton />
      <UserInformation />
      <Tabs />
    </Styled.Container>
  );
};

export default Profile;
