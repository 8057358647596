import styled from "styled-components";

const CardLinkText = styled.p`
  margin: 0;
  padding: 0;
  color: #1400ff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
`;

export default CardLinkText;
