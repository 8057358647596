import { useScreen } from "../../../hooks/useScreen"
import { Divider } from "../../Common/atoms/Divider"
export const PaymentMethod = ({ label, darePrice, hint, styles }) => {
  const { isMobile } = useScreen()
  return (
    <div
      className={`flex flex-col w-full justify-center mt-2 font-proxima ${
        styles && styles
      }`}
    >
      {!isMobile && (
        <>
          <Divider styles="mt-2" />
          <div className="flex w-full my-3 justify-between items-center text-dashboardtext text-base sm:text-lg">
            <p>Pay using</p>
            <p className="font-semibold p-2 text-center w-44 border border-dashboardframes rounded-lg">
              Wallet
            </p>
          </div>
        </>
      )}
      <div className="flex w-full justify-between items-center border-dashboardframes rounded-lg border p-2 text-base">
        <p className="text-dashboardalttext">{label}</p>
        <p className="text-dashboardhighlight">
          {`USD ${Math.round(darePrice).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}`}
        </p>
      </div>
      {hint && <p className="mt-2 text-dashboardalttext text-xs">{hint}</p>}
    </div>
  )
}
